import React from 'react';
import { Dialog } from 'primereact/dialog';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';
// import {formatDate} from '../../../../../_metronic/helpers'
import { toast } from 'react-toastify';
import {updateTicket} from '../core/_requests'
import {isNotEmpty} from '../../../../../_metronic/helpers'
import {useFormik} from 'formik'
import {useState} from 'react'
import {Installers} from '../../../../common/components/Installers'


const AssignTicketModal = ({ open, onClose, row }) => {

  const initialValues = {
    id: row.id,
    ticket_id: row.ticket_id || '',
    status: row.status || 'open',
    category: row.category || '',
    priority: row.priority || '',
    subject: row.subject || '',
    description: row.description || '',
    creation_date: row.creation_date || '',
    resolution_date: row.resolution_date || ''
  }

  const [ticketForEdit] = useState(initialValues)
  const formik = useFormik({
    initialValues: ticketForEdit,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      const payload = {
        id: values.id,
        ticket_id: initialValues.ticket_id,
        status: values.status,
        category: values.category,
        priority: values.priority,
        subject: values.subject,
        description: values.description,
        creation_date: initialValues.creation_date,
        resolution_date: values.resolution_date,
        user_id: row.user_id
      }
      try {
        if (isNotEmpty(values.id)) {
          console.log(payload)
          await updateTicket(payload)
          toast.success('Record updated successfully');
        } else {
          toast.success('Record created successfully');
        }
      } catch (ex) {
        console.error(ex)
        toast.warning('An error occured');
      } finally {
        setSubmitting(true)
      }
    },
  })

  return (
    <Dialog
      visible={open}
      onHide={onClose}
      header={`Ticket details: ${row.ticket_id}`}
      style={{ width: '50vw' }}
      breakpoints={{ '960px': '50vw', '641px': '95vw' }}
      footer={
        <div>
          <button className="btn btn-danger btn-sm" onClick={onClose}>
            Close
          </button>
        </div>
      }
    >
    <div className='d-flex flex-column'>
          <div className="row">
          {/*text-dark fw-bold mw-lg-400px*/}
            <div className="col-md-6 mw-lg-400px">
              <h4>User Information</h4>
              <p><strong>Name:</strong> {`${row.user.firstname} ${row.user.lastname}`}</p>
              <p><strong>Username:</strong> {row.user.username}</p>
              <p><strong>Email:</strong> {row.user.email}</p>
            </div>
            <div className="col-md-6 mw-lg-400px">
              <h4>Ticket Information</h4>
              <p><strong>Status:</strong> {row.status}</p>
              <p><strong>Priority:</strong> {row.priority}</p>
              <p><strong>Subject:</strong> {row.subject}</p>
            </div>
          </div>

          <div className="mt-3 mw-lg-400px">
            <h4>Description</h4>
            <p>{row.description}</p>
          </div>
          <hr className="text-gray-600" />

          <form id='kt_modal_add_ticket_form' className='form' onSubmit={formik.handleSubmit} noValidate>
            <div className="row" style={{marginBottom: '30%'}}>
              <div className="col-md-12">
                <Installers 
                  label="Select installer"
                  name="installer"
                  formik={formik}
                  defaultValue={{}}
                  />
              </div>
            </div>
          </form>
      </div>

    </Dialog>
  );
};

export default AssignTicketModal;
