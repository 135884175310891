import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  StatisticsWidget5,
  TablesWidget11,
} from '../../../_metronic/partials/widgets'

import {getJobsStats} from './core/_requests'

import {JobServiceSummary} from './components/JobServiceSummary'
import {JobPerformanceSummary} from './components/JobPerformanceSummary'
import {JobStatusSummary} from './components/JobStatusSummary'

import L from "leaflet";
import { addressPoints } from "./../../../meterLocations";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import MarkerClusterGroup from "react-leaflet-cluster";
import { popupContent, popupHead, popupText } from "../../../popupStyles";

type AdressPoint = Array<[number, number, string, string, string, string, string, string]>;

const customIcon = new L.Icon({
  iconUrl: require("./../../../location.svg").default,
  iconSize: new L.Point(40, 47)
});

interface JobStatistics {
  total_jobs_count?: number;
  service_type_statistics?: {
    installation_count?: number;
    maintenance_count?: number;
    repair_count?: number;
    cleaning_count?: number;
    installation_avg_cost?: number;
    maintenance_avg_cost?: number;
    repair_avg_cost?: number;
    cleaning_avg_cost?: number;
    installation_avg_rating?: number;
    maintenance_avg_rating?: number;
    repair_avg_rating?: number;
    cleaning_avg_rating?: number;
    installation_median_completion_time?: number;
    maintenance_median_completion_time?: number;
    repair_median_completion_time?: number;
    cleaning_median_completion_time?: number;
  };
  status_statistics?: {
    initiated_count?: number;
    pending_count?: number;
    completed_count?: number;
  };
}

const DashboardPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState<JobStatistics>({})

  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    
    const fetchJobsStats = async () => {
      setIsLoading(true);
      try {
        const data = await getJobsStats();
        if (data) {
          setStats(data);
        }
      } catch (error) {
        console.error('Error fetching stats:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchJobsStats();

    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }

  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]} description=''>
        Dashboard
      </PageTitle>

      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
   
        <div className='col-xl-12'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='map'
            color='success'
            iconColor='white'
            title='Bartum Customers'
            titleColor='white'
            description='Visualization of all active customers, providing detailed information for each location.'
            descriptionColor='white'
          />
        </div>

      </div>
      {/* end::Row */}


      {/* begin::Row */}
      <div className='g-5 gx-xxl-8'>
      <MapContainer style={{height: '600px'}} className='mb-xl-8' center={[9.92, 8.90]} zoom={6} scrollWheelZoom={false}>
        <TileLayer
          attribution=''
          //url="https://tiles.stadiamaps.com/tiles/alidade_satellite/{z}/{x}/{y}{r}.jpg"
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MarkerClusterGroup chunkedLoading>
          {(addressPoints as AdressPoint).map((meterData, index) => (
            <Marker
              icon={customIcon}
              key={index}
              position={[meterData[0], meterData[1]]}
              title={meterData[2]}
            >

              <Popup  className="request-popup">
              <div style={popupContent}>
                  <img
                    src={meterData[6]}
                    width="150"
                    height="150"
                    alt="user"
                  />
                  <div className="m-2" style={popupHead}>
                   {meterData[3]}
                  </div>
                  <span style={popupText}>
                    <strong>Address:</strong> {meterData[7]}
                  </span>
                  <span style={popupText}>
                  <strong>Phone Number:</strong> {meterData[2]}
                  </span>
                  <span style={popupText}>
                  <span className={`badge ${meterData[5] === 'Active' ? 'badge-primary' : 'badge-danger'}`}>
                    {meterData[5]}
                  </span>
                  <span style={popupText}>
                  
                  </span>

                  <a href="/users/customers/3" target="_blank" rel="noopener noreferrer">
                  <button type="button" className="btn btn-success">
                    <i className="ki-duotone ki-eye fs-2"></i>
                    View Customer 
                  </button>
                  </a>


                  </span>
                  
                </div>
              </Popup>

            </Marker>
          ))}
        </MarkerClusterGroup>
      </MapContainer>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='wrench'
            color='danger'
            iconColor='white'
            title='Scheduled Services'
            titleColor='white'
            description='Solar Panel Installation,Battery Maintenance'
            descriptionColor='white'
          />
        </div>

        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='electricity'
            color='primary'
            iconColor='white'
            title='Installations'
            titleColor='white'
            descriptionColor='white'
            description='Residential Solar System,Commercial PV Panels'
          />
        </div>

        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='chart-simple-3'
            color='success'
            iconColor='white'
            titleColor='white'
            descriptionColor='white'
            title='Energy Savings'
            description='CO2 Emissions Saved'
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        {/* begin::Col */}
        <div className='col-xl-4'>
          <JobServiceSummary
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='150px'
            isLoading={isLoading}
            stats={stats}
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-4'>
          <JobPerformanceSummary 
            className='card-xl-stretch' 
            chartColor='primary' 
            chartHeight='225px'
            isLoading={isLoading}
            stats={stats}
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-4'>
          <JobStatusSummary
            className='card-xl-stretch mb-5 mb-xl-8'
            chartColor='danger'
            chartHeight='150px'
            isLoading={isLoading}
            stats={stats}
          />
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='g-5 gx-xxl-8'>
        {/*<TablesWidget11 className='' />*/}
      </div>
      {/* end::Row */}
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
