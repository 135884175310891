/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import { toast } from 'react-toastify';
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {KTIcon} from '../../../../../_metronic/helpers'
import {useListView} from '../../../../common/table/core/ListViewProvider'
import ConfirmDeleteDialog from "../../../../common/components/ConfirmDeleteDialog"
import ResponsesModal from '../components/ResponsesModal';
import AssignTicketModal from '../components/AssignTicketModal';

// I refactored this cos actions might differ
const ActionsCell = ({id, row, queryList, useQueryResponse, deleteEntity}) => {
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const [isDialogVisible, setDialogVisible] = useState(false);
  const [isResponseModalOpen, setResponseModalOpen] = useState(false);
  const [isAssignModalOpen, setAssignModalOpen] = useState(false);

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const deleteItem = useMutation(() => deleteEntity(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${queryList}-${query}`])
      toast.success('Records deleted successfully');
    },
    onError: (error) => {
      // ❌ Handle the
      console.error('Error deleting record:', error);
      setDialogVisible(false)
      toast.error('Failed to delete records');
    },
  })

  const handleDelete = async () => {
    await deleteItem.mutateAsync();
    setDialogVisible(false)
  };

  const handleCancelConfirm = () => {
    setDialogVisible(false);
  };

  const handleShowDialog = () => {
    setDialogVisible(true);
  };

  const handleResponseModal = () => {
    setResponseModalOpen(!isResponseModalOpen);
  }

  const closeResponseModal = () => {
    setResponseModalOpen(!isResponseModalOpen)
  }

  const handleAssignModal = () => {
    setAssignModalOpen(!isAssignModalOpen);
  }

  const closeAssignModal = () => {
    setAssignModalOpen(!isAssignModalOpen)
  }

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            <i className="bi bi-pencil-square"></i>&nbsp; Edit
          </a>
        </div>
        {/* end::Menu item */}
        
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={handleResponseModal}>
            <i className="bi bi-chat-dots"></i>&nbsp; View
          </a>
        </div>
        <ResponsesModal
          open={isResponseModalOpen}
          onClose={closeResponseModal}
          row={row}
        />
        {/* end::Menu item */}
        

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={handleAssignModal}>
            <i className="bi bi-link-45deg"></i> &nbsp; Assign
          </a>
        </div>
        <AssignTicketModal
          open={isAssignModalOpen}
          onClose={closeAssignModal}
          row={row}
        />
        {/* end::Menu item */}
        
        {/* begin::Menu item */}
        {/*<div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            <i className="bi bi-file-earmark-lock"></i> &nbsp; close
          </a>
        </div>*/}
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-table-filter='delete_row'
            // onClick={async () => await deleteItem.mutateAsync()}
            onClick={handleShowDialog}
          >
            <i className="bi bi-trash"></i> &nbsp; Delete
          </a>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
      <ConfirmDeleteDialog
        open={isDialogVisible}
        onCancel={handleCancelConfirm}
        onConfirm={handleDelete}
      />
    </>
  )
}

export {ActionsCell}
