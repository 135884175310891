import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import { useState } from "react";
import "./styles.css";

const DateTime = ({ defaultValue, columns, label, name, formik, isLoading }) => {
  const [dateState, setDateState] = useState<any>({
    date: new Date(defaultValue || new Date()) // Set initial date to defaultValue or current date
  });

  return (
    <div className={`col-md-${columns}`}>
      <div className="mb-10">
        <label className="form-label">{label}</label>
        <Flatpickr
          value={dateState.date}
          onChange={([date]) => {
            setDateState({ date });
            // Adjust timezone offset
            const adjustedDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
            formik.setFieldValue(name, adjustedDate);
          }}
          options={{
            enableTime: true,
            dateFormat: "Y-m-d H:i"
          }}
          className='form-control'
          placeholder='Pick date'
          disabled={isLoading}
        />
      </div>
    </div>
  );
}

export default DateTime;
