// @ts-nocheck
import {FC} from 'react'
import {Column} from 'react-table'
import {ActionsCell} from './ActionsCell'
import {TableCustomHeader} from '../../../../common/table/columns/TableCustomHeader'
import {TableSelectionHeader} from '../../../../common/table/columns/TableSelectionHeader'
import {TableSelectionCell} from '../../../../common/table/columns/TableSelectionCell'
import {Job} from '../core/_models'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {deleteJob} from '../core/_requests'
import {QUERIES, capitalize} from '../../../../../_metronic/helpers'


type Props = {
  job: Job
}


const ColumnCell: FC<Props> = ({ job, fieldName }) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>{capitalize(job[fieldName])}</div>
    </div>
  )
}

const StatusCell: FC<Props> = ({ job }) => {
  return (
      <>
        {job.status === 'pending' ? (
          <div className='d-flex align-items-center'>
            <span className="badge badge-primary">{capitalize(job.status)}</span>
          </div>
        ) : (
          <div className='d-flex align-items-center'>
            <span className="badge badge-info">{capitalize(job.status)}</span>
          </div>
        )}
      </>
  );
}

const jobsColumns: ReadonlyArray<Column<Job>> = [
  {
    Header: (props) => <TableSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <TableSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Title' className='min-w-125px' />,
    id: 'title',
    Cell: ({ ...props }) => <ColumnCell job={props.data[props.row.index]} fieldName='title' />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Service type' className='min-w-125px' />,
    id: 'service_type',
    Cell: ({ ...props }) => <ColumnCell job={props.data[props.row.index]} fieldName='service_type' />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='State' className='min-w-125px' />,
    id: 'state',
    Cell: ({ ...props }) => <ColumnCell job={props.data[props.row.index]} fieldName='state' />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    id: 'status',
    Cell: ({ ...props }) => <StatusCell job={props.data[props.row.index]} fieldName='status' />,
  },
  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ActionsCell status={props.data[props.row.index].status} id={props.data[props.row.index].id} queryList={QUERIES.JOBS_LIST} useQueryResponse={useQueryResponse} deleteEntity={deleteJob} />,
  },
]

export {jobsColumns}
