import { Bar } from 'react-chartjs-2';

const RatingBarChart = ({ jobData }) => {
  const jobLabels = jobData.map((job) => job.title);
  const ratingAverages = jobData.map((job) => job.rating_average);

  const data = {
    labels: jobLabels,
    datasets: [
      {
        label: 'Rating Average',
        data: ratingAverages,
        backgroundColor: 'rgba(75, 192, 192, 0.6)', // Customize color as needed
        borderColor: 'rgba(75, 192, 192, 1)', // Customize color as needed
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        max: 5, // Assuming a maximum rating of 5
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default RatingBarChart;
