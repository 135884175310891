import Select from 'react-select';
import { useState, useEffect } from 'react';
import { getAllCustomers } from '../../pages/users/customers/core/_requests';

const Customers = ({ label, name, defaultValue, formik }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const data = await getAllCustomers();
        if (data.data) {
          const options = data.data.map((customer) => ({
            label: customer.user?.firstname +" "+customer.user?.lastname,
            value: customer.id,
          }));
          setOptions(options);
        }
      } catch (error) {
        console.error('Error fetching customers:', error);
      }
    };

    fetchCustomers();
  }, []);

  return (
    <>
      <div className='mb-10'>
        <label className='form-label'>{label}</label>
        <Select
          className='react-select-styled react-select-solid'
          classNamePrefix='react-select'
          options={options}
          placeholder='Select an option'
          defaultValue={defaultValue} 
          onChange={(selectedOption) => formik.setFieldValue('customer', selectedOption.value)}
        />
      </div>
    </>
  );
};

export { Customers };
