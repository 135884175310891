import {ListViewProvider, useListView} from '../../../common/table/core/ListViewProvider'
import {QueryRequestProvider} from '../../../common/table/core/QueryRequestProvider'
import {QueryResponseProvider, useQueryResponse, useQueryResponseData} from './core/QueryResponseProvider'
import {TicketsListHeader} from './components/TicketsListHeader'
import {TicketsTable} from './table/TicketsTable'
import {KTCard} from '../../../../_metronic/helpers'
import {TicketModal} from  './components/TicketModal'

const TicketsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <TicketsListHeader />
        <TicketsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <TicketModal title="ticket"/>}
    </>
  );
}

const TicketsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider
        useQueryResponse={useQueryResponse}
        useQueryResponseData={useQueryResponseData}
      >
        <TicketsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {TicketsListWrapper}
