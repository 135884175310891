import React from "react";
import {FeedbacksListWrapper} from './FeedbacksList'
import {PageTitle} from '../../../../_metronic/layout/core'

const feedbacksBreadcrumbs = [
  {
    title: '',
    path: '/support/feedbacks',
    isSeparator: false,
    isActive: false,
  },
]

export default function FeedbacksPage() {
    return (
        <>
            <PageTitle breadcrumbs={feedbacksBreadcrumbs} description='Manage feedbacks'>
                Support Feedbacks
            </PageTitle>
            <FeedbacksListWrapper />
        </>
    )
}