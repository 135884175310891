import React from "react";
import {MetricsListWrapper} from './MetricsList'
import {PageTitle} from '../../../../_metronic/layout/core'

const usersBreadcrumbs = [
  {
    title: '',
    path: '/installers/metrics',
    isSeparator: false,
    isActive: false,
  },
]

export default function MetricsPage() {
    return (
        <>
            <PageTitle breadcrumbs={usersBreadcrumbs} description='Manage metrics'>
                Installers Metrics
            </PageTitle>
            <MetricsListWrapper />
        </>
    )
}