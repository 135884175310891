import React from "react";
import {JobsHistoryListWrapper} from './JobsHistoryList'
import {PageTitle} from '../../../../_metronic/layout/core'

const historyBreadcrumbs = [
  {
    title: '',
    path: '/jobs/history',
    isSeparator: false,
    isActive: false,
  },
]

export default function JobsHistoryPage() {
    return (
        <>
            <PageTitle breadcrumbs={historyBreadcrumbs} description='Completed Jobs'>
                Jobs History
            </PageTitle>
            <JobsHistoryListWrapper />
        </>
    )
}