import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import {PageTitle} from '../../../../_metronic/layout/core'
import { getInvoiceById } from './core/_requests';
import { Link } from 'react-router-dom';
import PleaseWait from '../../../common/components/PleaseWait'
import {capitalize, formatDate, CURRENCY} from '../../../../_metronic/helpers'

import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';

import PaymentModal from './components/PaymentModal';

function PaymentsTable({payments, invoiceId, invoiceStatus, jobStatus}) {

  const [filters, setFilters] = useState({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      title: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      status: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
  const [paymentId, setPaymentId] = useState<any>(null)
  const [amount, setAmount] = useState<any>(0)

  const onGlobalFilterChange = (e) => {
      const value = e.target.value;
      let _filters = { ...filters };

      _filters['global'].value = value;

      setFilters(_filters);
      setGlobalFilterValue(value);
  };

  const renderHeader = () => {
      return (
          <div className="flex justify-content-end">
              <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
              </span>
          </div>
      );
  };


  const amountBodyTemplate = (rowData) => {
    const amount = rowData.amount
    return (
      <span className='fw-bold text-gray-500 fs-6'>
        {CURRENCY.format(amount)}
      </span>
    )
  }


  const paymentDateBodyTemplate = (rowData) => {
    const date = rowData.paymentDate
    return (
      <span className='fw-bold text-gray-500 fs-6'>
        {formatDate(date)}
      </span>
    )
  }

  const handleOpenCreateModal = () => {
    setPaymentModalOpen(true);
    setPaymentId(null); // Reset paymentId for new payment
  };

  const handleOpenEditModal = (id, amount) => {
    setPaymentModalOpen(true);
    setPaymentId(id); // Set the paymentId for editing
    setAmount(amount)
  };

  const closePaymentModal = () => {
  	setPaymentId(null)
    setPaymentModalOpen(false)
  }

  const  viewBodyTemplate = (rowData) => {
    return (
      <span className='fw-bold text-gray-600 fs-6'>
        <button className='btn btn-secondary btn-sm' onClick={() => handleOpenEditModal(rowData.id, rowData.amount)}><i className="bi bi-pencil-square sm"></i></button>
      </span>
    )
  }


  const header = renderHeader();


  return (
      <div className="mt-10">
        <div className="card-header">
          {/* Card title */}
          <div className="card-title m-0">
            <h3 className="fw-bold">Payments</h3>
            {/* Action */}
          </div>
	      <div className="card-title m-0">
			<button
        className="btn btn-primary align-self-center"
        onClick={handleOpenCreateModal}
        disabled={invoiceStatus === 'paid' || jobStatus === 'pending'}
      >
				<i className="bi bi-plus"></i> New Payment
			</button>
	      </div>
	      <PaymentModal
	        open={isPaymentModalOpen}
	        onClose={closePaymentModal}
	        id={paymentId}
          invoiceId={invoiceId}
          amount={amount}
	      />
        </div>
          <DataTable value={payments} paginator rows={10} dataKey="id" filters={filters} filterDisplay="row" loading={false}
            globalFilterFields={['amount', 'paymentDate']} header={header} emptyMessage="No payments found." tableStyle={{ 
              backgroundColor: 'red'
             }}>
              <Column field="amount" header="Amount"  body={amountBodyTemplate}/>
              <Column field="paymentDate" header="Payment date"  body={paymentDateBodyTemplate}/>
              <Column headerStyle={{ width: '8rem', textAlign: 'center' }} header="View"  body={viewBodyTemplate} />
          </DataTable>
      </div>
  );
}

function InvoiceDetails({invoice}){
  console.log(invoice)
	return (
		<>
			<div className='card mb-5 mb-xl-10'>
				<div className="card-header">
				  {/* Card title */}
				  <div className="card-title m-0">
				    <h3 className="fw-bold m-0">Invoice Details -:: {invoice?.invoice_no}</h3>
				  </div>
				  {/* Action */}
				  <Link to={`/finance/invoices/`} className="btn btn-sm btn-primary align-self-center">
				  	<i className="bi bi-arrow-return-left"></i> Go back
				  </Link>
				</div>

				{/* Card body */}
				<div className="card-body p-9">
	      			<div className="row mb-3">
	      				<div className="col">
	      					<p className="mb-2 fs-4">Issued on: <strong>{formatDate(invoice?.issueDate)}</strong></p>
	      					<p className="mb-2 fs-4">Due date: <strong>{formatDate(invoice?.dueDate)}</strong></p>
	      					<p className="mb-2 fs-4">Paid on: <strong>{formatDate(invoice?.paidDate) ?? '---'}</strong></p>
	      				</div>
	      				<div className="col">
	      					<p className="mb-2 fs-4">Total amount: <strong>{CURRENCY.format(invoice?.total_amount)}</strong></p>
	      					<p className="mb-2 fs-4">Balance: <strong>{CURRENCY.format(invoice?.remaining_balance) ?? '0.0'}</strong></p>
	      					<p className="mb-2 fs-4">Status: <strong>{capitalize(invoice?.status)}</strong></p>
	      				</div>
	      			</div>
	      			<hr />
	      			<div className="row mb-3">
	      				<div className="col">
	      					<p className="mb-2 fs-4">Customer: <Link to={`/users/customers/${invoice.customer_id}`}>{invoice.customer.firstname} {invoice.customer.lastname}</Link></p>
	      			    	<p className="mb-2 fs-4">Service Type: <Link to={`/jobs/history/${invoice.job.id}`}>{capitalize(invoice.job.service_type)}</Link></p>
	      				</div>
	      				<div className="col">
                			<p className="mb-2 fs-4">Customer Email: <strong>{invoice.customer.email}</strong></p>
	      			    	<p className="mb-2 fs-4">Job Status: <strong>{capitalize(invoice.job.status)}</strong></p>
	      				</div>
	      			</div>
	      			<hr />
	      			<PaymentsTable
                payments={invoice?.payments ?? []}
                invoiceId={invoice.id}
                invoiceStatus={invoice.status}
                jobStatus={invoice.job.status}
              />
				</div>
			</div>
		</>
	)
}

export default function InvoiceViewPage() {
    const { id } = useParams();
    const [invoice, Invoice] = useState({});
    const [isLoading, setIsLoading] = useState(true)
    const [title, setTitle] = useState('')

    useEffect(() => {
        const fetchInvoice = async () => {
          try {
            const data = await getInvoiceById(id);
            if(data){
              Invoice(data)
              setTitle(data?.invoice_no!)
            }
          } catch (error) {
            console.error('Error fetching invoice:', error);
          } finally {
            setIsLoading(false)
          }
        };
        fetchInvoice();
    }, [id])

    return (
      <>
        <PageTitle description={title}>
          <Link to="/finance/invoices">Invoices</Link>
        </PageTitle>
        {isLoading ? <PleaseWait /> : <InvoiceDetails invoice={invoice}/>}
      </>
    );
}