// @ts-nocheck
import {FC} from 'react'
import clsx from 'clsx'
import {Column} from 'react-table'
import {ActionsCell} from './ActionsCell'
import {TableCustomHeader} from '../../../../common/table/columns/TableCustomHeader'
import {TableSelectionHeader} from '../../../../common/table/columns/TableSelectionHeader'
import {TableSelectionCell} from '../../../../common/table/columns/TableSelectionCell'
import {Paymentrequest} from '../core/_models'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {deletePaymentrequest} from '../core/_requests'
import {QUERIES, getInitials, getRandomColor, capitalize, getPaymentRequestType, CURRENCY} from '../../../../../_metronic/helpers'


type Props = {
  paymentrequest: Paymentrequest
}

const UserInfoCell: FC<Props> = ({ user }) => {

  const initials = getInitials(user.user.firstname, user.user.lastname);
  const randomColor = getRandomColor();

  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        {user.user.avatar_url ? (
          <div className='symbol-label'>
            <img src={`${user.user.avatar_url}`} alt={`${user.user.firstname} ${user.user.lastname}`} className='w-100' />
          </div>
        ) : (
          <div
            className={clsx(
              'symbol-label fs-3',
              `bg-light${randomColor}`,
              `text-${randomColor}`
            )}
          >
            {initials}
          </div>
        )}
      </div>
      <div className='d-flex flex-column'>
        <div className='text-gray-800 text-hover-primary mb-1'>
          {user.user.firstname} {user.user.lastname}
        </div>
        <span>{user.user.email}</span>
      </div>
    </div>
  );
};

const AmountCell: FC<Props> = ({ paymentrequest, fieldName }) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>{CURRENCY.format(paymentrequest[fieldName])}</div>
    </div>
  )
}

const ColumnCell: FC<Props> = ({ paymentrequest, fieldName }) => {
  if(fieldName == 'request_type'){
    return (
      <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>{capitalize(getPaymentRequestType(paymentrequest[fieldName]))}</div>
      </div>
    )  
  }
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>{capitalize(paymentrequest[fieldName])}</div>
    </div>
  )
}

const StatusCell: FC<Props> = ({ paymentrequest }) => {
  return (
      <>
        {paymentrequest.approval_status === 'pending' ? (
          <div className='d-flex align-items-center'>
            <span className="badge badge-primary">{capitalize(paymentrequest.approval_status)}</span>
          </div>
        ) : (
          <div className='d-flex align-items-center'>
            <span className="badge badge-info">{capitalize(paymentrequest.approval_status)}</span>
          </div>
        )}
      </>
  );
}

const paymentrequestsColumns: ReadonlyArray<Column<Paymentrequest>> = [
  {
    Header: (props) => <TableSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <TableSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'firstname',
    Cell: ({ ...props }) => <UserInfoCell user={props.data[props.row.index]} fieldName='name' />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Request type' className='min-w-125px' />,
    id: 'request_type',
    Cell: ({ ...props }) => <ColumnCell paymentrequest={props.data[props.row.index]} fieldName='request_type' />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Amount' className='min-w-125px' />,
    id: 'amount_requested',
    Cell: ({ ...props }) => <AmountCell paymentrequest={props.data[props.row.index]} fieldName='amount_requested' />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    id: 'approval_status',
    Cell: ({ ...props }) => <StatusCell paymentrequest={props.data[props.row.index]} fieldName='approval_status' />,
  },
  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ActionsCell row={props.data[props.row.index]} id={props.data[props.row.index].id} queryList={QUERIES.PAYMENTREQUESTS_LIST} useQueryResponse={useQueryResponse} deleteEntity={deletePaymentrequest} />,
  },
]

export {paymentrequestsColumns}
