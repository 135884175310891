// @ts-nocheck
import clsx from 'clsx';
import { FC } from 'react';
import { Row } from 'react-table';

type Props<T> = {
  row: Row<T>;
};

const TableRow: FC<Props<any>> = ({ row }) => (
  <tr {...row.getRowProps()}>
    {row.cells.map((cell) => {
      return (
        <td
          {...cell.getCellProps()}
          className={clsx({ 'text-end min-w-100px': cell.column.id === 'actions' })}
        >
          {cell.render('Cell')}
        </td>
      );
    })}
  </tr>
);

export { TableRow };
