// @ts-nocheck
import {FC} from 'react'
import {Column} from 'react-table'
import {ActionsCell} from './ActionsCell'
import {TableCustomHeader} from '../../../../common/table/columns/TableCustomHeader'
import {TableSelectionHeader} from '../../../../common/table/columns/TableSelectionHeader'
import {TableSelectionCell} from '../../../../common/table/columns/TableSelectionCell'
import {Certificate} from '../core/_models'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {deleteCertificate} from '../core/_requests'
import {QUERIES, capitalize} from '../../../../../_metronic/helpers'


type Props = {
  certificate: Certificate
}


const ColumnCell: FC<Props> = ({ certificate, fieldName }) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>{capitalize(certificate[fieldName])}</div>
    </div>
  )
}

const certificatesColumns: ReadonlyArray<Column<Certificate>> = [
  {
    Header: (props) => <TableSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <TableSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='title' className='min-w-125px' />,
    id: 'title',
    Cell: ({ ...props }) => <ColumnCell certificate={props.data[props.row.index]} fieldName='title' />,
  },
  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ActionsCell row={props.data[props.row.index]} id={props.data[props.row.index].id} queryList={QUERIES.CERTIFICATES_LIST} useQueryResponse={useQueryResponse} deleteEntity={deleteCertificate} />,
  },
]

export {certificatesColumns}
