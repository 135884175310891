import Select from 'react-select';
import {REQUEST_TYPES} from '../../../_metronic/helpers';

const RequestTypes = ({label, name, defaultValue, formik}) => {
    const options = Object.entries(REQUEST_TYPES).map(([key, value]) => ({label: value, value: key}))
    const defaultVal = options.filter((type) => type['value'] == defaultValue)

    return (
        <>
            <div className='mb-10'>
                <label className='form-label'>{label}</label>
                <Select 
                    className='react-select-styled react-select-lg' 
                    classNamePrefix='react-select' 
                    options={options} 
                    placeholder='Select an option'
                    defaultValue={defaultVal}
                    name={name}
                    onChange={(type) => formik.setFieldValue('request_type', type?.value)}
                />
            </div>
        </>
    )
}

export { RequestTypes };