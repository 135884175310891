// @ts-nocheck
import {FC} from 'react'
import clsx from 'clsx'
import {Column} from 'react-table'
import {ActionsCell} from './ActionsCell'
import {TableCustomHeader} from '../../../../common/table/columns/TableCustomHeader'
import {TableSelectionHeader} from '../../../../common/table/columns/TableSelectionHeader'
import {TableSelectionCell} from '../../../../common/table/columns/TableSelectionCell'
import {User} from '../core/_models'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {deleteUser} from '../core/_requests'
import {QUERIES, getInitials, getRandomColor, capitalize} from '../../../../../_metronic/helpers'
import { Link } from 'react-router-dom';


type Props = {
  user: User
}

const UserInfoCell: FC<Props> = ({ user }) => {

  const initials = getInitials(user.firstname, user.lastname);
  const randomColor = getRandomColor();

  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <Link to={`/users/admins/${user.id}`}>
          {user.avatar_url ? (
            <div className='symbol-label'>
              <img src={`${user.avatar_url}`} alt={`${user.firstname} ${user.lastname}`} className='w-100' />
            </div>
          ) : (
            <div
              className={clsx(
                'symbol-label fs-3',
                `bg-light${randomColor}`,
                `text-${randomColor}`
              )}
            >
              {initials}
            </div>
          )}
        </Link>
      </div>
      <div className='d-flex flex-column'>
        <Link to={`/users/admins/${user.id}`} className='text-gray-800 text-hover-primary mb-1'>
          {user.firstname} {user.lastname}
        </Link>
        <span>{user.email}</span>
      </div>
    </div>
  );
};

const ColumnCell: FC<Props> = ({ user, fieldName }) => {
  const roles = user.roles.map((role) => role.name);
  const isAdmin = roles.includes('SUPER ADMIN');

  return (
    <div className="d-flex align-items-center">
      <div className="d-flex flex-column">
        {isAdmin && fieldName === "username" ? (
          <span className="badge badge-light-success">{capitalize(user[fieldName])}</span>
        ) : (
          capitalize(user[fieldName])
        )}
      </div>
    </div>
  );
};

const StatusCell: FC<Props> = ({ user }) => {
  return (
      <>
        {user.is_active ? (
          <div className='d-flex align-items-center'>
            <span className="badge badge-primary">Active</span>
          </div>
        ) : (
          <div className='d-flex align-items-center'>
            <span className="badge badge-secondary">Inactive</span>
          </div>
        )}
      </>
  );
}

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <TableSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <TableSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'firstname',
    Cell: ({ ...props }) => <UserInfoCell user={props.data[props.row.index]} fieldName='name' />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Username' className='min-w-125px' />,
    id: 'username',
    Cell: ({ ...props }) => <ColumnCell user={props.data[props.row.index]} fieldName='username' />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='State' className='min-w-125px' />,
    id: 'state',
    Cell: ({ ...props }) => <ColumnCell user={props.data[props.row.index]} fieldName='state' />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    id: 'status',
    Cell: ({ ...props }) => <StatusCell user={props.data[props.row.index]} fieldName='state' />,
  },
  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ActionsCell id={props.data[props.row.index].id} queryList={QUERIES.USERS_LIST} useQueryResponse={useQueryResponse} deleteEntity={deleteUser} />,
  },
]

export {usersColumns}
