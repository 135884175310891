import {ListViewProvider, useListView} from '../../../common/table/core/ListViewProvider'
import {QueryRequestProvider} from '../../../common/table/core/QueryRequestProvider'
import {QueryResponseProvider, useQueryResponse, useQueryResponseData} from './core/QueryResponseProvider'
import {PaymentrequestsListHeader} from './components/PaymentRequestHistoryListHeader'
import {PaymentrequestsTable} from './table/PaymentRequestHistoryTable'
import {KTCard} from '../../../../_metronic/helpers'
import {PaymentrequestModal} from  './components/PaymentRequestHistoryModal'

const PaymentrequestsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <PaymentrequestsListHeader />
        <PaymentrequestsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <PaymentrequestModal title="paymentrequest"/>}
    </>
  );
}

const PaymentrequestsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider
        useQueryResponse={useQueryResponse}
        useQueryResponseData={useQueryResponseData}
      >
        <PaymentrequestsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {PaymentrequestsListWrapper}
