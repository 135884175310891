import * as Yup from 'yup'
import {useEffect} from 'react'
import {FC, useState} from 'react'
import {useQuery} from 'react-query'
import { toast } from 'react-toastify';
import {KTIcon, QUERIES, isNotEmpty} from '../../../../../_metronic/helpers'
import {useListView} from '../../../../common/table/core/ListViewProvider'
import {TableListLoader} from '../../../../common/table/components/TableListLoader'
import {useFormik} from 'formik'
import {getInstallerById, createInstaller, updateInstaller} from '../core/_requests'
import {Installer} from '../core/_models'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {States} from '../../../../common/components/States'
import {Skills} from '../../../../common/components/Skills'
import InputField from '../../../../common/components/InputField'
import AvatarUpload from '../../../../common/components/AvatarUpload'

type Props = {
  isInstallerLoading: boolean
  installer: Installer
}

const InstallerSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  lastname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  username: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('User name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('First name is required'),
  phone: Yup.string(),
  state: Yup.string()
    .required('State is required'),
  latitude: Yup.number(),
  longitude: Yup.number(),
  address: Yup.string(),
  password: Yup.string(),
  availability_status: Yup.boolean(),
  kyc_verified: Yup.boolean(),
  is_interviewed: Yup.boolean(),
  is_verified: Yup.boolean()
})

const ModalForm: FC<Props> = ({installer, isInstallerLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [avatarUrl, setAvatarUrl] = useState(installer.user?.avatar_url)

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  const initialValues = {
    id: installer.id,
    firstname: installer.user?.firstname || '',
    lastname: installer.user?.lastname || '',
    username: installer.user?.username || '',
    email: installer.user?.email || '',
    phone: installer.user?.phone || '',
    avatar_url: avatarUrl || '',
    user_type: installer.user?.user_type || 3,
    is_active: installer.user?.is_active || false,
    state: installer.user?.state || '',
    skills: installer.details?.skills || [],
    address: installer.details?.contact_info?.address || '',
    latitude: installer.details?.contact_info?.latitude || '',
    longitude: installer.details?.contact_info?.longitude || '',
    rating_average: installer.details?.rating_average || 0,
    availability_status: installer.details?.availabilityStatus || false,
    kyc_verified: installer.details?.kyc_verified || false,
    is_interviewed: installer.details?.is_interviewed || false,
    is_verified: installer.details?.is_verified || false,
    password: installer.user?.password || '',
  }
  const [availabilityStatus, setAvailabilityStatus] = useState(initialValues.availability_status);
  const [isKycVerified, setIsKycVerified] = useState(initialValues.kyc_verified);
  const [isInterviewed, setIsInterviewed] = useState(initialValues.is_interviewed);
  const [isVerified, setIsVerified] = useState(initialValues.is_verified);
  const [isActive, setIsActive] = useState(initialValues.is_active);

  const [installerForEdit] = useState(initialValues)

  const formik = useFormik({
    initialValues: installerForEdit,
    validationSchema: InstallerSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      const payload = {
        id: values.id,
        user: {
          firstname: values.firstname,
          lastname: values.lastname,
          username: values.username,
          email: values.email,
          phone: values.phone,
          avatar_url: avatarUrl,
          user_type: values.user_type,
          is_active: isActive,
          state: values.state,
          password: values.password
        },
        details: {
          user_id: values.id,
          contact_info: {
            address: values.address,
            latitude: values.latitude,
            longitude: values.longitude,
          },
          rating_average: values.rating_average,
          availability_status: availabilityStatus,
          is_interviewed: isInterviewed,
          is_verified: isVerified,
          kyc_verified: isKycVerified,
          skills: values.skills
        }
      }
      try {
        if (isNotEmpty(values.id)) {
          await updateInstaller(payload)
          toast.success('Record updated successfully');
        } else {
          delete payload['id'];
          delete payload['details']['user_id'];
          await createInstaller(payload)
          toast.success('Record created successfully');
        }
      } catch (ex) {
        console.error(ex)
        toast.warning('An error occured');
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const handleUploadSuccess = (imageUrl) => {
    formik.setFieldValue('avatar_url', imageUrl);
    setAvatarUrl(imageUrl);
  };
  
  return (
    <>
      <form id='kt_modal_add_installer_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_installer_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_installer_header'
          data-kt-scroll-wrappers='#kt_modal_add_installer_scroll'
          data-kt-scroll-offset='300px'
        >

          {/* Set the profile image */}
          <AvatarUpload
            avatarSrc={initialValues.avatar_url}
            onUploadSuccess={handleUploadSuccess}
          />

          <div className="row">
            <InputField
              isLoading={isInstallerLoading}
              formik={formik}
              name="firstname"
              label="First Name"
              placeholder="First Name"
              type='text'
              columns={6}
            />

            <InputField
              isLoading={isInstallerLoading}
              formik={formik}
              name="lastname"
              label="Last Name"
              placeholder="Last Name"
              type='text'
              columns={6}
            />
          </div>

          <div className="row">
            <InputField
              isLoading={isInstallerLoading}
              formik={formik}
              name="username"
              label="User Name"
              placeholder="User Name"
              type='text'
              columns={6}
            />

            <InputField
              isLoading={isInstallerLoading}
              formik={formik}
              name="phone"
              label="phone"
              placeholder="Phone number"
              type='text'
              columns={6}
            />
          </div>

          <div className="row">
            <InputField
              isLoading={isInstallerLoading}
              formik={formik}
              name="email"
              label="Email address"
              placeholder="email"
              type='email'
              columns={12}
            />
          </div>

          <div className="row">
            <div className="col-md-4">
              <States 
                label="Select state"
                defaultValue={initialValues.state}
                name="state"
                formik={formik}
              />
            </div>

             <InputField
              isLoading={isInstallerLoading}
              formik={formik}
              name="address"
              label="Address"
              placeholder="Address"
              type='text'
              columns={8}
            />
          </div>
          
          
          <div className="row">
            <InputField
              isLoading={isInstallerLoading}
              formik={formik}
              name="latitude"
              label="Latitude"
              placeholder="Latitude"
              type='text'
              columns={6}
            />
            <InputField
              isLoading={isInstallerLoading}
              formik={formik}
              name="longitude"
              label="Longitude"
              placeholder="Longitude"
              type='text'
              columns={6}
            />
          </div>
          <div className="row">
            <div className="col-md-12">
              <Skills 
                label="Select skills"
                defaultValue={initialValues.skills}
                name="skills"
                formik={formik}
              />
            </div>
          </div>

          <div className="row">
            <InputField
                isLoading={isInstallerLoading}
                formik={formik}
                name="rating_average"
                label="Rating"
                placeholder="Rating"
                type='number'
                columns={6}
              />

            <InputField
              isLoading={isInstallerLoading}
              formik={formik}
              name="password"
              label="Password"
              placeholder="Password"
              type='text'
              columns={6}
            />
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="mb-10">
                <div className="form-check form-switch form-check-custom form-check-solid me-10">
                  <input 
                    className="form-check-input h-30px w-70px"
                    type="checkbox"
                    id="flexSwitch30x50"
                    {...formik.getFieldProps('is_active')}
                    checked={isActive}
                    onChange={(e) => setIsActive(e.target.checked)}
                    />
                  <label className="form-check-label">
                      Is Active (can installer login to the system)
                  </label>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-10">
                <div className="form-check form-switch form-check-custom form-check-solid me-10">
                  <input 
                    className="form-check-input h-30px w-70px"
                    type="checkbox"
                    id="flexSwitch30x50"
                    {...formik.getFieldProps('availability_status')}
                    checked={availabilityStatus}
                    onChange={(e) => setAvailabilityStatus(e.target.checked)}
                    />
                  <label className="form-check-label">
                      Is Available (is installer available for job)
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="mb-10">
                <div className="form-check form-switch form-check-custom form-check-solid me-10">
                  <input 
                    className="form-check-input h-30px w-70px"
                    type="checkbox"
                    id="flexSwitch30x50"
                    {...formik.getFieldProps('kyc_verified')}
                    checked={isKycVerified}
                    onChange={(e) => {
                      setIsKycVerified(e.target.checked)
                      if(e.target.checked && isInterviewed){
                        setIsVerified(true)
                      } else {
                        setIsVerified(false)
                      }
                    }}
                    />
                  <label className="form-check-label">
                      KYC Status (has passed KYC)
                  </label>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-10">
                <div className="form-check form-switch form-check-custom form-check-solid me-10">
                  <input 
                    className="form-check-input h-30px w-70px"
                    type="checkbox"
                    id="flexSwitch30x50"
                    {...formik.getFieldProps('is_interviewed')}
                    checked={isInterviewed}
                    onChange={(e) => {
                      setIsInterviewed(e.target.checked)
                      if(e.target.checked && isKycVerified){
                        setIsVerified(true)
                      } else {
                        setIsVerified(false)
                      }
                    }}
                    />
                  <label className="form-check-label">
                      Interview (has passed interview)
                  </label>
                </div>
              </div>
            </div>

          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="mb-10">
                <div className="form-check form-switch form-check-custom form-check-solid me-10">
                  <input 
                    className="form-check-input h-30px w-70px"
                    type="checkbox"
                    id="flexSwitch30x50"
                    {...formik.getFieldProps('is_verified')}
                    checked={isVerified}
                    onChange={(e) => {
                      setIsInterviewed(e.target.checked)
                      setIsKycVerified(e.target.checked)
                      setIsVerified(e.target.checked)
                    }}
                    />
                  <label className="form-check-label">
                      Account Status (has passed verification)
                  </label>
                </div>
              </div>
            </div>

          </div>
          
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-installers-modal-action='cancel'
            disabled={formik.isSubmitting || isInstallerLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-installers-modal-action='submit'
            disabled={isInstallerLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isInstallerLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isInstallerLoading) && <TableListLoader />}
    </>
  )
}

const ModalHeader = ({title}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>{itemIdForUpdate ? `Edit ${title}` : `Add ${title}`}</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-installers-modal-action='close'
        onClick={() => setItemIdForUpdate(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

const ModalWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: installer,
    error,
  } = useQuery(
    `${QUERIES.INSTALLERS_LIST}-installer-${itemIdForUpdate}`,
    () => {
      return getInstallerById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <ModalForm isInstallerLoading={isLoading} installer={{id: undefined}} />
  }

  if (!isLoading && !error && installer) {
    return <ModalForm isInstallerLoading={isLoading} installer={installer} />
  }

  return null
}


const InstallerModal = ({title}) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_installer'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-900px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <ModalHeader title={title}/>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-10 my-2'>
              <ModalWrapper />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

const AddInstallerButton = () => {
  const {setItemIdForUpdate} = useListView()

  const openAddInstallerModal = () => {
    setItemIdForUpdate(null)
  }

  return (
      <button type='button' className='btn btn-primary' onClick={openAddInstallerModal}>
        <KTIcon iconName='plus' className='fs-2' />
        Add Installer
      </button>
  )
}

export {AddInstallerButton, InstallerModal}