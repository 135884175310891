import {PageTitle} from '../../../../_metronic/layout/core'

const ReportsPage = () => {
  
  return (
    <>
      <PageTitle breadcrumbs={[]} description=''>
        Financial reports
      </PageTitle>
    </>
  )
}

export default ReportsPage
