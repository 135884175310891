import {ListViewProvider, useListView} from '../../../common/table/core/ListViewProvider'
import {QueryRequestProvider} from '../../../common/table/core/QueryRequestProvider'
import {QueryResponseProvider, useQueryResponse, useQueryResponseData} from './core/QueryResponseProvider'
import {CertificatesListHeader} from './components/CertificatesListHeader'
import {CertificatesTable} from './table/CertificatesTable'
import {KTCard} from '../../../../_metronic/helpers'
import {CertificateModal} from  './components/CertificateModal'

const CertificatesList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <CertificatesListHeader />
        <CertificatesTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <CertificateModal title="certificate"/>}
    </>
  );
}

const CertificatesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider
        useQueryResponse={useQueryResponse}
        useQueryResponseData={useQueryResponseData}
      >
        <CertificatesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {CertificatesListWrapper}
