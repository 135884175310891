import * as Yup from 'yup'
import {useEffect} from 'react'
import {FC, useState} from 'react'
import {useQuery} from 'react-query'
import { toast } from 'react-toastify';
import {KTIcon, QUERIES, isNotEmpty} from '../../../../../_metronic/helpers'
import {useListView} from '../../../../common/table/core/ListViewProvider'
import {TableListLoader} from '../../../../common/table/components/TableListLoader'
import {useFormik} from 'formik'
import {getCertificationById, createCertification, updateCertification} from '../core/_requests'
import {Certification} from '../core/_models'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {Installers} from '../../../../common/components/Installers'
import {Certificates} from '../../../../common/components/Certificates'
import DatePicker from '../../../../common/components/DatePicker'
import SelectField from '../../../../common/components/SelectField'
import {useAuth} from '../../../../modules/auth/core/Auth'

type Props = {
  isCertificationLoading: boolean
  certification: Certification
}

const CertificationSchema = Yup.object().shape({
  installer_id: Yup.number()
    .required('Installer is required'),
  certificate_id: Yup.number()
    .required('Certificate is required'),
  issue_date: Yup.date()
    .required('Issued date is required'),
  expiry_date: Yup.date()
    .required('Expiry date is required'),
  status: Yup.string()
})

const ModalForm: FC<Props> = ({certification, isCertificationLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const {currentUser} = useAuth()

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  const initialValues = {
    id: certification.id,
    installer_id: certification.installer_id || '',
    certificate_id: certification.certificate_id || '',
    issue_date: certification.issue_date || '',
    expiry_date: certification.expiry_date || '',
    status: certification.status || '',
  }

  const [certificationForEdit] = useState(initialValues)

  const formik = useFormik({
    initialValues: certificationForEdit,
    validationSchema: CertificationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      const payload = {
        id: values.id,
        installer_id: values.installer_id,
        certificate_id: values.certificate_id,
        issue_date: values.issue_date,
        expiry_date: values.expiry_date,
        issued_by: currentUser?.username,
        status: values.status
      }
      try {
        if (isNotEmpty(values.id)) {
          await updateCertification(payload)
          toast.success('Record updated successfully');
        } else {
          delete payload['id'];
          await createCertification(payload)
          toast.success('Record created successfully');
        }
      } catch (ex) {
        console.error(ex)
        toast.warning('An error occured');
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_certification_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_certification_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_certification_header'
          data-kt-scroll-wrappers='#kt_modal_add_certification_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className="row" style={{marginBottom: '30px'}}>
            <div className="col-md-6">
              <Certificates 
                label="Select certificate"
                name="certificate_id"
                formik={formik}
                defaultValue={certification.certificate_id
                    ? {
                        label: certification.certificate.title,
                        value: initialValues.certificate_id
                      }
                    : {}
                  }
                />
            </div>

            <div className="col-md-6">
              <Installers 
                label="Select installer"
                name="installer_id"
                formik={formik}
                defaultValue={certification.installer
                    ? {
                        label: certification.installer.firstname + " " + certification.installer.lastname,
                        value: initialValues.installer_id
                      }
                    : {}
                  }
                />
            </div>
          </div>

          <div className="row">
            <DatePicker
              defaultValue={initialValues.issue_date}
              columns={6}
              label="Issued date"
              name="issue_date"
              formik={formik}
              isLoading={isCertificationLoading}
            />

            <DatePicker
              defaultValue={initialValues.expiry_date}
              columns={6}
              label="Expiry date"
              name="expiry_date"
              formik={formik}
              isLoading={isCertificationLoading} 
            />
          </div>

           <div className="row">
           
            <SelectField
              options={[
                {label: "Pending", value: "pending"},
                {label: "Approved", value: "approved"},
                {label: "Rejected", value: "rejected"},
                {label: "Expired", value: "expired"},
                {label: "Revoked", value: "revoked"},
                {label: "Suspended", value: "suspended"},
                {label: "Cancelled", value: "cancelled"},
                {label: "Archived", value: "archived"},
                {label: "Processing", value: "processing"},
              ]}
              defaultValue={initialValues.status}
              name="status"
              columns={6}
              label="Status"
              formik={formik}
            />
          </div>

        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-certifications-modal-action='cancel'
            disabled={formik.isSubmitting || isCertificationLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-certifications-modal-action='submit'
            disabled={isCertificationLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isCertificationLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isCertificationLoading) && <TableListLoader />}
    </>
  )
}

const ModalHeader = ({title}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>{itemIdForUpdate ? `Edit ${title}` : `Add ${title}`}</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-certifications-modal-action='close'
        onClick={() => setItemIdForUpdate(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

const ModalWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: certification,
    error,
  } = useQuery(
    `${QUERIES.CERTIFICATIONS_LIST}-certification-${itemIdForUpdate}`,
    () => {
      return getCertificationById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <ModalForm isCertificationLoading={isLoading} certification={{id: undefined}} />
  }

  if (!isLoading && !error && certification) {
    return <ModalForm isCertificationLoading={isLoading} certification={certification} />
  }

  return null
}


const CertificationModal = ({title}) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_certification'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-900px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <ModalHeader title={title}/>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-10 my-2'>
              <ModalWrapper />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

const AddCertificationButton = () => {
  const {setItemIdForUpdate} = useListView()

  const openAddCertificationModal = () => {
    setItemIdForUpdate(null)
  }

  return (
      <button type='button' className='btn btn-primary' onClick={openAddCertificationModal}>
        <KTIcon iconName='plus' className='fs-2' />
        Add Certification
      </button>
  )
}

export {AddCertificationButton, CertificationModal}