import {createContext, Dispatch, SetStateAction, useEffect, useState} from 'react'
import qs from 'qs'
import {ID, QueryResponseContextProps, QueryState} from './models'
import {REQUEST_TYPES} from './consts'

import moment from 'moment';

function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
  return createContext(initialState)
}

function isNotEmpty(obj: unknown) {
  return obj !== undefined && obj !== null && obj !== ''
}

// Example: page=1&page_size=10&sort=id&order=desc&search=a&filter_name=a&filter_online=false
function stringifyRequestQuery(state: QueryState): string {
  const pagination = qs.stringify(state, {filter: ['page', 'page_size'], skipNulls: true})
  const sort = qs.stringify(state, {filter: ['sort', 'order'], skipNulls: true})
  const search = isNotEmpty(state.search)
    ? qs.stringify(state, {filter: ['search'], skipNulls: true})
    : ''

  const filter = state.filter
    ? Object.entries(state.filter as Object)
        .filter((obj) => isNotEmpty(obj[1]))
        .map((obj) => {
          // return `filter_${obj[0]}=${obj[1]}`
          return `${obj[0]}=${obj[1]}`
        })
        .join('&')
    : ''

  return [pagination, sort, search, filter]
    .filter((f) => f)
    .join('&')
    .toLowerCase()//this could be removed
}

function parseRequestQuery(query: string): QueryState {
  const cache: unknown = qs.parse(query)
  return cache as QueryState
}

function calculatedGroupingIsDisabled<T>(isLoading: boolean, data: Array<T> | undefined): boolean {
  if (isLoading) {
    return true
  }

  return !data || !data.length
}

function calculateIsAllDataSelected<T>(data: Array<T> | undefined, selected: Array<ID>): boolean {
  if (!data) {
    return false
  }

  return data.length > 0 && data.length === selected.length
}

function groupingOnSelect(
  id: ID,
  selected: Array<ID>,
  setSelected: Dispatch<SetStateAction<Array<ID>>>
) {
  if (!id) {
    return
  }

  if (selected.includes(id)) {
    setSelected(selected.filter((itemId) => itemId !== id))
  } else {
    const updatedSelected = [...selected]
    updatedSelected.push(id)
    setSelected(updatedSelected)
  }
}

function groupingOnSelectAll<T>(
  isAllSelected: boolean,
  setSelected: Dispatch<SetStateAction<Array<ID>>>,
  data?: Array<T & {id?: ID}>
) {
  if (isAllSelected) {
    setSelected([])
    return
  }

  if (!data || !data.length) {
    return
  }

  setSelected(data.filter((item) => item.id).map((item) => item.id))
}

// Hook
function useDebounce(value: string | undefined, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

// Process query string
function processQueryString(queryString) {
  const urlSearchParams = new URLSearchParams(queryString);
  let query = '';
  let filters = '';

  // Extract page and page_size
  const page = urlSearchParams.get('page');
  const page_size = urlSearchParams.get('page_size');
  const pagination = `page=${page}&page_size=${page_size}`
  
  query += pagination
  // Remove page and page_size from the parameters
  urlSearchParams.delete('page');
  urlSearchParams.delete('page_size');

  // Construct a new query string without page and page_size
  if (urlSearchParams.toString() !== '') {
    filters = `&filters=${urlSearchParams.toString()}`;
  }
  query += filters

  return query;
}

const getInitials = (firstname: string, lastname: string) => {
  return `${firstname.charAt(0)}${lastname.charAt(0)}`;
};

const getRandomColor = () => {
  const colors = ['warning', 'success', 'danger', 'primary', 'secondary', 'info'];
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};

function capitalize(str) {
  // console.log(str)

  if(isNaN(str)){
   return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }
  return str;
}

function generateInvoiceNo(length) {
  let randomNumberString = 'BT-';

  for (let i = 0; i < length; i++) {
    const randomNumber = Math.floor(Math.random() * 10);
    randomNumberString += randomNumber.toString();
  }

  return randomNumberString;
}

function formatDate(str){
  return str ? moment(str).format('MM/DD/YYYY') : '-';
}

// Format the price above to NGN using the locale, style, and CURRENCY.
let CURRENCY = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
    currencyDisplay: 'symbol',
});

function convertToDateString(datetimeString) {
  const date = new Date(datetimeString);

  // Get the date components
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // add 1
  const day = date.getDate();

  // Construct the date string in "YYYY-MM-DD" format
  const dateString = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;

  return dateString;
}

function renamePdfToJpg(url) {
  // Parse the URL to extract the filename
  const parts = url.split('/');
  const filename = parts[parts.length - 1];
  
  // Extract the file extension
  const extension = filename.split('.').pop();
  
  // Check if it's a PDF file
  if (extension.toLowerCase() === 'pdf') {
    // Replace the extension with ".jpg"
    const newFilename = filename.replace('.pdf', '.jpg');
    
    // Construct the new URL with the renamed file
    const newUrl = url.replace(filename, newFilename);
    
    return newUrl;
  } else {
    // Not a PDF file, return the original URL
    return url;
  }
}

function formatCompletionTime(completionTimeInSeconds: number): string {
  const hours = Math.floor(completionTimeInSeconds / 3600);
  const minutes = Math.floor((completionTimeInSeconds % 3600) / 60);
  const seconds = completionTimeInSeconds % 60;

  const formattedTime: string[] = [];
  if (hours > 0) {
    formattedTime.push(`${hours} hour${hours !== 1 ? 's' : ''}`);
  }
  if (minutes > 0) {
    formattedTime.push(`${minutes} minute${minutes !== 1 ? 's' : ''}`);
  }
  if (seconds > 0) {
    formattedTime.push(`${seconds} second${seconds !== 1 ? 's' : ''}`);
  }

  return formattedTime.join(', ');
}

function getPaymentRequestType(type): string {
  return REQUEST_TYPES[type];
}

export {
  processQueryString,
  createResponseContext,
  stringifyRequestQuery,
  parseRequestQuery,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
  useDebounce,
  isNotEmpty,
  getInitials,
  getRandomColor,
  capitalize,
  generateInvoiceNo,
  formatDate,
  CURRENCY,
  convertToDateString,
  renamePdfToJpg,
  formatCompletionTime,
  getPaymentRequestType
}
