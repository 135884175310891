import {ListViewProvider, useListView} from '../../../common/table/core/ListViewProvider'
import {QueryRequestProvider} from '../../../common/table/core/QueryRequestProvider'
import {QueryResponseProvider, useQueryResponse, useQueryResponseData} from './core/QueryResponseProvider'
import {CustomersListHeader} from './components/CustomersListHeader'
import {CustomersTable} from './table/CustomersTable'
import {KTCard} from '../../../../_metronic/helpers'
import {CustomerModal} from  './components/CustomerModal'

const CustomersList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <CustomersListHeader />
        <CustomersTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <CustomerModal title="customer"/>}
    </>
  );
}

const CustomersListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider
        useQueryResponse={useQueryResponse}
        useQueryResponseData={useQueryResponseData}
      >
        <CustomersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {CustomersListWrapper}
