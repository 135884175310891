import React from 'react';
import { Dialog } from 'primereact/dialog';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';
// import {formatDate} from '../../../../../_metronic/helpers'
import {Chat} from './Chat'
import {useAuth} from '../../../../modules/auth/core/Auth'

const ResponsesModal = ({ open, onClose, row }) => {
  const {currentUser} = useAuth()

  return (
    <Dialog
      visible={open}
      onHide={onClose}
      header={`Ticket details: ${row.ticket_id}`}
      style={{ width: '50vw' }}
      breakpoints={{ '960px': '50vw', '641px': '95vw' }}
      footer={
        <div>
          <button className="btn btn-danger btn-sm" onClick={onClose}>
            Close
          </button>
        </div>
      }
    >
    <div className='d-flex flex-column'>
      <div className="row">
      {/*text-dark fw-bold mw-lg-400px*/}
        <div className="col-md-4 mw-lg-400px ">
          <h4>User Information</h4>
          <p><strong>Name:</strong> {`${row.user.firstname} ${row.user.lastname}`}</p>
          <p><strong>Username:</strong> {row.user.username}</p>
          <p><strong>Email:</strong> {row.user.email}</p>
        </div>

        <div className="col-md-4 mw-lg-400px">
          <h4>Ticket Information</h4>
          <p><strong>Status:</strong> {row.status}</p>
          <p><strong>Priority:</strong> {row.priority}</p>
          <p><strong>Subject:</strong> {row.subject}</p>
        </div>

        <div className="col-md-4 mw-lg-400px">
          <h4>Job Information</h4>
          <p><strong>Status:</strong> {row.job.status}</p>
          <p><strong>Service Type:</strong> {row.job.service_type}</p>
          <p><strong>State:</strong> {row.job.state}</p>
        </div>
      </div>

      <div className="mt-3 mw-lg-400px">
        <h4>Description</h4>
        <p>{row.description}</p>
      </div>
      <hr className="text-gray-600" />
      <Chat row={row} currentUser={currentUser}/>
    </div>

    </Dialog>
  );
};

export default ResponsesModal;
