// @ts-nocheck
import { FC } from 'react';
import { ColumnInstance } from 'react-table';

type Props<T> = {
  column: ColumnInstance<T>;
};

const TableHeaderColumn: FC<Props<any>> = ({ column }) => (
  <>
    {column.Header && typeof column.Header === 'string' ? (
      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
    ) : (
      column.render('Header')
    )}
  </>
);

export { TableHeaderColumn };
