import axios, {AxiosResponse} from 'axios'
import {ID, Response, processQueryString} from '../../../../../_metronic/helpers'
import {Job, JobsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const JOB_URL = `${API_URL}/jobs`
const GET_JOBS_URL = `${API_URL}/jobs`

const getJobs = (query: string): Promise<JobsQueryResponse> => {
  const filters = processQueryString(query);

  return axios
    .get(`${GET_JOBS_URL}?${filters}`)
    .then((d: AxiosResponse<JobsQueryResponse>) => {
      return d.data
    })
}

const getAllJobs = () => {

  return axios
    .get(`${GET_JOBS_URL}`)
    .then((d) => d.data)
}

const getJobById = (id: ID): Promise<Job | undefined> => {
  return axios
    .get(`${JOB_URL}/${id}`)
    .then((response: AxiosResponse<Response<Job>>) => response.data)
    .then((response: Response<Job>) => response.data)
}

const createJob = (job): Promise<Job | undefined> => {
  return axios
    .post(JOB_URL, job)
    .then((response: AxiosResponse<Response<Job>>) => response.data)
    .then((response: Response<Job>) => response.data)
}

const updateJob = (job): Promise<Job | undefined> => {
  return axios
    .put(`${JOB_URL}/${job.id}`, job)
    .then((response: AxiosResponse<Response<Job>>) => response.data)
    .then((response: Response<Job>) => response.data)
}

const deleteJob = (jobId: ID): Promise<void> => {
  return axios.delete(`${JOB_URL}/${jobId}`).then(() => {})
}

// const deleteSelectedJobs = () => {}
const deleteSelectedJobs = (jobIds: Array<ID>): Promise<void> => {
  const requests = jobIds.map((id) => axios.delete(`${JOB_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getJobs, getAllJobs, getJobById, createJob, updateJob, deleteJob, deleteSelectedJobs}