import {KTIcon} from '../../../../_metronic/helpers'

const Export = () => {
  return (
    <button type='button' className='btn btn-light-primary me-3'>
      <KTIcon iconName='exit-up' className='fs-2' />
      Export
    </button>
  )
}

export {Export}