import React from "react";
import {JobsListWrapper} from './JobsList'
import {PageTitle} from '../../../../_metronic/layout/core'

const activeJobsBreadcrumbs = [
  {
    title: '',
    path: '/jobs/active',
    isSeparator: false,
    isActive: false,
  },
]

export default function JobsPage() {
    return (
        <>
            <PageTitle breadcrumbs={activeJobsBreadcrumbs} description='Manage active jobs'>
                Active Jobs
            </PageTitle>
            <JobsListWrapper />
        </>
    )
}