import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const JOBS_URL = `${API_URL}/jobs`


const getJobsStats = () => {

  return axios
    .get(`${JOBS_URL}/stats/`)
    .then((d) => d.data)
}

export {getJobsStats}