// @ts-nocheck
import {FC} from 'react'
import clsx from 'clsx'
import {Column} from 'react-table'
import {ActionsCell} from './ActionsCell'
import {TableCustomHeader} from '../../../../common/table/columns/TableCustomHeader'
import {TableSelectionHeader} from '../../../../common/table/columns/TableSelectionHeader'
import {TableSelectionCell} from '../../../../common/table/columns/TableSelectionCell'
import {Installer} from '../core/_models'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {deleteInstaller} from '../core/_requests'
import {QUERIES, getInitials, getRandomColor, capitalize} from '../../../../../_metronic/helpers'
import { Link } from 'react-router-dom';


type Props = {
  installer: Installer
}

const InstallerInfoCell: FC<Props> = ({ installer }) => {

  const initials = getInitials(installer.user.firstname, installer.user.lastname);
  const randomColor = getRandomColor();

  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <Link to={`/users/installers/${installer.user.id}`}>
          {installer.user.avatar_url ? (
            <div className='symbol-label'>
              <img src={`${installer.user.avatar_url}`} alt={`${installer.user.firstname} ${installer.user.lastname}`} className='w-100' />
            </div>
          ) : (
            <div
              className={clsx(
                'symbol-label fs-3',
                `bg-light${randomColor}`,
                `text-${randomColor}`
              )}
            >
              {initials}
            </div>
          )}
        </Link>
      </div>
      <div className='d-flex flex-column'>
        <Link to={`/users/installers/${installer.user.id}`} className='text-gray-800 text-hover-primary mb-1'>
          {installer.user.firstname} {installer.user.lastname}
        </Link>
        <span>{installer.user.email}</span>
      </div>
    </div>
  );
};

const ColumnCell: FC<Props> = ({ installer, fieldName }) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>{capitalize(installer.user[fieldName])}</div>
    </div>
  )
}

const StatusCell: FC<Props> = ({ installer }) => {
  return (
      <>
        {installer.details.availabilityStatus ? (
          <div className='d-flex align-items-center'>
            <span className="badge badge-primary">Available</span>
          </div>
        ) : (
          <div className='d-flex align-items-center'>
            <span className="badge badge-secondary">Unavailable</span>
          </div>
        )}
      </>
  );
}

const installersColumns: ReadonlyArray<Column<Installer>> = [
  {
    Header: (props) => <TableSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <TableSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'firstname',
    Cell: ({ ...props }) => <InstallerInfoCell installer={props.data[props.row.index]} fieldName='name' />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Username' className='min-w-125px' />,
    id: 'username',
    Cell: ({ ...props }) => <ColumnCell installer={props.data[props.row.index]} fieldName='username' />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='State' className='min-w-125px' />,
    id: 'state',
    Cell: ({ ...props }) => <ColumnCell installer={props.data[props.row.index]} fieldName='state' />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    id: 'status',
    Cell: ({ ...props }) => <StatusCell installer={props.data[props.row.index]} fieldName='status' />,
  },
  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ActionsCell id={props.data[props.row.index].id} queryList={QUERIES.INSTALLERS_LIST} useQueryResponse={useQueryResponse} deleteEntity={deleteInstaller} />,
  },
]

export {installersColumns}
