/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import { toast } from 'react-toastify';
import clsx from 'clsx'
import {getMessages, createMessage} from '../core/_requests'
import moment from 'moment';
import Spinner from '../../../../common/components/Spinner'

// export interface MessageModel {
//   user: number
//   type: 'in' | 'out'
//   text: string
//   time: string
//   template?: boolean
//   avatar?: string
//   name: string
// }
const Chat = ({row, currentUser}) => {

  const [message, setMessage] = useState<string>('')
  const [messages, setMessages] = useState<any[]>([])
  const [isLoadingMessages, setIsLoadingMessages] = useState(true)
  const [isSendingMessage, setIsSendingMessage] = useState(false)
  const isClosed = (row.status === 'open' || row.status === 'active') ?? false

  const isDrawer = false;

  const ticket_id = row.id

  const sendMessage = async () => {
    setIsSendingMessage(true)
    const payload = {
      user_id: currentUser.id,
      ticket_id: row.id,
      message: message
    }

    try{
      const response = await createMessage(payload);
      const newMessage = {
        user: currentUser.id,
        type: 'out',
        text: message,
        time: moment(response.data.time).format("MMM D YYYY [at] h:mm A") || '',
        avatar: response.data.user.avatar,
        name: currentUser.firstname +" "+currentUser.lastname
      }
      setMessages(prevMessages => [...prevMessages, newMessage]);
      setMessage('')
      toast.success('Message sent');
    }catch(error){
      console.log(error)
      toast.error('Error sending message');
    } finally {
      setIsSendingMessage(false)
    }
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const data = await getMessages(ticket_id);
        if (data) {
          const msgs = data.map(d => ({
            user: d.user_id,
            type: d.user_id !== currentUser.id ? 'in' : 'out',
            text: d.text,
            time: moment(d.time).format("MMM D YYYY [at] h:mm A"),
            avatar: d.user.avatar,
            name: d.user.firstname+" "+d.user.lastname,
          }))
          setMessages(msgs)
          setIsLoadingMessages(false);
        }
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();
  }, [currentUser, ticket_id, message, messages]);

  return (
    <>
      {isLoadingMessages ? (
        <h1 className="kt_header">Loading...</h1>
      ): (
        <div
          className='card-body'
          id='kt_chat_messenger_body'
        >
          <div
            className='scroll-y me-n5 pe-5'
            data-kt-element='messages'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
            data-kt-scroll-wrappers='#kt_content, #kt_app_content, #kt_chat_messenger_body'
            data-kt-scroll-offset='-2px'
          >
            {messages.map((message, index) => {
              const state = message.type === 'in' ? 'info' : 'primary'
              const templateAttr = {}
              if (message.template) {
                Object.defineProperty(templateAttr, 'data-kt-element', {
                  value: `template-${message.type}`,
                })
              }
              const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
                message.type === 'in' ? 'start' : 'end'
              } mb-10`
              return (
                <div
                  key={`message${index}`}
                  className={clsx('d-flex', contentClass, 'mb-10', {'d-none': message.template})}
                  {...templateAttr}
                >
                  <div
                    className={clsx(
                      'd-flex flex-column align-items',
                      `align-items-${message.type === 'in' ? 'start' : 'end'}`
                    )}
                  >
                    <div className='d-flex align-items-center mb-2'>
                      {message.type === 'in' ? (
                        <>
                          <div className='symbol  symbol-35px symbol-circle '>
                            <img alt='Pic' src={message.avatar} />
                          </div>
                          <div className='ms-3'>
                            <a
                              href='#'
                              className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                            >
                              {message.name}
                            </a>
                            <span className='text-muted fs-7 mb-1'>{message.time}</span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className='me-3'>
                            <span className='text-muted fs-7 mb-1'>{message.time}</span>
                            <a
                              href='#'
                              className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                            >
                              You
                            </a>
                          </div>
                          <div className='symbol  symbol-35px symbol-circle '>
                            <img alt='Pic' src={message.avatar} />
                          </div>
                        </>
                      )}
                    </div>

                    <div
                      className={clsx(
                        'p-5 rounded',
                        `bg-light-${state}`,
                        'text-dark fw-bold mw-lg-400px',
                        `text-${message.type === 'in' ? 'start' : 'end'}`
                      )}
                      data-kt-element='message-text'
                      dangerouslySetInnerHTML={{__html: message.text}}
                    ></div>
                  </div>
                </div>
              )
            })}
          </div>

          {isSendingMessage ? (
            <div className="text-center">
                <Spinner />
            </div>
          ) : isClosed && (
            <div
              className='card-footer pt-4'
              id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
            >
              <textarea
                className='form-control mb-3'
                rows={2}
                data-kt-element='input'
                placeholder='Type a message'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={onEnterPress}
              ></textarea>

              <div className='d-flex flex-stack'>
                <div className='d-flex align-items-center me-2'>
                  {/*<button
                    className='btn btn-sm btn-icon btn-active-light-primary me-1'
                    type='button'
                    data-bs-toggle='tooltip'
                    title='Coming soon'
                  >
                    <i className='bi bi-paperclip fs-3'></i>
                  </button>
                  <button
                    className='btn btn-sm btn-icon btn-active-light-primary me-1'
                    type='button'
                    data-bs-toggle='tooltip'
                    title='Coming soon'
                  >
                    <i className='bi bi-upload fs-3'></i>
                  </button>*/}
                </div>
                <button
                  className='btn btn-primary'
                  type='button'
                  data-kt-element='send'
                  onClick={sendMessage}
                >
                  Send
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export {Chat}
