import * as Yup from 'yup'
import {useEffect} from 'react'
import {FC, useState} from 'react'
import {useQuery} from 'react-query'
import { toast } from 'react-toastify';
import {KTIcon, QUERIES, isNotEmpty} from '../../../../_metronic/helpers'
import {useListView} from '../../../common/table/core/ListViewProvider'
import {TableListLoader} from '../../../common/table/components/TableListLoader'
import {useFormik} from 'formik'
import {getRoleById, createRole, updateRole} from '../core/_requests'
import {Role} from '../core/_models'
import {useQueryResponse} from '../core/QueryResponseProvider'
// EditModal, EditModalForm, EditModalFormWrapper, EditModalHeadear
import InputField from '../../../common/components/InputField'
import {Permissions} from '../../../common/components/Permissions'


type Props = {
  isRoleLoading: boolean
  role: Role
}

const RoleSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})

const ModalForm: FC<Props> = ({role, isRoleLoading}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

   const [roleForEdit] = useState<Role>({
    ...role,
    name: role.name || '',
    permissions: role.permissions || []
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  
  const formik = useFormik({
    initialValues: roleForEdit,
    validationSchema: RoleSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateRole(values)
          toast.success('Record updated successfully');
        } else {
          await createRole(values)
          toast.success('Record created successfully');
        }
      } catch (ex) {
        console.error(ex)
        toast.warning('An error occured');
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_role_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_role_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_role_header'
          data-kt-scroll-wrappers='#kt_modal_add_role_scroll'
          data-kt-scroll-offset='300px'
        >

          <div className="row">
            <InputField
              isLoading={isRoleLoading}
              formik={formik}
              name="name"
              label="Name"
              placeholder="Name"
              type='text'
              columns={12}
            />
          </div>

          { itemIdForUpdate && (
            <div className="row" style={{marginBottom: '20vh'}}>
              <div className="col-md-12">
                <Permissions 
                  label="Select skills"
                  defaultValue={roleForEdit.permissions?.map((per) => ({ label: per.toUpperCase().replace('_',' '), value: per }))}
                  name="skills"
                  formik={formik}
                />
              </div>
            </div>
          )}

        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-roles-modal-action='cancel'
            disabled={formik.isSubmitting || isRoleLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-roles-modal-action='submit'
            disabled={isRoleLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isRoleLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isRoleLoading) && <TableListLoader />}
    </>
  )
}

const ModalHeader = ({title}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>{itemIdForUpdate ? `Edit ${title}` : `Add ${title}`}</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setItemIdForUpdate(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

const ModalWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: role,
    error,
  } = useQuery(
    `${QUERIES.ROLES_LIST}-role-${itemIdForUpdate}`,
    () => {
      return getRoleById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <ModalForm isRoleLoading={isLoading} role={{id: undefined}} />
  }

  if (!isLoading && !error && role) {
    return <ModalForm isRoleLoading={isLoading} role={role} />
  }

  return null
}


const RoleModal = ({title}) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_role'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <ModalHeader title={title}/>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <ModalWrapper />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

const AddRoleButton = () => {
  const {setItemIdForUpdate} = useListView()

  const openAddRoleModal = () => {
    setItemIdForUpdate(null)
  }

  return (
      <button type='button' className='btn btn-primary' onClick={openAddRoleModal}>
        <KTIcon iconName='plus' className='fs-2' />
        Add Role
      </button>
  )
}

export {AddRoleButton, RoleModal}