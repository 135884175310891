const TableListToolbar = ({children}) => {
  
  return (
    <div className='d-flex justify-content-end' data-kt-table-toolbar='base'>
      {children}
    </div>
  )
}

export {TableListToolbar}
