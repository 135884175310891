import React from 'react';
import { Dialog } from 'primereact/dialog';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';
import RatingStars from '../../../../common/components/RatingStars'
import RatingBarChart from '../../../../common/components/RatingBarChart'

// Import necessary libraries and components

const MetricsModal = ({ open, onClose, row }) => {
  // const ratingAvg = row.jobs
  const jobData = [
    {
      title: 'Job A',
      rating_average: 4.2,
    },
    {
      title: 'Job B',
      rating_average: 3.8,
    },
    {
      title: 'Job C',
      rating_average: 4.5,
    },
  ];
  return (
    <Dialog
      visible={open}
      onHide={onClose}
      header={`Performance metrics for: ${row.user.firstname} ${row.user.lastname}`}
      style={{ width: '50vw' }}
      breakpoints={{ '960px': '50vw', '641px': '95vw' }}
      footer={
        <div className="d-flex justify-content-end">
          <button className="btn btn-danger btn-sm me-2" onClick={onClose}>
            Close
          </button>
        </div>
      }
    >
      <div className="p-4">
        <h5 className="mb-3">Rating Average</h5>
        <RatingStars totalStars={5} numStars={row.details?.rating_average} />

        <hr className="my-4" />
        <RatingBarChart jobData={jobData}/>
        
      </div>
    </Dialog>
  );
};


export default MetricsModal;
