import {UserProfileCard} from '../../common/components/UserProfile'

function UserProfilePage() {
  return (
    <>
      <UserProfileCard />
    </>
  );
}

export default UserProfilePage