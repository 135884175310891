import React from "react";
import {RolesListWrapper} from './RolesList'
import {PageTitle} from '../../../_metronic/layout/core'

const usersBreadcrumbs = [
  {
    title: '',
    path: '/roles',
    isSeparator: false,
    isActive: false,
  },
]

export default function RolesPage() {
    return (
        <>
            <PageTitle breadcrumbs={usersBreadcrumbs} description='Manage role permissions'>
                Roles & Permissions
            </PageTitle>
            <RolesListWrapper />
        </>
    )
}