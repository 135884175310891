import {useListView} from '../../../common/table/core/ListViewProvider'
import {TableListToolbar} from '../../../common/table/components/TableListToolbar'
import {TableListSearchComponent} from '../../../common/table/components/TableListSearchComponent'
import {TableListGrouping} from '../../../common/table/components/TableListGrouping'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {deleteSelectedRoles} from '../core/_requests'
import {RolesListFilter} from './RolesListFilter'
import {AddRoleButton} from './RoleModal'
import {Export} from './Export'
import {QUERIES} from '../../../../_metronic/helpers'

const RolesListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <TableListSearchComponent placeholder="Search for role" />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {
          selected.length > 0 ? <TableListGrouping queryList={QUERIES.ROLES_LIST} useQueryResponse={useQueryResponse} deleteSelectedEntity={deleteSelectedRoles} />
          : (
              <TableListToolbar>
                <RolesListFilter />
                <Export />
                <AddRoleButton />
              </TableListToolbar>
            )
        }
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {RolesListHeader}
