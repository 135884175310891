// @ts-nocheck
import {FC} from 'react'
import clsx from 'clsx'
import {Column} from 'react-table'
import {ActionsCell} from './ActionsCell'
import {TableCustomHeader} from '../../../../common/table/columns/TableCustomHeader'
import {TableSelectionHeader} from '../../../../common/table/columns/TableSelectionHeader'
import {TableSelectionCell} from '../../../../common/table/columns/TableSelectionCell'
import {Certification} from '../core/_models'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {deleteCertification} from '../core/_requests'
import {QUERIES, capitalize, getInitials, getRandomColor, formatDate} from '../../../../../_metronic/helpers'


type Props = {
  certification: Certification
}

const InstallerInfoCell: FC<Props> = ({ installer }) => {

  const initials = getInitials(installer.installer.firstname, installer.installer.lastname);
  const randomColor = getRandomColor();

  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <a href='/metrics'>
          {installer.installer.avatar_url ? (
            <div className='symbol-label'>
              <img src={`${installer.installer.avatar_url}`} alt={`${installer.installer.firstname} ${installer.installer.lastname}`} className='w-100' />
            </div>
          ) : (
            <div
              className={clsx(
                'symbol-label fs-3',
                `bg-light${randomColor}`,
                `text-${randomColor}`
              )}
            >
              {initials}
            </div>
          )}
        </a>
      </div>
      <div className='d-flex flex-column'>
        <a href='/metrics' className='text-gray-800 text-hover-primary mb-1'>
          {installer.installer.firstname} {installer.installer.lastname}
        </a>
        <span>{installer.installer.email}</span>
      </div>
    </div>
  );
};

const CertificateTitleCell: FC<Props> = ({ certification }) => {

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>{capitalize(certification.certificate.title)}</div>
    </div>
  )
}

const DateCell: FC<Props> = ({ certification, fieldName }) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>{formatDate(certification[fieldName])}</div>
    </div>
  )
}

const ColumnCell: FC<Props> = ({ certification, fieldName }) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>{capitalize(certification[fieldName])}</div>
    </div>
  )
}

const certificationsColumns: ReadonlyArray<Column<Certification>> = [
  {
    Header: (props) => <TableSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <TableSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({ ...props }) => <InstallerInfoCell installer={props.data[props.row.index]} fieldName='name' />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='title' className='min-w-125px' />,
    id: 'title',
    Cell: ({ ...props }) => <CertificateTitleCell certification={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Issued On' className='min-w-125px' />,
    id: 'issueDate',
    Cell: ({ ...props }) => <DateCell certification={props.data[props.row.index]} fieldName='issue_date' />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    id: 'status',
    Cell: ({ ...props }) => <ColumnCell certification={props.data[props.row.index]} fieldName='status' />,
  },
  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ActionsCell row={props.data[props.row.index]} id={props.data[props.row.index].id} queryList={QUERIES.CERTIFICATIONS_LIST} useQueryResponse={useQueryResponse} deleteEntity={deleteCertification} />,
  },
]

export {certificationsColumns}
