const popupContent = {
    textAlign: "center",
    height: "350px",
    marginTop: "30px",
  } as React.CSSProperties;
  const popupHead = {
    fontWeight: "bold",
    fontSize: "22px",
  } as React.CSSProperties;
  
  const popupText = {
    fontSize: "15px",
    marginBottom: "5px",
    display: "block"
  } as React.CSSProperties;
  
  
  export { popupContent, popupHead, popupText };
  