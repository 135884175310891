import React from "react";
import {InvoicesListWrapper} from './InvoicesList'
import {PageTitle} from '../../../../_metronic/layout/core'

const invoicesBreadcrumbs = [
  {
    title: '',
    path: '/users/invoices',
    isSeparator: false,
    isActive: false,
  },
]

export default function InvoicesPage() {
    return (
        <>
            <PageTitle breadcrumbs={invoicesBreadcrumbs} description='Manage invoices'>
                Invoices
            </PageTitle>
            <InvoicesListWrapper />
        </>
    )
}