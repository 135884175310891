// @ts-nocheck
import {FC} from 'react'
import {Column} from 'react-table'
import {ActionsCell} from './ActionsCell'
import {TableCustomHeader} from '../../../../common/table/columns/TableCustomHeader'
import {TableSelectionHeader} from '../../../../common/table/columns/TableSelectionHeader'
import {TableSelectionCell} from '../../../../common/table/columns/TableSelectionCell'
import {Ticket} from '../core/_models'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {deleteTicket} from '../core/_requests'
import {QUERIES, capitalize} from '../../../../../_metronic/helpers'


type Props = {
  ticket: Ticket
}


const ColumnCell: FC<Props> = ({ ticket, fieldName }) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>{capitalize(ticket[fieldName])}</div>
    </div>
  )
}

const NameCell: FC<Props> = ({ ticket }) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>{capitalize(ticket.user.firstname+" "+ticket.user.lastname)}</div>
    </div>
  )
}

const StatusCell: FC<Props> = ({ ticket }) => {
  let badgeClass = '';

  switch (ticket.status) {
    case 'open':
      badgeClass = 'badge badge-primary';
      break;
    case 'active':
      badgeClass = 'badge badge-success';
      break;
    case 'closed':
      badgeClass = 'badge badge-info';
      break;
    default:
      badgeClass = 'badge badge-secondary';
      break;
  }

  return (
    <div className='d-flex align-items-center'>
      <span className={badgeClass}>{ticket.status.charAt(0).toUpperCase() + ticket.status.slice(1)}</span>
    </div>
  );
};

const PriorityCell: FC<Props> = ({ ticket }) => {
  let badgeClass = '';

  switch (ticket.priority) {
    case 'low':
      badgeClass = 'badge badge-secondary';
      break;
    case 'medium':
      badgeClass = 'badge badge-success';
      break;
    case 'high':
      badgeClass = 'badge badge-danger';
      break;
    default:
      badgeClass = 'badge badge-secondary';
      break;
  }

  return (
    <div className='d-flex align-items-center'>
      <span className={badgeClass}>{ticket.priority.charAt(0).toUpperCase() + ticket.priority.slice(1)}</span>
    </div>
  );
};


const ticketsColumns: ReadonlyArray<Column<Ticket>> = [
  {
    Header: (props) => <TableSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <TableSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Ticket #' className='min-w-125px' />,
    id: 'ticket_id',
    Cell: ({ ...props }) => <ColumnCell ticket={props.data[props.row.index]} fieldName='ticket_id' />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Customer' className='min-w-125px' />,
    id: 'customer',
    Cell: ({ ...props }) => <NameCell ticket={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Category' className='min-w-125px' />,
    id: 'category',
    Cell: ({ ...props }) => <ColumnCell ticket={props.data[props.row.index]} fieldName='category' />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Priority' className='min-w-125px' />,
    id: 'priority',
    Cell: ({ ...props }) => <PriorityCell ticket={props.data[props.row.index]} fieldName='priority' />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    id: 'status',
    Cell: ({ ...props }) => <StatusCell ticket={props.data[props.row.index]} fieldName='status' />,
  },
  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ActionsCell row={props.data[props.row.index]} id={props.data[props.row.index].id} queryList={QUERIES.TICKETS_LIST} useQueryResponse={useQueryResponse} deleteEntity={deleteTicket} />,
  },
]

export {ticketsColumns}
