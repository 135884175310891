import {ListViewProvider} from '../../../common/table/core/ListViewProvider'
import {QueryRequestProvider} from '../../../common/table/core/QueryRequestProvider'
import {QueryResponseProvider, useQueryResponse, useQueryResponseData} from './core/QueryResponseProvider'
import {MetricsListHeader} from './components/MetricsListHeader'
import {MetricsTable} from './table/MetricsTable'
import {KTCard} from '../../../../_metronic/helpers'

const MetricsList = () => {
  return (
    <>
      <KTCard>
        <MetricsListHeader />
        <MetricsTable />
      </KTCard>
    </>
  );
}

const MetricsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider
        useQueryResponse={useQueryResponse}
        useQueryResponseData={useQueryResponseData}
      >
        <MetricsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {MetricsListWrapper}
