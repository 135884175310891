// @ts-nocheck
import {FC} from 'react'
import {Column} from 'react-table'
import {ActionsCell} from './ActionsCell'
import {TableCustomHeader} from '../../../common/table/columns/TableCustomHeader'
import {TableSelectionHeader} from '../../../common/table/columns/TableSelectionHeader'
import {TableSelectionCell} from '../../../common/table/columns/TableSelectionCell'
import {Role} from '../core/_models'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {deleteRole} from '../core/_requests'
import {QUERIES} from '../../../../_metronic/helpers'

type Props = {
  role: Role
}

// we should find a way to make these reusable also
const RoleName: FC<Props> = ({role}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
        {role.name}
    </div>
  </div>
)

const PermissionsCount: FC<Props> = ({role}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
        {role.permissions?.length}
    </div>
  </div>
)

export {RoleName, PermissionsCount}


const rolesColumns: ReadonlyArray<Column<Role>> = [
  {
    Header: (props) => <TableSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <TableSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => <RoleName role={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Permissions' className='min-w-250px' />,
    id: 'permissions',
    Cell: ({...props}) => <PermissionsCount role={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ActionsCell id={props.data[props.row.index].id} queryList={QUERIES.ROLES_LIST} useQueryResponse={useQueryResponse} deleteEntity={deleteRole} />,
  },
]

export {rolesColumns}
