import {ListViewProvider, useListView} from '../../../common/table/core/ListViewProvider'
import {QueryRequestProvider} from '../../../common/table/core/QueryRequestProvider'
import {QueryResponseProvider, useQueryResponse, useQueryResponseData} from './core/QueryResponseProvider'
import {CertificationsListHeader} from './components/CertificationsListHeader'
import {CertificationsTable} from './table/CertificationsTable'
import {KTCard} from '../../../../_metronic/helpers'
import {CertificationModal} from  './components/CertificationModal'

const CertificationsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <CertificationsListHeader />
        <CertificationsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <CertificationModal title="certification"/>}
    </>
  );
}

const CertificationsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider
        useQueryResponse={useQueryResponse}
        useQueryResponseData={useQueryResponseData}
      >
        <CertificationsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {CertificationsListWrapper}
