import {ListViewProvider, useListView} from '../../common/table/core/ListViewProvider'
import {QueryRequestProvider} from '../../common/table/core/QueryRequestProvider'
import {QueryResponseProvider, useQueryResponse, useQueryResponseData} from './core/QueryResponseProvider'
import {RolesListHeader} from './components/RolesListHeader'
import {RolesTable} from './table/RolesTable'
import {KTCard} from '../../../_metronic/helpers'
import {RoleModal} from  './components/RoleModal'

const RolesList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <RolesListHeader />
        <RolesTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <RoleModal title="role"/>}
    </>
  );
}

const RolesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider
        useQueryResponse={useQueryResponse}
        useQueryResponseData={useQueryResponseData}
      >
        <RolesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {RolesListWrapper}
