import React from 'react';

const SelectField = ({ options, defaultValue, columns, label, name, formik }) => {
  return (
    <div className={`col-md-${columns}`}>
      <div className="mb-10">
        <label className="form-label">{label}</label>
        <select
          className="form-select"
          aria-label={`Select ${name}`}
          {...formik.getFieldProps(name)}
        >
          <option value="" disabled>Select {label}</option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SelectField;