import {ListViewProvider, useListView} from '../../../common/table/core/ListViewProvider'
import {QueryRequestProvider} from '../../../common/table/core/QueryRequestProvider'
import {QueryResponseProvider, useQueryResponse, useQueryResponseData} from './core/QueryResponseProvider'
import {FeedbacksListHeader} from './components/FeedbacksListHeader'
import {FeedbacksTable} from './table/FeedbacksTable'
import {KTCard} from '../../../../_metronic/helpers'
import {FeedbackModal} from  './components/FeedbackModal'

const FeedbacksList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <FeedbacksListHeader />
        <FeedbacksTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <FeedbackModal title="feedback"/>}
    </>
  );
}

const FeedbacksListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider
        useQueryResponse={useQueryResponse}
        useQueryResponseData={useQueryResponseData}
      >
        <FeedbacksList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {FeedbacksListWrapper}
