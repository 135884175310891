import { Dialog } from 'primereact/dialog';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';

import * as Yup from 'yup'
import DatePicker from '../../../../common/components/DatePicker'
import InputField from '../../../../common/components/InputField'
import {useFormik} from 'formik'
import { toast } from 'react-toastify';
import { getPaymentById, createPayment, updatePayment } from '../core/_requests'
import { isNotEmpty, convertToDateString } from '../../../../../_metronic/helpers'
import { useState, useEffect } from 'react'
import PleaseWait from '../../../../common/components/PleaseWait'

const PaymentSchema = Yup.object().shape({
  amount: Yup.number()
    .required('Amount is required')
    .min(1000),
  payment_date: Yup.date()
    .required('Issue date is required'),
})

const PaymentModal = ({ open, onClose, id, invoiceId, amount }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [payment, setPayment] = useState({
    id: id, 
    amount: parseFloat(amount) ?? 0,
    invoice_id:  invoiceId,
    payment_date: convertToDateString(new Date())
  })

  const fetchPaymentData = async () => {
    setIsLoading(true);
    try {
      const data = await getPaymentById(id);
      setPayment(prev => ({
        ...prev,
        amount: parseFloat(data.amount),
        payment_date: data.paymentDate,
      }));
      return data || {};
    } catch (error) {
      console.error('Error fetching payment:', error);
      return {};
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: payment,
    validationSchema: PaymentSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const payload = {
        id: id,
        amount: values.amount,
        payment_date: values.payment_date,
        invoice_id: invoiceId
      }
      try {
        if (isNotEmpty(id)) {
          await updatePayment(payload)
          toast.success('Record updated successfully');
          setTimeout(() => {
            window.location.reload()
          },1000)
        } else {
          delete payload['id'];
          await createPayment(payload)
          toast.success('Record created successfully');
          setTimeout(() => {
            window.location.reload()
          },1000)
        }
        onClose();
      } catch (ex) {
        console.error(ex)
        toast.warning('An error occurred');
      } finally {
        setSubmitting(false);
      }
    },
  });
  useEffect(() => {
    if (id) {
      formik.setFieldValue('amount', amount)
      fetchPaymentData().then(data => {
        formik.setFieldValue('payment_date', data.paymentDate)
      });

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Dialog
      visible={open}
      onHide={onClose}
      header={`Payment Details: ${id ? 'Edit' : 'Create'}`}
      style={{ width: '30vw' }}
      breakpoints={{ '960px': '50vw', '641px': '95vw' }}
      footer={
        <div>
          <button className="btn btn-danger btn-sm" onClick={() => {
            formik.resetForm();
            onClose();
          }}>
            Close
          </button>
        </div>
      }
    >
      {isLoading ? (<PleaseWait />)
        :
        (
          <form className='form' onSubmit={formik.handleSubmit} noValidate>
            <div className='d-flex flex-column'>
              <div className="row">
                <InputField
                  isLoading={false}
                  formik={formik}
                  name="amount"
                  label="Amount"
                  placeholder="Amount"
                  type='number'
                  columns={12}
                />
              </div>
              <div className="row">
                <DatePicker
                  defaultValue={payment.payment_date}
                  columns={12}
                  label="Payment date"
                  name="payment_date"
                  formik={formik}
                  isLoading={false}
                />
              </div>
            </div>

            {/* begin::Actions */}
            <div className='text-center pt-15'>
              <button
                type='reset'
                onClick={() => {
                  formik.resetForm()
                  onClose()
                }}
                className='btn btn-light me-3'
                disabled={formik.isSubmitting}
              >
                Discard
              </button>

              <button
                type='submit'
                className='btn btn-primary'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                <span className='indicator-label'>Submit</span>
                {formik.isSubmitting && (
                  <span className='indicator-progress'>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Actions */}
          </form>
        )}
    </Dialog>
  );
}

export default PaymentModal;
