import {KTIcon} from '../../../_metronic/helpers'
import {useAuth} from '../../modules/auth/core/Auth'

function UserProfileCard() {
  const {currentUser} = useAuth()
  const userType = currentUser?.user_type
  console.log(currentUser?.state)
  
  let userTypeText = '';
  if(typeof userType === 'number' && userType === 1){
    userTypeText = 'Staff';
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <img src={currentUser?.avatar_url} alt='Metornic' />
              <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <span className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    {`${currentUser?.firstname} ${currentUser?.lastname}`}
                  </span>
                  <span>
                    <KTIcon iconName='verify' className='fs-1 text-primary' />
                  </span>
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <span
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                    {userTypeText}
                  </span>
                  <span
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTIcon iconName='geolocation' className='fs-4 me-1' />
                    {currentUser?.state}
                  </span>
                  <span
                    className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                  >
                    <KTIcon iconName='sms' className='fs-4 me-1' />
                    {currentUser?.email}
                  </span>
                </div>
              </div>

              <div className='d-flex my-4'>
                <span className='btn btn-sm btn-light me-2' id='kt_user_follow_button'>
                  <KTIcon iconName='check' className='fs-3 d-none' />

                  <span className='indicator-label'>Change password</span>
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </span>
                <span
                  className='btn btn-sm btn-primary me-3'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_offer_a_deal'
                >
                  Update profile
                </span>
                
              </div>
            </div>

            
          </div>
        </div>

        {/*<div className='d-flex overflow-auto h-55px'>
          
        </div>*/}
      </div>
    </div>
  );
}

export { UserProfileCard }