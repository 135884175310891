import React from 'react';
import clsx from 'clsx';

const TextArea = ({ isLoading, formik, name, label, placeholder, columns = 6 }) => {
  return (
    <div className={`col-md-${columns}`}>
      <div className="mb-10">
        <label className="form-label">{label}</label>
        <textarea
          {...formik.getFieldProps(name)}
          className={clsx(
            'form-control',
            {'is-invalid': formik.touched[name] && formik.errors[name]},
            {'is-valid': formik.touched[name] && !formik.errors[name]}
          )}
          placeholder={placeholder}
          name={name}
          autoComplete='off'
          disabled={formik.isSubmitting || isLoading}
          rows="5"
        >
        </textarea>
        {formik.errors[name] && typeof formik.errors[name] === 'string' && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors[name]}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TextArea;
