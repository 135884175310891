import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {useQueryResponseData, useQueryResponseLoading, useQueryResponsePagination} from '../core/QueryResponseProvider'
import {useQueryRequest} from '../../../../common/table/core/QueryRequestProvider'
import {feedbacksColumns} from './Columns'
import {Feedback} from '../core/_models'
import {TableListLoader} from '../../../../common/table/components/TableListLoader'
import {TableListPagination} from '../../../../common/table/components/TableListPagination'
import {TableRow} from '../../../../common/table/components/TableRow'
import {TableHeaderColumn} from '../../../../common/table/columns/TableHeaderColumn'
import {KTCardBody} from '../../../../../_metronic/helpers'


const FeedbacksTable = () => {
  const feedbacks = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => feedbacks, [feedbacks])
  const columns = useMemo(() => feedbacksColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_feedbacks'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Feedback>) => (
                <TableHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Feedback>, i) => {
                prepareRow(row)
                return <TableRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/*Table Pagination*/}
      <TableListPagination
        useQueryRequest={useQueryRequest}
        useQueryResponseLoading={useQueryResponseLoading} 
        useQueryResponsePagination={useQueryResponsePagination}
      />

      {isLoading && <TableListLoader />}
    </KTCardBody>
  )
}

export {FeedbacksTable}
