import axios, {AxiosResponse} from 'axios'
import {ID, Response, processQueryString} from '../../../../../_metronic/helpers'
import {Certification, CertificationsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const CERTIFICATION_URL = `${API_URL}/certifications`
const GET_CERTIFICATIONS_URL = `${API_URL}/certifications`

const getCertifications = (query: string): Promise<CertificationsQueryResponse> => {
  const filters = processQueryString(query);

  return axios
    .get(`${GET_CERTIFICATIONS_URL}?${filters}`)
    .then((d: AxiosResponse<CertificationsQueryResponse>) => {
      return d.data
    })
}

const getAllCertifications = () => {

  return axios
    .get(`${GET_CERTIFICATIONS_URL}`)
    .then((d) => d.data)
}

const getCertificationById = (id: ID): Promise<Certification | undefined> => {
  return axios
    .get(`${CERTIFICATION_URL}/${id}`)
    .then((response: AxiosResponse<Response<Certification>>) => response.data)
    .then((response: Response<Certification>) => response.data)
}

const createCertification = (certification): Promise<Certification | undefined> => {
  return axios
    .post(CERTIFICATION_URL, certification)
    .then((response: AxiosResponse<Response<Certification>>) => response.data)
    .then((response: Response<Certification>) => response.data)
}

const updateCertification = (certification): Promise<Certification | undefined> => {
  return axios
    .put(`${CERTIFICATION_URL}/${certification.id}`, certification)
    .then((response: AxiosResponse<Response<Certification>>) => response.data)
    .then((response: Response<Certification>) => response.data)
}

const deleteCertification = (certificationId: ID): Promise<void> => {
  return axios.delete(`${CERTIFICATION_URL}/${certificationId}`).then(() => {})
}

// const deleteSelectedCertifications = () => {}
const deleteSelectedCertifications = (certificationIds: Array<ID>): Promise<void> => {
  const requests = certificationIds.map((id) => axios.delete(`${CERTIFICATION_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getCertifications, getAllCertifications, getCertificationById, createCertification, updateCertification, deleteCertification, deleteSelectedCertifications}