import * as Yup from 'yup'
import {useEffect} from 'react'
import {FC, useState} from 'react'
import {useQuery} from 'react-query'
import { toast } from 'react-toastify';
import {KTIcon, QUERIES, isNotEmpty} from '../../../../../_metronic/helpers'
import {useListView} from '../../../../common/table/core/ListViewProvider'
import {TableListLoader} from '../../../../common/table/components/TableListLoader'
import {useFormik} from 'formik'
import {getCustomerById, createCustomer, updateCustomer} from '../core/_requests'
import {Customer} from '../core/_models'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {States} from '../../../../common/components/States'
import InputField from '../../../../common/components/InputField'
import AvatarUpload from '../../../../common/components/AvatarUpload'

type Props = {
  isCustomerLoading: boolean
  customer: Customer
}

const CustomerSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  lastname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  username: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('User name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('First name is required'),
  phone: Yup.string(),
  state: Yup.string()
    .required('State is required'),
  latitude: Yup.number(),
  longitude: Yup.number(),
  address: Yup.string(),
  password: Yup.string(),
  is_active: Yup.boolean()
})

const ModalForm: FC<Props> = ({customer, isCustomerLoading}) => {
  console.log(customer)
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [avatarUrl, setAvatarUrl] = useState(customer.user?.avatar_url)
 
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  const initialValues = {
    id: customer.id,
    firstname: customer.user?.firstname || '',
    lastname: customer.user?.lastname || '',
    username: customer.user?.username || '',
    email: customer.user?.email || '',
    phone: customer.user?.phone || '',
    avatar_url: avatarUrl || '',
    user_type: customer.user?.user_type || 2,
    is_active: customer.user?.is_active || false,
    state: customer.user?.state || '',
    address: customer.details?.contact_info?.address || '',
    latitude: customer.details?.contact_info?.latitude || '',
    longitude: customer.details?.contact_info?.longitude || '',
    password: customer.user?.password || '',
  }
  const [isActive, setIsActive] = useState(initialValues.is_active);

  const [customerForEdit] = useState(initialValues)

  const formik = useFormik({
    initialValues: customerForEdit,
    validationSchema: CustomerSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      const payload = {
        id: values.id,
        user: {
          firstname: values.firstname,
          lastname: values.lastname,
          username: values.username,
          email: values.email,
          phone: values.phone,
          avatar_url: avatarUrl,
          user_type: values.user_type,
          is_active: isActive,
          state: values.state,
          password: values.password
        },
        details: {
          user_id: values.id,
          contact_info: {
            address: values.address,
            latitude: values.latitude,
            longitude: values.longitude,
          }
        }
      }
      try {
        if (isNotEmpty(values.id)) {
          await updateCustomer(payload)
          toast.success('Record updated successfully');
        } else {
          delete payload['id'];
          delete payload['details']['user_id'];
          await createCustomer(payload)
          toast.success('Record created successfully');
        }
      } catch (ex) {
        console.error(ex)
        toast.warning('An error occured');
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const handleUploadSuccess = (imageUrl) => {
    formik.setFieldValue('avatar_url', imageUrl);
    setAvatarUrl(imageUrl);
  };
  
  return (
    <>
      <form id='kt_modal_add_customer_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_customer_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_customer_header'
          data-kt-scroll-wrappers='#kt_modal_add_customer_scroll'
          data-kt-scroll-offset='300px'
        >

          {/* Set the profile image */}
          <AvatarUpload
            avatarSrc={initialValues.avatar_url}
            onUploadSuccess={handleUploadSuccess}
          />

          <div className="row">
            <InputField
              isLoading={isCustomerLoading}
              formik={formik}
              name="firstname"
              label="First Name"
              placeholder="First Name"
              type='text'
              columns={6}
            />
            <InputField
              isLoading={isCustomerLoading}
              formik={formik}
              name="lastname"
              label="Last Name"
              placeholder="Last Name"
              type='text'
              columns={6}
            />
          </div>

          <div className="row">
            <InputField
              isLoading={isCustomerLoading}
              formik={formik}
              name="username"
              label="User Name"
              placeholder="User Name"
              type='text'
              columns={6}
            />

            <InputField
              isLoading={isCustomerLoading}
              formik={formik}
              name="email"
              label="Email"
              placeholder="Email"
              type='text'
              columns={6}
            />
          </div>

          <div className="row">
            <div className="col-md-4">
              <States 
                label="Select state"
                defaultValue={initialValues.state}
                name="state"
                formik={formik}
              />
            </div>

             <InputField
              isLoading={isCustomerLoading}
              formik={formik}
              name="address"
              label="Address"
              placeholder="Address"
              type='text'
              columns={8}
            />
          </div>
          
          
          <div className="row">
            <InputField
              isLoading={isCustomerLoading}
              formik={formik}
              name="latitude"
              label="Latitude"
              placeholder="Latitude"
              type='text'
              columns={6}
            />
            <InputField
              isLoading={isCustomerLoading}
              formik={formik}
              name="longitude"
              label="Longitude"
              placeholder="Longitude"
              type='text'
              columns={6}
            />
          </div>
          
          <div className="row">

            <InputField
              isLoading={isCustomerLoading}
              formik={formik}
              name="phone"
              label="Phone number"
              placeholder="Phone number"
              type='text'
              columns={6}
            />

            <InputField
              isLoading={isCustomerLoading}
              formik={formik}
              name="password"
              label="Password"
              placeholder="Password"
              type='text'
              columns={6}
            />

          </div>
          
          <div className="form-check form-switch form-check-custom form-check-solid me-10">
            <input 
              className="form-check-input h-30px w-70px"
              type="checkbox"
              id="flexSwitch30x50"
              {...formik.getFieldProps('is_active')}
              checked={isActive}
              onChange={(e) => setIsActive(e.target.checked)}
              />
            <label className="form-check-label">
                Is Available
            </label>
          </div>
          
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-customers-modal-action='cancel'
            disabled={formik.isSubmitting || isCustomerLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-customers-modal-action='submit'
            disabled={isCustomerLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isCustomerLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isCustomerLoading) && <TableListLoader />}
    </>
  )
}

const ModalHeader = ({title}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>{itemIdForUpdate ? `Edit ${title}` : `Add ${title}`}</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-customers-modal-action='close'
        onClick={() => setItemIdForUpdate(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

const ModalWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: customer,
    error,
  } = useQuery(
    `${QUERIES.CUSTOMERS_LIST}-customer-${itemIdForUpdate}`,
    () => {
      return getCustomerById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <ModalForm isCustomerLoading={isLoading} customer={{id: undefined}} />
  }

  if (!isLoading && !error && customer) {
    return <ModalForm isCustomerLoading={isLoading} customer={customer} />
  }

  return null
}


const CustomerModal = ({title}) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_customer'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-900px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <ModalHeader title={title}/>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-10 my-2'>
              <ModalWrapper />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

const AddCustomerButton = () => {
  const {setItemIdForUpdate} = useListView()

  const openAddCustomerModal = () => {
    setItemIdForUpdate(null)
  }

  return (
      <button type='button' className='btn btn-primary' onClick={openAddCustomerModal}>
        <KTIcon iconName='plus' className='fs-2' />
        Add Customer
      </button>
  )
}

export {AddCustomerButton, CustomerModal}