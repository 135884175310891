import axios, {AxiosResponse} from 'axios'
import {ID, Response, processQueryString} from '../../../../../_metronic/helpers'
import {Metric, MetricsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const METRIC_URL = `${API_URL}/installers`
const GET_METRICS_URL = `${API_URL}/installers`

const getMetrics = (query: string): Promise<MetricsQueryResponse> => {
  const filters = processQueryString(query);

  return axios
    .get(`${GET_METRICS_URL}?${filters}`)
    .then((d: AxiosResponse<MetricsQueryResponse>) => {
      return d.data
    })
}

const getAllMetrics = () => {

  return axios
    .get(`${GET_METRICS_URL}`)
    .then((d) => d.data)
}

const getMetricById = (id: ID): Promise<Metric | undefined> => {
  return axios
    .get(`${METRIC_URL}/${id}`)
    .then((response: AxiosResponse<Response<Metric>>) => response.data)
    .then((response: Response<Metric>) => response.data)
}

const createMetric = (metric): Promise<Metric | undefined> => {
  return axios
    .post(METRIC_URL, metric)
    .then((response: AxiosResponse<Response<Metric>>) => response.data)
    .then((response: Response<Metric>) => response.data)
}

const updateMetric = (metric): Promise<Metric | undefined> => {
  return axios
    .put(`${METRIC_URL}/${metric.id}`, metric)
    .then((response: AxiosResponse<Response<Metric>>) => response.data)
    .then((response: Response<Metric>) => response.data)
}

const deleteMetric = (metricId: ID): Promise<void> => {
  return axios.delete(`${METRIC_URL}/${metricId}`).then(() => {})
}

// const deleteSelectedMetrics = () => {}
const deleteSelectedMetrics = (metricIds: Array<ID>): Promise<void> => {
  const requests = metricIds.map((id) => axios.delete(`${METRIC_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getMetrics, getAllMetrics, getMetricById, createMetric, updateMetric, deleteMetric, deleteSelectedMetrics}