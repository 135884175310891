import axios, {AxiosResponse} from 'axios'
import {ID, Response, processQueryString} from '../../../../../_metronic/helpers'
import {Feedback, FeedbacksQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const FEEDBACK_URL = `${API_URL}/feedbacks`
const GET_FEEDBACKS_URL = `${API_URL}/feedbacks`

const getFeedbacks = (query: string): Promise<FeedbacksQueryResponse> => {
  const filters = processQueryString(query);

  return axios
    .get(`${GET_FEEDBACKS_URL}?${filters}`)
    .then((d: AxiosResponse<FeedbacksQueryResponse>) => {
      return d.data
    })
}

const getAllFeedbacks = () => {

  return axios
    .get(`${GET_FEEDBACKS_URL}`)
    .then((d) => d.data)
}

const getFeedbackById = (id: ID): Promise<Feedback | undefined> => {
  return axios
    .get(`${FEEDBACK_URL}/${id}`)
    .then((response: AxiosResponse<Response<Feedback>>) => response.data)
    .then((response: Response<Feedback>) => response.data)
}

const createFeedback = (feedback): Promise<Feedback | undefined> => {
  return axios
    .post(FEEDBACK_URL, feedback)
    .then((response: AxiosResponse<Response<Feedback>>) => response.data)
    .then((response: Response<Feedback>) => response.data)
}

const updateFeedback = (feedback): Promise<Feedback | undefined> => {
  return axios
    .put(`${FEEDBACK_URL}/${feedback.id}`, feedback)
    .then((response: AxiosResponse<Response<Feedback>>) => response.data)
    .then((response: Response<Feedback>) => response.data)
}

const deleteFeedback = (feedbackId: ID): Promise<void> => {
  return axios.delete(`${FEEDBACK_URL}/${feedbackId}`).then(() => {})
}

// const deleteSelectedFeedbacks = () => {}
const deleteSelectedFeedbacks = (feedbackIds: Array<ID>): Promise<void> => {
  const requests = feedbackIds.map((id) => axios.delete(`${FEEDBACK_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getFeedbacks, getAllFeedbacks, getFeedbackById, createFeedback, updateFeedback, deleteFeedback, deleteSelectedFeedbacks}