/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import { toast } from 'react-toastify';
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'
import {KTIcon} from '../../../../_metronic/helpers'
import {useListView} from '../../../common/table/core/ListViewProvider'
import ConfirmDeleteDialog from "../../../common/components/ConfirmDeleteDialog"


// I refactored this cos actions might differ
const ActionsCell = ({id, queryList, useQueryResponse, deleteEntity}) => {
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const [isDialogVisible, setDialogVisible] = useState(false);

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const deleteItem = useMutation(() => deleteEntity(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${queryList}-${query}`])
      toast.success('Records deleted successfully');
    },
  })

  const handleDelete = async () => {
    await deleteItem.mutateAsync();
    setDialogVisible(false)
  };

  const handleCancelConfirm = () => {
    setDialogVisible(false);
  };

  const handleShowDialog = () => {
    setDialogVisible(true);
  };

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            <i className="bi bi-pencil-square"></i>&nbsp; Edit
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-table-filter='delete_row'
            // onClick={async () => await deleteItem.mutateAsync()}
            onClick={handleShowDialog}
          >
            <i className="bi bi-trash"></i> &nbsp; Delete
          </a>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
      <ConfirmDeleteDialog
        open={isDialogVisible}
        onCancel={handleCancelConfirm}
        onConfirm={handleDelete}
      />
    </>
  )
}

export {ActionsCell}
