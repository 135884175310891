import axios, {AxiosResponse} from 'axios'
import {ID, Response, processQueryString} from '../../../../../_metronic/helpers'
import {Ticket, TicketsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const TICKET_URL = `${API_URL}/tickets`
const GET_TICKETS_URL = `${API_URL}/tickets`

const getTickets = (query: string): Promise<TicketsQueryResponse> => {
  const filters = processQueryString(query);

  return axios
    .get(`${GET_TICKETS_URL}?${filters}`)
    .then((d: AxiosResponse<TicketsQueryResponse>) => {
      return d.data
    })
}

const getAllTickets = () => {

  return axios
    .get(`${GET_TICKETS_URL}`)
    .then((d) => d.data)
}

const createMessage = (payload) => {
  return axios
    .post(`${TICKET_URL}/messages/`, payload)
    .then((response) => response.data)
}

const getMessages = (ticketId) => {
  return axios
    .get(`${TICKET_URL}/messages/?ticket_id=${ticketId}`)
    .then((d) => d.data)
}

const getTicketById = (id: ID): Promise<Ticket | undefined> => {
  return axios
    .get(`${TICKET_URL}/${id}`)
    .then((response: AxiosResponse<Response<Ticket>>) => response.data)
    .then((response: Response<Ticket>) => response.data)
}

const createTicket = (ticket): Promise<Ticket | undefined> => {
  return axios
    .post(TICKET_URL, ticket)
    .then((response: AxiosResponse<Response<Ticket>>) => response.data)
    .then((response: Response<Ticket>) => response.data)
}

const updateTicket = (ticket): Promise<Ticket | undefined> => {
  return axios
    .put(`${TICKET_URL}/${ticket.id}`, ticket)
    .then((response: AxiosResponse<Response<Ticket>>) => response.data)
    .then((response: Response<Ticket>) => response.data)
}

const deleteTicket = (ticketId: ID): Promise<void> => {
  return axios.delete(`${TICKET_URL}/${ticketId}`).then(() => {})
}

// const deleteSelectedTickets = () => {}
const deleteSelectedTickets = (ticketIds: Array<ID>): Promise<void> => {
  const requests = ticketIds.map((id) => axios.delete(`${TICKET_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getTickets, createMessage, getMessages, getAllTickets, getTicketById, createTicket, updateTicket, deleteTicket, deleteSelectedTickets}