import Select from 'react-select';
import { useState, useEffect } from 'react';
import { getAllInstallers } from '../../pages/users/installers/core/_requests';

const Installers = ({ label, name, defaultValue, formik }) => {
  const [options, setOptions] = useState([]);
  defaultValue = defaultValue.label === undefined || defaultValue.value === '' ? null : defaultValue
  useEffect(() => {
    const fetchInstallers = async () => {
      try {
        const data = await getAllInstallers();
        if (data.data) {
          const options = data.data.map((installer) => ({
            label: installer.user?.firstname +" "+installer.user?.lastname,
            value: installer.id,
          }));
          setOptions(options);
        }
      } catch (error) {
        console.error('Error fetching installers:', error);
      }
    };

    fetchInstallers();
  }, []);

  return (
    <>
      <div className='mb-10'>
        <label className='form-label'><strong>{label}</strong></label>
        <Select
          className='react-select-styled react-select-solid'
          classNamePrefix='react-select'
          options={options}
          placeholder='Select an option'
          defaultValue={defaultValue ? defaultValue : null}
          onChange={(selectedOption) => {
            if ((Object.keys(formik).length != 0)) {
              formik.setFieldValue(name, selectedOption.value);
            }

          }}
        />
      </div>
    </>
  );
};

export { Installers };
