import React from "react";
import {CertificationsListWrapper} from './CertificationsList'
import {PageTitle} from '../../../../_metronic/layout/core'

const certificationsBreadcrumbs = [
  {
    title: '',
    path: '/coordination/certifications',
    isSeparator: false,
    isActive: false,
  },
]

export default function CertificationsPage() {
    return (
        <>
            <PageTitle breadcrumbs={certificationsBreadcrumbs} description='Manage certifications'>
                Certifications
            </PageTitle>
            <CertificationsListWrapper />
        </>
    )
}