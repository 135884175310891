import React from "react";
import {InstallersListWrapper} from './InstallersList'
import {PageTitle} from '../../../../_metronic/layout/core'

const usersBreadcrumbs = [
  {
    title: '',
    path: '/users/installers',
    isSeparator: false,
    isActive: false,
  },
]

export default function InstallersPage() {
    return (
        <>
            <PageTitle breadcrumbs={usersBreadcrumbs} description='Manage installers'>
                Installers Profiles
            </PageTitle>
            <InstallersListWrapper />
        </>
    )
}