import axios, {AxiosResponse} from 'axios'
import {ID, Response, processQueryString} from '../../../../../_metronic/helpers'
import {Installer, InstallersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const INSTALLER_URL = `${API_URL}/installers`
const GET_INSTALLERS_URL = `${API_URL}/installers`

const getInstallers = (query: string): Promise<InstallersQueryResponse> => {
  const filters = processQueryString(query);

  return axios
    .get(`${GET_INSTALLERS_URL}?${filters}`)
    .then((d: AxiosResponse<InstallersQueryResponse>) => {
      return d.data
    })
}

const getAllInstallers = () => {

  return axios
    .get(`${GET_INSTALLERS_URL}`)
    .then((d) => d.data)
}

const getInstallerById = (id): Promise<Installer | undefined> => {
  return axios
    .get(`${INSTALLER_URL}/${id}`)
    .then((response: AxiosResponse<Response<Installer>>) => response.data)
    .then((response: Response<Installer>) => response.data)
}

const createInstaller = (installer): Promise<Installer | undefined> => {
  return axios
    .post(INSTALLER_URL, installer)
    .then((response: AxiosResponse<Response<Installer>>) => response.data)
    .then((response: Response<Installer>) => response.data)
}

const updateInstaller = (installer): Promise<Installer | undefined> => {
  return axios
    .put(`${INSTALLER_URL}/${installer.id}`, installer)
    .then((response: AxiosResponse<Response<Installer>>) => response.data)
    .then((response: Response<Installer>) => response.data)
}

const deleteInstaller = (installerId: ID): Promise<void> => {
  return axios.delete(`${INSTALLER_URL}/${installerId}`).then(() => {})
}

// const deleteSelectedInstallers = () => {}
const deleteSelectedInstallers = (installerIds: Array<ID>): Promise<void> => {
  const requests = installerIds.map((id) => axios.delete(`${INSTALLER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getInstallers, getAllInstallers, getInstallerById, createInstaller, updateInstaller, deleteInstaller, deleteSelectedInstallers}