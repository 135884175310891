import * as Yup from 'yup'
import {useEffect} from 'react'
import {FC, useState} from 'react'
import {useQuery} from 'react-query'
import { toast } from 'react-toastify';
import {KTIcon, QUERIES, isNotEmpty} from '../../../../../_metronic/helpers'
import {useListView} from '../../../../common/table/core/ListViewProvider'
import {TableListLoader} from '../../../../common/table/components/TableListLoader'
import {useFormik} from 'formik'
import {getTicketById, createTicket, updateTicket} from '../core/_requests'
import {Ticket} from '../core/_models'
import {useQueryResponse} from '../core/QueryResponseProvider'
import InputField from '../../../../common/components/InputField'
import DateTime from '../../../../common/components/DateTime'
import SelectField from '../../../../common/components/SelectField'
import TextArea from '../../../../common/components/TextArea'
import {Jobs} from '../../../../common/components/Jobs'

type Props = {
  isTicketLoading: boolean
  ticket: Ticket
}

const TicketSchema = Yup.object().shape({
  job_id: Yup.string(),
  ticket_id: Yup.string()
    .required('Ticket ID is required'),
  status: Yup.string(),
  category: Yup.string(),
  priority: Yup.string(),
  subject: Yup.string(),
  description: Yup.string(),
  creation_date: Yup.date(),
  resolution_date: Yup.date(),
})

/*status: open active close
category: installation, maintenace, billing, general
priority: low, medium, high*/

const ModalForm: FC<Props> = ({ticket, isTicketLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  const initialValues = {
    id: ticket.id,
    job_id: ticket.job_id || '',
    ticket_id: ticket.ticket_id || '',
    status: ticket.status || 'open',
    category: ticket.category || '',
    priority: ticket.priority || '',
    subject: ticket.subject || '',
    description: ticket.description || '',
    creation_date: ticket.creation_date || '',
    resolution_date: ticket.resolution_date || ''
  }

  const [ticketForEdit] = useState(initialValues)

  const formik = useFormik({
    initialValues: ticketForEdit,
    validationSchema: TicketSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      const payload = {
        id: values.id,
        job_id: initialValues.job_id,
        ticket_id: initialValues.ticket_id,
        status: values.status,
        category: values.category,
        priority: values.priority,
        subject: values.subject,
        description: values.description,
        creation_date: initialValues.creation_date,
        resolution_date: values.resolution_date,
        user_id: ticket.user_id
      }
      try {
        if (isNotEmpty(values.id)) {
          console.log(payload)
          await updateTicket(payload)
          toast.success('Record updated successfully');
        } else {
          delete payload['id'];
          await createTicket(payload)
          toast.success('Record created successfully');
        }
      } catch (ex) {
        console.error(ex)
        toast.warning('An error occured');
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })
  
  return (
    <>
      <form id='kt_modal_add_ticket_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_ticket_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_ticket_header'
          data-kt-scroll-wrappers='#kt_modal_add_ticket_scroll'
          data-kt-scroll-offset='300px'
        >


          <div className="row">
            <div className="col-md-6">
              <Jobs 
                label="Select job"
                name="job"
                formik={formik}
                isDisabled={true}
                defaultValue={ticket.job
                    ? {
                        label: ticket.job.title,
                        value: initialValues.job_id
                      }
                    : {}
                  }
                />
            </div>

            <InputField
              isLoading={true}
              formik={formik}
              name="ticket_id"
              label="Ticket ID"
              placeholder="Ticket ID"
              type='text'
              columns={6}
            />

          </div>

          <div className="row">
            <DateTime
              defaultValue={initialValues.creation_date}
              columns={6}
              label="Date submitted"
              name="creation_date"
              formik={formik}
              isLoading={true}
            />
            
            <InputField
              isLoading={isTicketLoading}
              formik={formik}
              name="subject"
              label="Subject"
              placeholder="Subject"
              type='text'
              columns={6}
            />

          </div>
          <div className="row">
            <SelectField
              options={[
                {label: 'Billing', value: 'billing'}, 
                {label: 'General', value: 'general'}, 
                {label: 'Installation', value: 'installation'},
                {label: 'Maintenance', value: 'maintenance'}, 
              ]}
              defaultValue={initialValues.category}
              name="category"
              columns={6}
              label="Category"
              formik={formik}
            />

            <SelectField
              options={[
                {label: 'Low', value: 'low'}, 
                {label: 'Medium', value: 'medium'}, 
                {label: 'High', value: 'high'},
              ]}
              defaultValue={initialValues.priority}
              name="priority"
              columns={6}
              label="Priority"
              formik={formik}
            />

          </div>

          <div className="row">
            <SelectField
              options={[
                {label: 'Open', value: 'open'}, 
                {label: 'Active', value: 'active'}, 
                {label: 'Closed', value: 'closed'},
              ]}
              defaultValue={initialValues.status}
              name="status"
              columns={6}
              label="Status"
              formik={formik}
            />

           {formik.values.status === 'closed' && (
              <DateTime
                defaultValue={initialValues.resolution_date}
                columns={6}
                label="Resolution date"
                name="resolution_date"
                formik={formik} 
                isLoading={isTicketLoading}
              />
            )}
          </div>

          <div className="row">
            <TextArea
              isLoading={isTicketLoading}
              formik={formik}
              name="description"
              label="Description"
              placeholder="Description"
              columns={12}
            />

          </div>
          
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-tickets-modal-action='cancel'
            disabled={formik.isSubmitting || isTicketLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-tickets-modal-action='submit'
            disabled={isTicketLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isTicketLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isTicketLoading) && <TableListLoader />}
    </>
  )
}

const ModalHeader = ({title}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>{itemIdForUpdate ? `Edit ${title}` : `Add ${title}`}</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-tickets-modal-action='close'
        onClick={() => setItemIdForUpdate(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

const ModalWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: ticket,
    error,
  } = useQuery(
    `${QUERIES.TICKETS_LIST}-ticket-${itemIdForUpdate}`,
    () => {
      return getTicketById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <ModalForm isTicketLoading={isLoading} ticket={{id: undefined}} />
  }

  if (!isLoading && !error && ticket) {
    return <ModalForm isTicketLoading={isLoading} ticket={ticket} />
  }

  return null
}


const TicketModal = ({title}) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_ticket'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-900px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <ModalHeader title={title}/>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-10 my-2'>
              <ModalWrapper />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

const AddTicketButton = () => {
  const {setItemIdForUpdate} = useListView()

  const openAddTicketModal = () => {
    setItemIdForUpdate(null)
  }

  return (
      <button type='button' className='btn btn-primary' onClick={openAddTicketModal}>
        <KTIcon iconName='plus' className='fs-2' />
        Add Ticket
      </button>
  )
}

export {AddTicketButton, TicketModal}