import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./styles.css";
import L from 'leaflet';

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

function LocationView({position}){

	return (
		<MapContainer
		  center={[position.latitude ? position.latitude : 0.0, position.longitude ? position.longitude : 0.0]}
		  zoom={13}
		  scrollWheelZoom={true}
		>
		  <TileLayer
		    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
		    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
		  />
		  <Marker position={[position.latitude ? position.latitude : 0.0, position.longitude ? position.longitude : 0.0]}>
		      <Popup>
		        {position.address ? position.address : ''}
		      </Popup>
		  </Marker>
		</MapContainer>
	);
}

export default LocationView;