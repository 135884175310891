import React from "react";
import {CertificatesListWrapper} from './CertificatesList'
import {PageTitle} from '../../../../_metronic/layout/core'

const certificatesBreadcrumbs = [
  {
    title: '',
    path: '/coordination/certificates',
    isSeparator: false,
    isActive: false,
  },
]

export default function CertificatesPage() {
    return (
        <>
            <PageTitle breadcrumbs={certificatesBreadcrumbs} description='Manage certificates'>
                Certificates
            </PageTitle>
            <CertificatesListWrapper />
        </>
    )
}