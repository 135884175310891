import React from "react";
import {CustomersListWrapper} from './CustomersList'
import {PageTitle} from '../../../../_metronic/layout/core'

const customersBreadcrumbs = [
  {
    title: '',
    path: '/customers/admins',
    isSeparator: false,
    isActive: false,
  },
]

export default function CustomersPage() {
    return (
        <>
            <PageTitle breadcrumbs={customersBreadcrumbs} description='Manage customers'>
                Customers Profiles
            </PageTitle>
            <CustomersListWrapper />
        </>
    )
}