import {ListViewProvider, useListView} from '../../../common/table/core/ListViewProvider'
import {QueryRequestProvider} from '../../../common/table/core/QueryRequestProvider'
import {QueryResponseProvider, useQueryResponse, useQueryResponseData} from './core/QueryResponseProvider'
import {InvoicesListHeader} from './components/InvoicesListHeader'
import {InvoicesTable} from './table/InvoicesTable'
import {KTCard} from '../../../../_metronic/helpers'
import {InvoiceModal} from  './components/InvoiceModal'

const InvoicesList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <InvoicesListHeader />
        <InvoicesTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <InvoiceModal title="invoice"/>}
    </>
  );
}

const InvoicesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider
        useQueryResponse={useQueryResponse}
        useQueryResponseData={useQueryResponseData}
      >
        <InvoicesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {InvoicesListWrapper}
