import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const INTERVIEWS_URL = `${API_URL}/interviews`;

const getInterviewSchedules = () => {
  return axios
    .get(`${INTERVIEWS_URL}`)
    .then((response) => response.data.data);
};

const addInterviewSchedule = (data) => {
  return axios
    .post(`${INTERVIEWS_URL}`, data)
    .then((response) => response.data.data);
};

export { getInterviewSchedules, addInterviewSchedule };