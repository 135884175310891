import {PageTitle} from '../../../../_metronic/layout/core'
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getJobById } from './core/_requests';
import PleaseWait from '../../../common/components/PleaseWait'
import LocationView from '../../../common/components/LocationView'
import { Link } from 'react-router-dom';
// import {UserProfileCard} from '../../../common/components/UserProfile'
import {capitalize, formatDate} from '../../../../_metronic/helpers'
import RatingStars from '../../../common/components/RatingStars'

const JobDetails = ({ job }) => {

  return (
    <div className="card">
      <div className="card-body">

        <div className="row mb-3">
          <div className="col">
            <h4 className="card-title mb-4 fs-2">{job.title}</h4>
          </div>
          <div className="col">
            <RatingStars totalStars={5} numStars={job.rating} />          
          </div>
        
        </div>
        <div className="row mb-3">
          <div className="col">
            <p className="mb-2 fs-4"><strong>Status:</strong> {capitalize(job.status)}</p>
            <p className="mb-2 fs-4"><strong>Service Type:</strong> {capitalize(job.service_type)}</p>
            <p className="mb-2 fs-4"><strong>Scheduled Date:</strong> {formatDate(job.scheduled_date)}</p>
            <p className="mb-2 fs-4"><strong>Completion Date:</strong> {formatDate(job.completion_date)}</p>
          </div>

          <div className="col">
            <p className="mb-2 fs-4"><strong>Description:</strong> {job.description}</p>
            <p className="mb-2 fs-4"><strong>Cost:</strong> ₦{job.cost}</p>
            <p className="mb-2 fs-4"><strong>Address:</strong> {job.location?.address}</p>
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col">
            {job.installer_id ? (
              <>
                <p className="mb-2 fs-4"><strong>Installer:</strong> <Link to={`/users/installers/${job.installer_id}`}>{job.installer && job.installer.firstname} {job.installer && job.installer.lastname}</Link></p>
                <p className="mb-2 fs-4"><strong>Installer Email:</strong> {job.installer && job.installer.email}</p>
              </>
            ) : (
              <p className="mb-2 fs-4"><strong>Installer:</strong> Installer information not available</p>
            )}
          </div>

          <div className="col">
            {job.customer_id ? (
              <>
                <p className="mb-2 fs-4"><strong>Customer:</strong> <Link to={`/users/customers/${job.customer_id}`}>{job.customer.firstname} {job.customer.lastname}</Link></p>
                <p className="mb-2 fs-4"><strong>Customer Email:</strong> {job.customer.email}</p>
              </>
            ) : (
              <p className="mb-2 fs-4"><strong>Customer:</strong> Customer information not available</p>
            )}
          </div>

        </div>
        <hr />
        <h5 className="card-title mb-4 fs-2" style={{marginTop: '30px'}}>Location</h5>
        <div className="row">
          <div className="p-5">
            <LocationView position={{...job.location}}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function JobHistoryViewPage() {
    const { id } = useParams();
    const [job, setJob] = useState({});
    const [isLoading, setIsLoading] = useState(true)
    const [title, setTitle] = useState('')

    useEffect(() => {
        const fetchJob = async () => {
          try {
            const data = await getJobById(id);
            if(data){
              setJob(data)
              setTitle(data?.title!)
            }
          } catch (error) {
            console.error('Error fetching job:', error);
          } finally {
            setIsLoading(false)
          }
        };
        fetchJob();
    }, [id])

    return (
      <>
        <PageTitle description={title}>
          <Link to="/jobs/history">Job Histories</Link>
        </PageTitle>
        {isLoading ? <PleaseWait /> : <JobDetails job={job}/>}
        {/*{isLoading ? <PleaseWait /> : <UserProfileCard user={{}} userType="Installer"/>}*/}
      </>
    );
}