import Select from 'react-select';
import { useState, useEffect } from 'react';
import { getAllCertificates } from '../../pages/coordination/certificates/core/_requests';

const Certificates = ({ label, name, defaultValue, formik }) => {
  const [options, setOptions] = useState([]);
  defaultValue = defaultValue.label === undefined || defaultValue.value === '' ? null : defaultValue
  useEffect(() => {
    const fetchInstallers = async () => {
      try {
        const data = await getAllCertificates();
        if (data.data) {
          const options = data.data.map((certificate) => ({
            label: certificate.title,
            value: certificate.id,
          }));
          setOptions(options);
        }
      } catch (error) {
        console.error('Error fetching Certificates:', error);
      }
    };

    fetchInstallers();
  }, []);

  return (
    <>
      <div className='mb-10'>
        <label className='form-label'><strong>{label}</strong></label>
        <Select
          className='react-select-styled react-select-solid'
          classNamePrefix='react-select'
          options={options}
          placeholder='Select an option'
          defaultValue={defaultValue ? defaultValue : null} 
          onChange={(selectedOption) => formik.setFieldValue('certificate_id', selectedOption.value)}
        />
      </div>
    </>
  );
};

export { Certificates };
