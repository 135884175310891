import Select from 'react-select';
import {STATES} from '../../../_metronic/helpers';

const States = ({label, name, defaultValue, formik}) => {
    const options = STATES.map((state) => ({label: state.toUpperCase(), value: state.toLowerCase()}))
    const defaultVal = options.filter((state) => state['value'] === defaultValue)

    return (
        <>
            <div className='mb-10'>
                <label className='form-label'>{label}</label>
                <Select 
                    className='react-select-styled react-select-lg' 
                    classNamePrefix='react-select' 
                    options={options} 
                    placeholder='Select an option'
                    defaultValue={defaultVal}
                    name={name}
                    onChange={(state) => formik.setFieldValue('state', state?.value)}
                />
            </div>
        </>
    )
}

export { States };