export const addressPoints = [
    [9.0800, 7.4895, "30612345678", "Chinelo Okonkwo", "NMMP", "Active", "https://avatar.iran.liara.run/public/girl", "11 Abuja Road, Abuja"],
    [9.0850, 7.4800, "30623456789", "Aminu Kano", "MAP", "Inactive", "https://avatar.iran.liara.run/public/boy", "23 Lagos Street, Abuja"],
    [9.0750, 7.4900, "30634567890", "Fatima Bello", "MAP", "Active", "https://avatar.iran.liara.run/public/girl", "7 Kano Road, Abuja"],
    [9.0820, 7.4795, "30645678901", "Ifeanyi Chukwu", "NMMP", "Inactive", "https://avatar.iran.liara.run/public/boy", "34 Enugu Crescent, Abuja"],
    [9.0790, 7.4850, "30656789012", "Halima Musa", "NMMP", "Active", "https://avatar.iran.liara.run/public/girl", "56 Bauchi Road, Abuja"],
    [9.0780, 7.4880, "30667890123", "Segun Adelaja", "MAP", "Active", "https://avatar.iran.liara.run/public/boy", "12 Oyo Close, Abuja"],
    [9.0710, 7.4810, "30678901234", "Mariam Adekunle", "NMMP", "Inactive", "https://avatar.iran.liara.run/public/girl", "45 Plateau Avenue, Abuja"],
    [9.0730, 7.4825, "30689012345", "Nnamdi Okoro", "MAP", "Active", "https://avatar.iran.liara.run/public/boy", "78 Nassarawa Street, Abuja"],
    [9.0775, 7.4870, "30690123456", "Omotola Johnson", "MAP", "Inactive", "https://avatar.iran.liara.run/public/girl", "22 Delta Way, Abuja"],
    [9.0810, 7.4865, "30601234567", "Tinuola Smith", "NMMP", "Active", "https://avatar.iran.liara.run/public/girl", "99 Kaduna Path, Abuja"],
    [9.0855, 7.4805, "30612345678", "Umar Ali", "MAP", "Inactive", "https://avatar.iran.liara.run/public/boy", "15 Borno Road, Abuja"],
    [9.0700, 7.4820, "30623456789", "Vivian Thomas", "NMMP", "Active", "https://avatar.iran.liara.run/public/girl", "83 Taraba Drive, Abuja"],
    [9.0760, 7.4885, "30634567890", "Wale Bakare", "MAP", "Inactive", "https://avatar.iran.liara.run/public/boy", "65 Gombe Street, Abuja"],
    [9.0720, 7.4795, "30645678901", "Xavier Onwuka", "NMMP", "Active", "https://avatar.iran.liara.run/public/boy", "28 Yobe Crescent, Abuja"],
    [9.0795, 7.4890, "30656789012", "Yemi Okeke", "MAP", "Active", "https://avatar.iran.liara.run/public/girl", "37 Sokoto Road, Abuja"],
    [9.0785, 7.4860, "30667890123", "Zainab Mohammed", "NMMP", "Inactive", "https://avatar.iran.liara.run/public/girl", "49 Adamawa Lane, Abuja"],
    [9.0805, 7.4905, "30678901234", "Aisha Yusuf", "MAP", "Active", "https://avatar.iran.liara.run/public/girl", "103 Cross River Road, Abuja"],
    [9.0715, 7.4830, "30689012345", "Babatunde Fashola", "NMMP", "Inactive", "https://avatar.iran.liara.run/public/boy", "12 Benue Boulevard, Abuja"],
    [9.0755, 7.4895, "30690123456", "Damilola Afolabi", "MAP", "Active", "https://avatar.iran.liara.run/public/girl", "21 Zamfara Avenue, Abuja"],
    [9.0740, 7.4875, "30601234567", "Emeka Ndubuisi", "NMMP", "Inactive", "https://avatar.iran.liara.run/public/boy", "3 Ebonyi Circle, Abuja"],
    [9.0802, 7.4812, "30612345678", "Funke Adewale", "MAP", "Active", "https://avatar.iran.liara.run/public/girl", "33 Jos East Road, Abuja"]
  ]
  
  ;
