import {ListViewProvider, useListView} from '../../../common/table/core/ListViewProvider'
import {QueryRequestProvider} from '../../../common/table/core/QueryRequestProvider'
import {QueryResponseProvider, useQueryResponse, useQueryResponseData} from './core/QueryResponseProvider'
import {JobsHistoryListHeader} from './components/JobsHistoryListHeader'
import {JobsHistoryTable} from './table/JobsHistoryTable'
import {KTCard} from '../../../../_metronic/helpers'
import {JobHistoryModal} from  './components/JobHistoryModal'

const JobsHistoryList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <JobsHistoryListHeader />
        <JobsHistoryTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <JobHistoryModal title="job"/>}
    </>
  );
}

const JobsHistoryListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider
        useQueryResponse={useQueryResponse}
        useQueryResponseData={useQueryResponseData}
      >
        <JobsHistoryList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {JobsHistoryListWrapper}
