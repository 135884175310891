import axios, {AxiosResponse} from 'axios'
import {ID, Response, processQueryString} from '../../../../../_metronic/helpers'
import {Paymentrequest, PaymentrequestsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const PAYMENTREQUEST_URL = `${API_URL}/payments-requests`
const GET_PAYMENTREQUESTS_URL = `${API_URL}/payments-requests/`

const getPaymentrequests = (query: string): Promise<PaymentrequestsQueryResponse> => {
  const filters = processQueryString(query);

  return axios
    .get(`${GET_PAYMENTREQUESTS_URL}?${filters}`)
    .then((d: AxiosResponse<PaymentrequestsQueryResponse>) => {
      return d.data
    })
}

const getAllPaymentrequests = () => {

  return axios
    .get(`${GET_PAYMENTREQUESTS_URL}`)
    .then((d) => d.data)
}

const getPaymentrequestById = (id: ID): Promise<Paymentrequest | undefined> => {
  return axios
    .get(`${PAYMENTREQUEST_URL}/${id}`)
    .then((response: AxiosResponse<Response<Paymentrequest>>) => response.data)
    .then((response: Response<Paymentrequest>) => response.data)
}

const createPaymentrequest = (paymentrequest): Promise<Paymentrequest | undefined> => {
  return axios
    .post(PAYMENTREQUEST_URL, paymentrequest)
    .then((response: AxiosResponse<Response<Paymentrequest>>) => response.data)
    .then((response: Response<Paymentrequest>) => response.data)
}

const updatePaymentrequest = (paymentrequest): Promise<Paymentrequest | undefined> => {
  return axios
    .put(`${PAYMENTREQUEST_URL}/${paymentrequest.id}`, paymentrequest)
    .then((response: AxiosResponse<Response<Paymentrequest>>) => response.data)
    .then((response: Response<Paymentrequest>) => response.data)
}

const deletePaymentrequest = (paymentrequestId: ID): Promise<void> => {
  return axios.delete(`${PAYMENTREQUEST_URL}/${paymentrequestId}`).then(() => {})
}

// const deleteSelectedPaymentrequests = () => {}
const deleteSelectedPaymentrequests = (paymentrequestIds: Array<ID>): Promise<void> => {
  const requests = paymentrequestIds.map((id) => axios.delete(`${PAYMENTREQUEST_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getPaymentrequests, getAllPaymentrequests, getPaymentrequestById, createPaymentrequest, updatePaymentrequest, deletePaymentrequest, deleteSelectedPaymentrequests}