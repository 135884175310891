/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'
// import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        title='Home'
        fontIcon='bi-house fs-2'
        bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        className='py-2'
      />

      <AsideMenuItemWithSubMain
        to='/builder'
        title='Users'
        bsTitle='Users'
        fontIcon='bi-people'
      >
        {/*<AsideMenuItem to='apps/user-management/users' title='Customer Profiles' fontIcon='bi-layers fs-3' />*/}
        <AsideMenuItem to='users/customers' title='Customer Profiles' fontIcon='bi-layers fs-3' />
        <AsideMenuItem to='users/installers' title='Installer Profiles' fontIcon='bi-layers fs-3' />
        <AsideMenuItem to='users/admins' title='Admin Profiles' fontIcon='bi-layers fs-3' />
      </AsideMenuItemWithSubMain>


      <AsideMenuItemWithSubMain
        to='/builder'
        title='Jobs'
        bsTitle='Jobs'
        fontIcon='bi-tools'
      >
        {/*<AsideMenuItem to='/jobs/assignments' title='Job Assignments' fontIcon='bi-layers fs-3' />*/}
        <AsideMenuItem to='/jobs/active-jobs' title='Active Jobs' fontIcon='bi-layers fs-3' />
        <AsideMenuItem to='/jobs/history' title='Job History' fontIcon='bi-layers fs-3' />
      </AsideMenuItemWithSubMain>


      <AsideMenuItemWithSubMain
        to='/builder'
        title='Finance'
        bsTitle='Finance'
        fontIcon='bi-cash-coin'
      >
        <AsideMenuItem to='/finance/overview' title='Payments Overview' fontIcon='bi-layers fs-3' />
        <AsideMenuItem to='/finance/payment-requests' title='Payments Requests' fontIcon='bi-layers fs-3' />
        <AsideMenuItem to='/finance/invoices' title='Invoice Management' fontIcon='bi-layers fs-3' />
        <AsideMenuItem to='/finance/reports' title='Financial Reporting' fontIcon='bi-layers fs-3' />
      </AsideMenuItemWithSubMain>



      <AsideMenuItemWithSubMain
        to='/builder'
        title='Customer Support'
        bsTitle='Customer Support'
        fontIcon='bi-headset'
      >
        <AsideMenuItem to='/support/tickets' title='Support Tickets' fontIcon='bi-layers fs-3' />
        {/*<AsideMenuItem to='/user-management' title='Chat History' fontIcon='bi-layers fs-3' />*/}
        <AsideMenuItem to='/support/feedbacks' title='Feedback Review' fontIcon='bi-layers fs-3' />
      </AsideMenuItemWithSubMain>



      <AsideMenuItemWithSubMain
        to='/builder'
        title='Installer Coordination'
        bsTitle='Installer Coordination'
        fontIcon='bi-person-lines-fill'
      >
        <AsideMenuItem to='/installers/certificates' title='Certificates' fontIcon='bi-layers fs-3' />
        <AsideMenuItem to='/installers/certifications' title='Certification Tracking' fontIcon='bi-layers fs-3' />
        <AsideMenuItem to='/installers/interview-scheduler' title='Interview Scheduler' fontIcon='bi-layers fs-3' />
        <AsideMenuItem to='/installers/metrics' title='Performance Metrics' fontIcon='bi-layers fs-3' />
      </AsideMenuItemWithSubMain>



      <AsideMenuItemWithSubMain
        to='/builder'
        title='System Settings'
        bsTitle='System Settings'
        fontIcon='bi-gear'
      >
        <AsideMenuItem to='/roles' title='Roles & Permissions' fontIcon='bi-layers fs-3' />
        {/*<AsideMenuItem to='/user-management' title='Application Settings' fontIcon='bi-layers fs-3' />
        <AsideMenuItem to='/user-management' title='Notification Settings' fontIcon='bi-layers fs-3' />
        <AsideMenuItem to='/user-management' title='API Integrations' fontIcon='bi-layers fs-3' />*/}
      </AsideMenuItemWithSubMain>


      

      


     {/*}     
      <AsideMenuItemWithSubMain
        to='/builder'
        title='Reports'
        bsTitle='Reports'
        fontIcon='bi-file-earmark-ruled'
      >
        
       { <AsideMenuItem to='/user-management' title='Custom Reports' fontIcon='bi-layers fs-3' />
        <AsideMenuItem to='/user-management' title='Usage Statistics' fontIcon='bi-layers fs-3' />
        <AsideMenuItem to='/user-management' title='Service Analytics' fontIcon='bi-layers fs-3' />}
      </AsideMenuItemWithSubMain> 
      */}

{/*
      <AsideMenuItemWithSubMain
        to='/builder'
        title='Help'
        bsTitle='Help'
        fontIcon='bi-info-circle'
      >
        <AsideMenuItem to='/user-management' title='Documentation' fontIcon='bi-layers fs-3' />
        <AsideMenuItem to='/user-management' title='Contact Support' fontIcon='bi-layers fs-3' />
      </AsideMenuItemWithSubMain>

      */}




      {/*<AsideMenuItemWithSubMain
        to='/crafted/pages'
        title='Crafted'
        fontIcon='bi-file-text'
        bsTitle='Crafted'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/profile/overview'
            title='Overview'
            bsTitle='Overview'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/pages/profile/projects'
            title='Projects'
            bsTitle='Projects'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            bsTitle='Campaigns'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            bsTitle='Documents'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
            bsTitle='Connections'
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
            bsTitle='Horizontal'
          />
          <AsideMenuItem
            to='/crafted/pages/wizards/vertical'
            title='Vertical'
            bsTitle='Vertical'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/accounts' title='Accounts' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/account/overview'
            title='Overview'
            hasBullet={true}
            bsTitle='Overview'
          />
          <AsideMenuItem
            to='/crafted/account/settings'
            title='Settings'
            hasBullet={true}
            bsTitle='Settings'
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/widgets' title='Widgets' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/widgets/lists'
            title='Lists'
            bsTitle='Lists'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/widgets/statistics'
            title='Statistics'
            bsTitle='Statistics'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/widgets/charts'
            title='Charts'
            bsTitle='Charts'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/widgets/mixed'
            title='Mixed'
            bsTitle='Mixed'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/widgets/tables'
            title='Tables'
            bsTitle='Tables'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/widgets/feeds'
            title='Feeds'
            bsTitle='Feeds'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/apps/chat' title='Chat' hasBullet={true}>
          <AsideMenuItem
            to='/apps/chat/private-chat'
            title='Private Chat'
            bsTitle='Private Chat'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/apps/chat/group-chat'
            title='Group Chart'
            bsTitle='Group Chart'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/apps/chat/drawer-chat'
            title='Drawer Chart'
            bsTitle='Drawer Chart'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='/error' title='Errors' hasBullet={true}>
          <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
          <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItem
          to='/apps/user-management/users'
          title='User management'
          hasBullet={true}
          bsTitle='User management'
        />
      </AsideMenuItemWithSubMain>*/}



    </>
  )
}
