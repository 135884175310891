const QUERIES = {
  JOBS_LIST: 'jobs-list',
  USERS_LIST: 'users-list',
  ROLES_LIST: 'roles-list',
  INSTALLERS_LIST: 'installers-list',
  CUSTOMERS_LIST: 'customers-list',
  INVOICES_LIST: 'invoices-list',
  TICKETS_LIST: 'tickets-list',
  FEEDBACKS_LIST: 'feedbacks-list',
  METRICS_LIST: 'metrics-list',
  CERTIFICATES_LIST: 'certificates_list',
  CERTIFICATIONS_LIST: 'certifications_list',
  PAYMENTREQUESTS_LIST: 'paymentrequests_list'
}

const REQUEST_TYPES = {
    1: 'UTILITY',
    2: 'OFFICE REPAIR & MAINTENANCE',
    3: 'OFFICE EXPENSES',
    4: 'LEGAL & PROFESSIONAL FEES',
    5: 'MOTOR REPAIRS & MAINTENANCE',
    6: 'HOSPITALITY',
    7: 'FUEL/DIESEL',
    8: 'TRANSPORTATION',
    9: 'SHIPPING & LOADING',
    10: 'COGS (Cost of Goods Sold)',
    11: 'CABLES',
    12: 'CIRCUIT BREAKER',
    13: 'ALUMINIUM RAIL',
    14: 'PROJECT COST',
    15: 'ADVERT',
    16: 'BANK CHARGES',
    17: 'INTER-COMPANY',
    18: 'PRINTING AND STATIONARIES',
    19: 'DTA (Daily Travel Allowance)',
    20: 'RENEWAL',
    21: 'ACCOMMODATION',
    22: 'SALARY',
    23: 'OFFICE EQUIPMENT',
    24: 'TAXES',
    25: 'INTERNET SUBSCRIPTION',
    26: 'INSURANCE',
    27: 'SALES COMMISSION',
    28: 'AMAC & ENVIRONMENTAL',
    29: 'MEDICAL EXPENSES'
}

const STATES = [
  'Abia', 'Adamawa', 'Akwa Ibom', 'Anambra', 'Bauchi', 'Bayelsa', 'Benue', 'Borno', 'Cross River', 'Delta',
  'Ebonyi', 'Edo', 'Ekiti', 'Enugu', 'Gombe', 'Imo', 'Jigawa', 'Kaduna', 'Kano', 'Katsina', 'Kebbi', 'Kogi',
  'Kwara', 'Lagos', 'Nasarawa', 'Niger', 'Ogun', 'Ondo', 'Osun', 'Oyo', 'Plateau', 'Rivers', 'Sokoto', 'Taraba',
  'Yobe', 'Zamfara',
];

const SKILLS = [
  'Electrical Knowledge', 'Solar Panel Installation', 'Inverter Installation and Configuration', 'System Integration', 'Safety Protocols', 'Usage of Tools', 'Installation Tools and Materials', 'Troubleshooting', 'System Testing and Commissioning', 'Communication', 'Customer Service', 'Teamwork and Collaboration', 'Time Management and Organization', 'Licensing', 'Training and Certification'
]

const ROLES = [
  'SUPER ADMIN','ADMIN','CUSTOMER','INSTALLER'
]

export {QUERIES, STATES, SKILLS, ROLES, REQUEST_TYPES}
