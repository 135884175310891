import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Spinner from './Spinner'


const presetKey = process.env.REACT_APP_CLOUDINARY_PRESET_KEY || 'grb1jhfm';
const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME || 'djlmwp9md';

const AvatarUpload = ({ avatarSrc, onUploadSuccess }) => {
  const [selectedFile, setSelectedFile] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const defaultAvatarSrc = '/media/avatars/avatar.png';

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const result = reader.result as string; // Explicitly type as string
        setSelectedFile(result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleUpload = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!selectedFile) {
      console.error('No file selected for upload');
      return;
    }

    setIsUploading(true);
    const instance = axios.create();

    instance
      .post(
        `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
        {
          file: selectedFile,
          upload_preset: presetKey,
        },
        {
          headers: {
            "Content-Type":
              "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      )
      .then((response) => {
        onUploadSuccess(response.data.secure_url)
        toast.success('Image uploaded successfully')
      })
      .catch((error) => {
        toast.error('Error uploading image')
        console.log(error);
      })
      .finally(() => setIsUploading(false));
  };

  return (
    <>
      <div className="text-center pb-5" style={{ position: 'relative' }}>
        <label htmlFor="fileInput" style={{ cursor: 'pointer' }}>
          <div
            style={{
              position: 'absolute',
              top: '65%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >

            {selectedFile ? (
              <button
                onClick={handleUpload}
                className="btn btn-info btn-sm"
                style={{ marginTop: '70%' }}
              >
                {isUploading ? (
                  <>
                    Uploading <Spinner />
                  </>
                ) : (
                  <>
                    Upload <i className="bi bi-cloud-upload fs-2"></i>
                  </>
                )}
              </button>
            ) : (
              <>
                <i className="bi bi-camera fs-3x text-success"></i>
                <br />
                <span style={{fontWeight: '500'}}>select image</span>
              </>
            )}
          </div>
          <img
            src={selectedFile || avatarSrc || defaultAvatarSrc}
            style={{ borderRadius: '50%' }}
            alt="avatar"
            width="200"
            height="200"
          />
        </label>
        <input
          id="fileInput"
          type="file"
          accept="*"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </div>
    </>
  );
};

export default AvatarUpload;
