import React from 'react';
import { Dialog } from 'primereact/dialog';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';
import RatingStars from '../../../../common/components/RatingStars'

// Import necessary libraries and components

const ReviewsModal = ({ open, onClose, row }) => {

  return (
    <Dialog
      visible={open}
      onHide={onClose}
      header={`Feedback for: ${row.job.title}`}
      style={{ width: '50vw' }}
      breakpoints={{ '960px': '50vw', '641px': '95vw' }}
      footer={
        <div className="d-flex justify-content-end">
          <button className="btn btn-danger btn-sm me-2" onClick={onClose}>
            Close
          </button>
        </div>
      }
    >
      <div className="p-4">
        <h5 className="mb-3">Job Rating</h5>
        <RatingStars totalStars={5} numStars={row.rating} />

        <hr className="my-4" />

        <div className="row">
          <div className="col-md-4">
            <h5>User Information</h5>
            <p>{`Name: ${row.user.firstname} ${row.user.lastname}`}</p>
            <p>{`Username: ${row.user.username}`}</p>
            <p>{`Email: ${row.user.email}`}</p>
          </div>
          <div className="col-md-4">
            <h5>Job Information</h5>
            <p>{`Title: ${row.job.title}`}</p>
            <p>{`Service Type: ${row.job.service_type}`}</p>
            <p>{`Status: ${row.job.status}`}</p>
            {/* Add more job-related information as needed */}
          </div>
          <div className="col-md-4">
            <h5>Installer Information</h5>
            <p>{`Name: ${row.installer.firstname} ${row.installer.lastname}`}</p>
            <p>{`Username: ${row.installer.username}`}</p>
            <p>{`Email: ${row.installer.email}`}</p>
          </div>
        </div>
        <hr className="my-4" />
        <div className="card shadow mb-4">
          <div className="card-header">
            <h5 className="card-title">Comments</h5>
          </div>
          <div className="card-body">
            <p className="card-text">{row.comment}</p>
          </div>
        </div>
      </div>
    </Dialog>
  );
};


export default ReviewsModal;
