import Select from 'react-select';
import { useState, useEffect } from 'react';
import { getAllJobs } from '../../pages/jobs/active/core/_requests';

const Jobs = ({ label, name, defaultValue, formik, isDisabled }) => {
  const [options, setOptions] = useState([]);
  defaultValue = defaultValue.label === undefined || defaultValue.value === '' ? null : defaultValue
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const data = await getAllJobs();
        if (data.data) {
          const options = data.data.map((job) => ({
            label: job.title,
            value: job.id,
          }));
          setOptions(options);
        }
      } catch (error) {
        console.error('Error fetching Jobs:', error);
      }
    };

    fetchJobs();
  }, []);

  return (
    <>
      <div className='mb-10'>
        <label className='form-label'>{label}</label>
        <Select
          className='react-select-styled react-select-solid'
          classNamePrefix='react-select'
          options={options}
          placeholder='Select an option'
          defaultValue={defaultValue ? defaultValue : null} 
          onChange={(selectedOption) => formik.setFieldValue('job', selectedOption.value)}
          isDisabled={isDisabled}
        />
      </div>
    </>
  );
};

export { Jobs };
