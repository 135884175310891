import * as Yup from 'yup'
import {useEffect} from 'react'
import {FC, useState} from 'react'
import {useQuery} from 'react-query'
import { toast } from 'react-toastify';
import {KTIcon, QUERIES, isNotEmpty} from '../../../../../_metronic/helpers'
import {useListView} from '../../../../common/table/core/ListViewProvider'
import {TableListLoader} from '../../../../common/table/components/TableListLoader'
import {useFormik} from 'formik'
import {getJobById, createJob, updateJob} from '../core/_requests'
import {Job} from '../core/_models'
import {useQueryResponse} from '../core/QueryResponseProvider'
import InputField from '../../../../common/components/InputField'
import SelectField from '../../../../common/components/SelectField'
import TextArea from '../../../../common/components/TextArea'
import {Customers} from '../../../../common/components/Customers'
import {Installers} from '../../../../common/components/Installers'
import {States} from '../../../../common/components/States'
import DateTime from '../../../../common/components/DateTime'
import FileUploader from '../../../../common/components/FileUploader'

type Props = {
  isJobLoading: boolean
  job: Job
}

const JobSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(100, 'Maximum 100 symbols')
    .required('Title is required'),
  customer: Yup.string()
    .required('Customer is required'),
  installer: Yup.string()
    .required(),
  service_type: Yup.string()
    .required('Service type is required'),
  state: Yup.string()
    .required('State is required'),
  latitude: Yup.number(),
  longitude: Yup.number(),
  address: Yup.string()
    .required(),
  status: Yup.string()
    .required(),
  cost: Yup.number(),
  scheduled_date: Yup.date()
    .required(),
  completion_date: Yup.date(),
  description: Yup.string()
})

const ModalForm: FC<Props> = ({job, isJobLoading}) => {

  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [invoiceUrl, setInvoiceUrl] = useState(job.invoice_url)

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  const initialValues = {
    id: job.id,
    title: job.title || '',
    customer: job.customer_id || '',
    installer: job.installer_id || '',
    service_type: job.service_type || '',
    state: job.state || '',
    latitude: job.location?.latitude || '',
    longitude: job.location?.longitude || '',
    address: job.location?.address || '',
    status: job.status || '',
    cost: job.cost || '',
    invoice_url: invoiceUrl || '',
    scheduled_date: job.scheduled_date || '',
    completion_date: job.completion_date || '',
    description: job.description || ''
  }

  const [jobForEdit] = useState(initialValues)

  const formik = useFormik({
    initialValues: jobForEdit,
    validationSchema: JobSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      const payload = {
        id: values.id,
        title: values.title,
        state: values.state,
        status: values.status,
        cost: values.cost,
        invoice_url: invoiceUrl,
        customer_id: values.customer,
        installer_id: values.installer,
        description: values.description,
        service_type: values.service_type,
        scheduled_date: values.scheduled_date,
        completion_date: values.completion_date === '' ? null : values.completion_date,
        location: {
          latitude: values.latitude,
          longitude: values.longitude,
          address: values.address 
        }
      }
      try {
        if (isNotEmpty(values.id)) {
          await updateJob(payload)
          toast.success('Record updated successfully');
        } else {
          delete payload['id'];
          await createJob(payload)
          toast.success('Record created successfully');
        }
      } catch (ex) {
        console.error(ex)
        toast.warning('An error occured');
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const handleUploadSuccess = (invoiceUrl) => {
    formik.setFieldValue('file_url', invoiceUrl);
    setInvoiceUrl(invoiceUrl);
  };

  // console.log(formik.values)
  return (
    <>
      <form id='kt_modal_add_job_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_job_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_job_header'
          data-kt-scroll-wrappers='#kt_modal_add_job_scroll'
          data-kt-scroll-offset='300px'
        >

          <div className="row">
            <InputField
              isLoading={isJobLoading}
              formik={formik}
              name="title"
              label="Title"
              placeholder="title"
              type='text'
              columns={6}
            />

            <SelectField
              options={[
                {label: 'Installation', value: 'installation'}, 
                {label: 'Maintenance', value: 'maintenance'},
                {label: 'Cleaning', value: 'cleaning'},
                {label: 'Repair', value: 'repair'},
              ]}
              defaultValue={initialValues.service_type}
              name="service_type"
              columns={6}
              label="Service type"
              formik={formik}
            />
          </div>

          <div className="row" style={{marginBottom: '30px'}}>
            <div className="col-md-6">
              <Customers 
                label="Select customer"
                name="customer"
                formik={formik}
                defaultValue={job.customer
                    ? {
                        label: job.customer.firstname + " " + job.customer.lastname,
                        value: initialValues.customer
                      }
                    : {}
                  }
                />
            </div>

            <div className="col-md-6">
              <Installers 
                label="Select installer"
                name="installer"
                formik={formik}
                defaultValue={job.installer
                    ? {
                        label: job.installer.firstname + " " + job.installer.lastname,
                        value: initialValues.installer
                      }
                    : {}
                  }
                />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <States 
                label="Select state"
                defaultValue={initialValues.state}
                name="state"
                formik={formik}
              />
            </div>

             <InputField
              isLoading={isJobLoading}
              formik={formik}
              name="address"
              label="Address"
              placeholder="Address"
              type='text'
              columns={8}
            />
          </div>

          <div className="row">
            <InputField
              isLoading={isJobLoading}
              formik={formik}
              name="latitude"
              label="Latitude"
              placeholder="Latitude"
              type='text'
              columns={6}
            />
            <InputField
              isLoading={isJobLoading}
              formik={formik}
              name="longitude"
              label="Longitude"
              placeholder="Longitude"
              type='text'
              columns={6}
            />
          </div>

          <div className="row">
            <InputField
              isLoading={isJobLoading}
              formik={formik}
              name="cost"
              label="Cost"
              placeholder="Cost"
              type='number'
              columns={6}
            />

            <SelectField
              options={[
                {label: 'Initiated', value: 'initiated'},
                {label: 'Pending', value: 'pending'}, 
                {label: 'Completed', value: 'completed'}, 
              ]}
              defaultValue={initialValues.status}
              name="status"
              columns={6}
              label="Status"
              formik={formik}
            />
          </div>

          <div className="row">
            <DateTime
              defaultValue={initialValues.scheduled_date}
              columns={6}
              label="Scheduled date"
              name="scheduled_date"
              formik={formik}
              isLoading={isJobLoading}
            />

            <DateTime
              defaultValue={initialValues.completion_date}
              columns={6}
              label="Completion date"
              name="completion_date"
              formik={formik}
              isLoading={isJobLoading} 
            />
          </div>

          <div className="row">
            {/*<div className={`col-md-${12}`}>
              <div className="mb-10">
                <label className="form-label">Description</label>
                <textarea className="form-control" {...formik.getFieldProps('description')}>
                </textarea>
              </div>
            </div>*/}
            <TextArea
              isLoading={isJobLoading}
              formik={formik}
              name="description"
              label="Description"
              placeholder="Description"
              columns={12}
            />

          </div>

          <div className="row mb-10">
            {invoiceUrl ? (<>
              <div className="text-center p-5 mt-5" style={{ position: 'relative'}}>
                  <div
                    className="border border-gray-300 p-5"
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: '80%',
                      height: '6vh'
                    }}
                  >
                    <a href={invoiceUrl} target="_blank" rel="noreferrer" className="fs-6 fw-bold">View Invoice</a>
                </div>
              </div>
            </>) : (
              <FileUploader onUploadSuccess={handleUploadSuccess}/>
            )}
          </div>
          
          
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-jobs-modal-action='cancel'
            disabled={formik.isSubmitting || isJobLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-jobs-modal-action='submit'
            disabled={isJobLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isJobLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isJobLoading) && <TableListLoader />}
    </>
  )
}

const ModalHeader = ({title}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>{itemIdForUpdate ? `Edit ${title}` : `Add ${title}`}</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-jobs-modal-action='close'
        onClick={() => setItemIdForUpdate(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

const ModalWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: job,
    error,
  } = useQuery(
    `${QUERIES.JOBS_LIST}-job-${itemIdForUpdate}`,
    () => {
      return getJobById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <ModalForm isJobLoading={isLoading} job={{id: undefined}} />
  }

  if (!isLoading && !error && job) {
    return <ModalForm isJobLoading={isLoading} job={job} />
  }

  return null
}


const JobModal = ({title}) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_job'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-900px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <ModalHeader title={title}/>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-10 my-2'>
              <ModalWrapper />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

const AddJobButton = () => {
  const {setItemIdForUpdate} = useListView()

  const openAddJobModal = () => {
    setItemIdForUpdate(null)
  }

  return (
      <button type='button' className='btn btn-primary' onClick={openAddJobModal}>
        <KTIcon iconName='plus' className='fs-2' />
        Add Job
      </button>
  )
}

export {AddJobButton, JobModal}