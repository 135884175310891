import Select from 'react-select';
// import { ROLES } from '../../../_metronic/helpers';
import { useState, useEffect } from 'react';
import { getAllRoles } from '../../pages/roles/core/_requests';

// for now dummy roles

const Roles = ({ label, name, defaultValue, formik }) => {
  const [options, setOptions] = useState([]);
  
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const data = await getAllRoles();
        if(data.data){
            const options = data.data.map((role) => ({ label: role.name, value: role.name }));
            setOptions(options)
        }
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };

    fetchRoles();
  }, []);

  return (
    <>
      <div className='mb-10'>
        <label className='form-label'>Roles</label>
        <Select
          className='react-select-styled react-select-solid'
          classNamePrefix='react-select'
          options={options}
          placeholder='Select an option'
          onChange={(role) => {
            const roles = role.map(role => role['value'])
            formik.setFieldValue('roles', roles)
          }}
          defaultValue={defaultValue}
          isMulti
        />
      </div>
    </>
  );
};

export { Roles };
