// @ts-nocheck
import {FC} from 'react'
import clsx from 'clsx'
import {Column} from 'react-table'
import {ActionsCell} from './ActionsCell'
import {TableCustomHeader} from '../../../../common/table/columns/TableCustomHeader'
import {TableSelectionHeader} from '../../../../common/table/columns/TableSelectionHeader'
import {TableSelectionCell} from '../../../../common/table/columns/TableSelectionCell'
import {Installer} from '../../../users/installers/core/_models'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {deleteMetric} from '../core/_requests'
import {QUERIES, getInitials, getRandomColor} from '../../../../../_metronic/helpers'
import RatingStars from '../../../../common/components/RatingStars'

type Props = {
  installer: Installer
}

const MetricInfoCell: FC<Props> = ({ installer }) => {

  const initials = getInitials(installer.user.firstname, installer.user.lastname);
  const randomColor = getRandomColor();

  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <a href='/metrics'>
          {installer.user.avatar_url ? (
            <div className='symbol-label'>
              <img src={`${installer.user.avatar_url}`} alt={`${installer.user.firstname} ${installer.user.lastname}`} className='w-100' />
            </div>
          ) : (
            <div
              className={clsx(
                'symbol-label fs-3',
                `bg-light${randomColor}`,
                `text-${randomColor}`
              )}
            >
              {initials}
            </div>
          )}
        </a>
      </div>
      <div className='d-flex flex-column'>
        <a href='/metrics' className='text-gray-800 text-hover-primary mb-1'>
          {installer.user.firstname} {installer.user.lastname}
        </a>
        <span>{installer.user.email}</span>
      </div>
    </div>
  );
};

const JobsCount: FC<Props> = ({installer}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
        {installer.jobs?.length}
    </div>
  </div>
)

const RatingCell: FC<Props> = ({installer}) => { 
  const ratingAvg = installer.details?.rating_average ?? 0
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <RatingStars totalStars={5} numStars={ratingAvg}/>
      </div>
    </div>
  )
}



const metricsColumns: ReadonlyArray<Column<installer>> = [
  {
    Header: (props) => <TableSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <TableSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({ ...props }) => <MetricInfoCell installer={props.data[props.row.index]} fieldName='name' />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Jobs' className='min-w-125px' />,
    id: 'jobs',
    Cell: ({ ...props }) => <JobsCount installer={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Rating average' className='min-w-125px' />,
    id: 'rating',
    Cell: ({ ...props }) => <RatingCell installer={props.data[props.row.index]}/>,
  },
  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ActionsCell row={props.data[props.row.index]} id={props.data[props.row.index].id} queryList={QUERIES.METRICS_LIST} useQueryResponse={useQueryResponse} deleteEntity={deleteMetric} />,
  },
]

export {metricsColumns}
