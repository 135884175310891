import axios, {AxiosResponse} from 'axios'
import {ID, Response, processQueryString} from '../../../../../_metronic/helpers'
import {Invoice, InvoicesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const INVOICE_URL = `${API_URL}/invoices`
const GET_INVOICES_URL = `${API_URL}/invoices`
const PAYMENT_URL = `${API_URL}/payments`

const getInvoices = (query: string): Promise<InvoicesQueryResponse> => {
  const filters = processQueryString(query);
  return axios
    .get(`${GET_INVOICES_URL}?${filters}`)
    .then((d: AxiosResponse<InvoicesQueryResponse>) => {
      return d.data
    })
}

const getAllInvoices = () => {

  return axios
    .get(`${GET_INVOICES_URL}`)
    .then((d) => d.data)
}

const getInvoiceById = (id): Promise<Invoice | undefined> => {
  return axios
    .get(`${INVOICE_URL}/${id}`)
    .then((response: AxiosResponse<Response<Invoice>>) => response.data)
    .then((response: Response<Invoice>) => response.data)
}

const getPaymentById = (id) => {
  return axios
    .get(`${PAYMENT_URL}/${id}`)
    .then((response) => response.data)
    .then((response) => response.data)
}

const createInvoice = (invoice): Promise<Invoice | undefined> => {
  return axios
    .post(INVOICE_URL, invoice)
    .then((response: AxiosResponse<Response<Invoice>>) => response.data)
    .then((response: Response<Invoice>) => response.data)
}

const createPayment = (payment) => {
  return axios
    .post(PAYMENT_URL, payment)
    .then((response) => response.data)
    .then((response) => response.data)
}

const updateInvoice = (invoice): Promise<Invoice | undefined> => {
  return axios
    .put(`${INVOICE_URL}/${invoice.id}`, invoice)
    .then((response: AxiosResponse<Response<Invoice>>) => response.data)
    .then((response: Response<Invoice>) => response.data)
}

const updatePayment = (payment) => {
  return axios
    .put(`${PAYMENT_URL}/${payment.id}`, payment)
    .then((response) => response.data)
    .then((response) => response.data)
}

const deleteInvoice = (invoiceId: ID): Promise<void> => {
  return axios.delete(`${INVOICE_URL}/${invoiceId}`).then(() => {})
}

// const deleteSelectedInvoices = () => {}
const deleteSelectedInvoices = (invoiceIds: Array<ID>): Promise<void> => {
  const requests = invoiceIds.map((id) => axios.delete(`${INVOICE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getInvoices,
  getAllInvoices, 
  getInvoiceById, 
  createInvoice, 
  updateInvoice, 
  deleteInvoice,
  createPayment,
  updatePayment,
  getPaymentById,
  deleteSelectedInvoices
}