import axios, {AxiosResponse} from 'axios'
import {ID, Response, processQueryString} from '../../../../../_metronic/helpers'
import {Certificate, CertificatesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const CERTIFICATE_URL = `${API_URL}/certificates`
const GET_CERTIFICATES_URL = `${API_URL}/certificates`

const getCertificates = (query: string): Promise<CertificatesQueryResponse> => {
  const filters = processQueryString(query);

  return axios
    .get(`${GET_CERTIFICATES_URL}?${filters}`)
    .then((d: AxiosResponse<CertificatesQueryResponse>) => {
      return d.data
    })
}

const getAllCertificates = () => {

  return axios
    .get(`${GET_CERTIFICATES_URL}`)
    .then((d) => d.data)
}

const getCertificateById = (id: ID): Promise<Certificate | undefined> => {
  return axios
    .get(`${CERTIFICATE_URL}/${id}`)
    .then((response: AxiosResponse<Response<Certificate>>) => response.data)
    .then((response: Response<Certificate>) => response.data)
}

const createCertificate = (certificate): Promise<Certificate | undefined> => {
  return axios
    .post(CERTIFICATE_URL, certificate)
    .then((response: AxiosResponse<Response<Certificate>>) => response.data)
    .then((response: Response<Certificate>) => response.data)
}

const updateCertificate = (certificate): Promise<Certificate | undefined> => {
  return axios
    .put(`${CERTIFICATE_URL}/${certificate.id}`, certificate)
    .then((response: AxiosResponse<Response<Certificate>>) => response.data)
    .then((response: Response<Certificate>) => response.data)
}

const deleteCertificate = (certificateId: ID): Promise<void> => {
  return axios.delete(`${CERTIFICATE_URL}/${certificateId}`).then(() => {})
}

// const deleteSelectedCertificates = () => {}
const deleteSelectedCertificates = (certificateIds: Array<ID>): Promise<void> => {
  const requests = certificateIds.map((id) => axios.delete(`${CERTIFICATE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getCertificates, getAllCertificates, getCertificateById, createCertificate, updateCertificate, deleteCertificate, deleteSelectedCertificates}