import {ListViewProvider, useListView} from '../../../common/table/core/ListViewProvider'
import {QueryRequestProvider} from '../../../common/table/core/QueryRequestProvider'
import {QueryResponseProvider, useQueryResponse, useQueryResponseData} from './core/QueryResponseProvider'
import {InstallersListHeader} from './components/InstallersListHeader'
import {InstallersTable} from './table/InstallersTable'
import {KTCard} from '../../../../_metronic/helpers'
import {InstallerModal} from  './components/InstallerModal'

const InstallersList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <InstallersListHeader />
        <InstallersTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <InstallerModal title="installer"/>}
    </>
  );
}

const InstallersListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider
        useQueryResponse={useQueryResponse}
        useQueryResponseData={useQueryResponseData}
      >
        <InstallersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {InstallersListWrapper}
