import {useListView} from '../../../../common/table/core/ListViewProvider'
import {TableListToolbar} from '../../../../common/table/components/TableListToolbar'
import {TableListSearchComponent} from '../../../../common/table/components/TableListSearchComponent'
import {TableListGrouping} from '../../../../common/table/components/TableListGrouping'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {deleteSelectedTickets} from '../core/_requests'
import {TicketsListFilter} from './TicketsListFilter'
import {Export} from './Export'
import {QUERIES} from '../../../../../_metronic/helpers'

const TicketsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <TableListSearchComponent placeholder="Search for ticket" />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {
          selected.length > 0 ? <TableListGrouping queryList={QUERIES.TICKETS_LIST} useQueryResponse={useQueryResponse} deleteSelectedEntity={deleteSelectedTickets} />
          : (
              <TableListToolbar>
                <TicketsListFilter />
                <Export />
              </TableListToolbar>
            )
        }
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {TicketsListHeader}
