import React from 'react';

interface RatingProps {
  numStars: number;
  totalStars: number;
}

const RatingStars: React.FC<RatingProps> = ({ totalStars, numStars }) => {
  const generateStars = () => {
    const stars: React.ReactNode[] = [];

    for (let i = 0; i < totalStars; i++) {
      const starClassName = i < numStars ? 'checked' : '';
      stars.push(
        <div key={i} className={`rating-label me-2 ${starClassName}`}>
          <i className="bi bi-star fs-1"></i>
        </div>
      );
    }
    return stars;
  };

  return (
    <div className="rating">
      {generateStars()}
    </div>
  );
};

export default RatingStars;
