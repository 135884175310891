import React from "react";
import {TicketsListWrapper} from './TicketsList'
import {PageTitle} from '../../../../_metronic/layout/core'

const ticketsBreadcrumbs = [
  {
    title: '',
    path: '/support/tickets',
    isSeparator: false,
    isActive: false,
  },
]

export default function TicketsPage() {
    return (
        <>
            <PageTitle breadcrumbs={ticketsBreadcrumbs} description='Manage tickets'>
                Support Tickets
            </PageTitle>
            <TicketsListWrapper />
        </>
    )
}