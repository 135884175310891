import "flatpickr/dist/themes/material_green.css";

import Flatpickr from "react-flatpickr";
import {useState} from  "react"

function convertToDateString(datetimeString) {
  const date = new Date(datetimeString);

  // Get the date components
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // add 1
  const day = date.getDate();

  // Construct the date string in "YYYY-MM-DD" format
  const dateString = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;

  return dateString;
}

const DatePicker = ({defaultValue, columns, label, name, formik, isLoading}) => {
  const [dateState, setDateState] = useState<any>({
    date: new Date()
  });
  return <>
    <div className={`col-md-${columns}`}>
      <div className="mb-10">
        <label className="form-label">{label}</label>
        <Flatpickr
          value={defaultValue}
          onChange={([date]) => {
            setDateState({ date });
            formik.setFieldValue(name, convertToDateString(date))
          }}
          options={{
            enableTime: false,
            dateFormat: "Y-m-d"
          }}
          className='form-control'
          placeholder='Pick date'
          disabled={isLoading}
        />
      </div>
    </div>
  </>
}

export default DatePicker