import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Spinner from './Spinner'
import {renamePdfToJpg} from '../../../_metronic/helpers'


const presetKey = process.env.REACT_APP_CLOUDINARY_PRESET_KEY || 'grb1jhfm';
const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME || 'djlmwp9md';

const FileUploader = ({ onUploadSuccess }) => {
    const [selectedFile, setSelectedFile] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [filename, setFilename] = useState('')

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      setFilename(file.name)
      reader.onloadend = () => {
        const result = reader.result as string; // Explicitly type as string
        setSelectedFile(result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleUpload = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!selectedFile) {
      console.error('No file selected for upload');
      return;
    }

    setIsUploading(true);
    const instance = axios.create();

    instance
      .post(
        `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
        {
          file: selectedFile,
          upload_preset: presetKey,
        },
        {
          headers: {
            "Content-Type":
              "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      )
      .then((response) => {
        onUploadSuccess(renamePdfToJpg(response.data.secure_url))
        toast.success('File uploaded successfully')
      })
      .catch((error) => {
        toast.error('Error uploading file')
        console.log(error);
      })
      .finally(() => {
       setIsUploading(false)
      });
  };
    
    return (
        <div className="text-center pb-5 mt-5" style={{ position: 'relative'}}>
          <label htmlFor="fileInput" style={{ cursor: 'pointer' }}>
            <div
              className="border border-gray-300 p-5"
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                height: '10vh'
              }}
            >
              {selectedFile ? (
                <button
                  onClick={handleUpload}
                  className="btn btn-info btn-sm"
                  disabled={isUploading}
                >
                  {isUploading ? (
                    <>
                      Uploading <Spinner />
                    </>
                  ) : (
                    <>
                      {filename} Upload <i className="bi bi-cloud-upload fs-1"></i>
                    </>
                  )}
                </button>
              ) : (
                <>
                  <i className="bi bi-file-earmark-plus fs-3x text-success"></i>
                  <br />
                  <span style={{ fontWeight: '500' }}>Select file</span>
                </>
              )}
            </div>
          </label>
          <input
            id="fileInput"
            type="file"
            accept="image/*, application/pdf"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </div>
      );
}

export default FileUploader;