import axios, {AxiosResponse} from 'axios'
import {ID, Response, processQueryString} from '../../../../_metronic/helpers'
import {Role, RolesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const ROLE_URL = `${API_URL}/rbac/roles`
const GET_ROLES_URL = `${API_URL}/rbac/roles`
const GET_PERMS_URL = `${API_URL}/rbac/permissions`

const getRoles = (query: string): Promise<RolesQueryResponse> => {
  const filters = processQueryString(query);

  return axios
    .get(`${GET_ROLES_URL}?${filters}`)
    .then((d: AxiosResponse<RolesQueryResponse>) => d.data)
}

const getAllRoles = () => {

  return axios
    .get(`${GET_ROLES_URL}`)
    .then((d) => d.data)
}

const getAllPermissions = () => {

  return axios
    .get(`${GET_PERMS_URL}`)
    .then((d) => d.data)
}

const getRoleById = (id: ID): Promise<Role | undefined> => {
  return axios
    .get(`${ROLE_URL}/${id}`)
    .then((response: AxiosResponse<Response<Role>>) => response.data)
    .then((response: Response<Role>) => response.data)
}

const createRole = (role: Role): Promise<Role | undefined> => {
  return axios
    .post(ROLE_URL, role)
    .then((response: AxiosResponse<Response<Role>>) => response.data)
    .then((response: Response<Role>) => response.data)
}

const updateRole = (role: Role): Promise<Role | undefined> => {
  return axios
    .put(`${ROLE_URL}/${role.id}`, role)
    .then((response: AxiosResponse<Response<Role>>) => response.data)
    .then((response: Response<Role>) => response.data)
}

const deleteRole = (roleId: ID): Promise<void> => {
  return axios.delete(`${ROLE_URL}/${roleId}`).then(() => {})
}

// const deleteSelectedRoles = () => {}
const deleteSelectedRoles = (roleIds: Array<ID>): Promise<void> => {
  const requests = roleIds.map((id) => axios.delete(`${ROLE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getRoles, getAllRoles, getAllPermissions, getRoleById, createRole, updateRole, deleteRole, deleteSelectedRoles}