import React from "react";
import {PaymentrequestsListWrapper} from './PaymentRequestsHistoryList'
import {PageTitle} from '../../../../_metronic/layout/core'

const paymentrequestsBreadcrumbs = [
  {
    title: '',
    path: '/finance/payment-requests',
    isSeparator: false,
    isActive: false,
  },
]

export default function PaymentRequestsHistoryPage() {
    return (
        <>
            <PageTitle breadcrumbs={paymentrequestsBreadcrumbs} description='Manage payment requests'>
                Payment requests
            </PageTitle>
            <PaymentrequestsListWrapper />
        </>
    )
}