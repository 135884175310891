import * as Yup from 'yup'
import {useEffect} from 'react'
import {FC, useState} from 'react'
import {useQuery} from 'react-query'
import { toast } from 'react-toastify';
import {KTIcon, QUERIES, isNotEmpty} from '../../../../../_metronic/helpers'
import {useListView} from '../../../../common/table/core/ListViewProvider'
import {TableListLoader} from '../../../../common/table/components/TableListLoader'
import {useFormik} from 'formik'
import {getPaymentrequestById, createPaymentrequest, updatePaymentrequest} from '../core/_requests'
import {Paymentrequest} from '../core/_models'
import {useQueryResponse} from '../core/QueryResponseProvider'
import InputField from '../../../../common/components/InputField'
import TextArea from '../../../../common/components/TextArea'
import DatePicker from '../../../../common/components/DatePicker'
import SelectField from '../../../../common/components/SelectField'
import {RequestTypes} from '../../../../common/components/RequestTypes'
import {useAuth} from '../../../../modules/auth/core/Auth'

type Props = {
  isPaymentrequestLoading: boolean
  paymentrequest: Paymentrequest
}

const PaymentrequestSchema = Yup.object().shape({
  request_type: Yup.number().required('Request type is required'),
  justification: Yup.string().required('Justifation is required'),
  date_needed: Yup.date().required('Use date is required'),
  amount_requested: Yup.number().required('Amount is required'),
  supporting_documents: Yup.string()
})

const ModalForm: FC<Props> = ({paymentrequest, isPaymentrequestLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  const initialValues = {
    id: paymentrequest.id,
    request_type: paymentrequest.request_type || '',
    justification: paymentrequest.justification || '',
    date_needed: paymentrequest.date_needed || '',
    amount_requested: paymentrequest.amount_requested || ''
  }

  const [paymentrequestForEdit] = useState(initialValues)

  const formik = useFormik({
    initialValues: paymentrequestForEdit,
    validationSchema: PaymentrequestSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      const payload = {
        id: values.id,
        request_type: values.request_type,
        justification: values.justification,
        date_needed: values.date_needed,
        amount_requested: values.amount_requested,
        user_id: paymentrequest.user_id
      }
      try {
        if (isNotEmpty(values.id)) {
          console.log(payload)
          await updatePaymentrequest(payload)
          toast.success('Record updated successfully');
        } else {
          delete payload['id'];
          await createPaymentrequest(payload)
          toast.success('Record created successfully');
        }
      } catch (ex) {
        console.error(ex)
        toast.warning('An error occured');
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })
  
  return (
    <>
      <form id='kt_modal_add_paymentrequest_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_paymentrequest_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_paymentrequest_header'
          data-kt-scroll-wrappers='#kt_modal_add_paymentrequest_scroll'
          data-kt-scroll-offset='300px'
        >

          <div className="row">
            <div className="col-md-12">
              <RequestTypes 
                label="Select request type"
                defaultValue={initialValues.request_type}
                name="request_type"
                formik={formik}
              />
            </div>
          </div>

          <div className="row">
            <InputField
              isLoading={isPaymentrequestLoading}
              formik={formik}
              name="amount_requested"
              label="Amount"
              placeholder="Amount"
              type='number'
              columns={12}
            />
          </div>
          
          <div className="row">
            <DatePicker
              defaultValue={initialValues.date_needed}
              columns={12}
              label="Use date"
              name="date_needed"
              formik={formik} 
              isLoading={isPaymentrequestLoading}
            />
          </div>

          <div className="row">
            <TextArea
              isLoading={isPaymentrequestLoading}
              formik={formik}
              name="justification"
              label="Justification"
              placeholder="Justification"
              columns={12}
            />

          </div>

        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-paymentrequests-modal-action='cancel'
            disabled={formik.isSubmitting || isPaymentrequestLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-paymentrequests-modal-action='submit'
            disabled={isPaymentrequestLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isPaymentrequestLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isPaymentrequestLoading) && <TableListLoader />}
    </>
  )
}

const ModalHeader = ({title}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>{itemIdForUpdate ? `Edit ${title}` : `Add ${title}`}</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-paymentrequests-modal-action='close'
        onClick={() => setItemIdForUpdate(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

const ModalWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: paymentrequest,
    error,
  } = useQuery(
    `${QUERIES.PAYMENTREQUESTS_LIST}-paymentrequest-${itemIdForUpdate}`,
    () => {
      return getPaymentrequestById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <ModalForm isPaymentrequestLoading={isLoading} paymentrequest={{id: undefined}} />
  }

  if (!isLoading && !error && paymentrequest) {
    return <ModalForm isPaymentrequestLoading={isLoading} paymentrequest={paymentrequest} />
  }

  return null
}


const PaymentrequestModal = ({title}) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_paymentrequest'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-900px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <ModalHeader title={title}/>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-10 my-2'>
              <ModalWrapper />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

const AddPaymentrequestButton = () => {
  const {setItemIdForUpdate} = useListView()

  const openAddPaymentrequestModal = () => {
    setItemIdForUpdate(null)
  }

  return (
      <button type='button' className='btn btn-primary' onClick={openAddPaymentrequestModal}>
        <KTIcon iconName='plus' className='fs-2' />
        Add Paymentrequest
      </button>
  )
}

export {AddPaymentrequestButton, PaymentrequestModal}