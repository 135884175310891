/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {useThemeMode} from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import { ProgressSpinner } from 'primereact/progressspinner';



const JobStatusSummary = ({className, chartHeight, chartColor, isLoading, stats}) => {
  const {mode} = useThemeMode()

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Jobs Summary</span>

          <span className='text-muted fw-semibold fs-7'>Jobs statistics by status</span>
        </h3>

        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          {/*<Dropdown1 />*/}
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}

      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '30vh', // Adjust height as needed
          }}
        >
          <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="5" />
        </div>
      ) : (
        <>
          {/* begin::Body */}
          <div className='card-body p-0 d-flex flex-column'>
            {/* begin::Stats */}
            <div className='card-p pt-5 bg-body flex-grow-1'>

              {/* begin::Row */}
              <div className='row g-0'>
                {/* begin::Col */}
                <div className='col mr-8'>
                  {/* begin::Label */}
                  <div className='fs-3 mb-5 text-muted fw-semibold text-center'>Total Jobs</div>
                  {/* end::Label */}

                  {/* begin::Stat */}
                  <div className='d-flex align-items-center justify-content-center'>
                    <div className="symbol symbol-70px">
                      <div className="symbol-label fs-2 fw-bold">{stats?.total_jobs_count}</div>
                    </div>
                  </div>
                  {/* end::Stat */}
                </div>
                {/* end::Col */}

                {/* begin::Col */}
                <div className='col mr-8'>
                  {/* begin::Label */}
                  <div className='fs-3 mb-5 text-muted fw-semibold text-center'>Completed</div>
                  {/* end::Label */}

                  {/* begin::Stat */}
                  <div className='d-flex align-items-center justify-content-center'>
                    <div className="symbol symbol-70px">
                      <div className="symbol-label fs-2 fw-bold">{stats?.status_statistics?.completed_count}</div>
                    </div>
                  </div>
                  {/* end::Stat */}
                </div>
                {/* end::Col */}
              </div>
              {/* end::Row */}

              {/* begin::Row */}
              <div className='row g-0 mt-10'>
                {/* begin::Col */}
                <div className='col mr-8'>
                  {/* begin::Label */}
                  <div className='fs-3 mb-5 text-muted fw-semibold text-center'>Pending</div>
                  {/* end::Label */}

                  {/* begin::Stat */}
                  <div className='d-flex align-items-center justify-content-center'>
                    <div className="symbol symbol-70px">
                      <div className="symbol-label fs-2 fw-bold">{stats?.status_statistics?.pending_count}</div>
                    </div>
                  </div>
                  {/* end::Stat */}
                </div>
                {/* end::Col */}

                {/* begin::Col */}
                <div className='col mr-8'>
                  {/* begin::Label */}
                  <div className='fs-3 mb-5 text-muted fw-semibold text-center'>Initiated</div>
                  {/* end::Label */}

                  {/* begin::Stat */}
                  <div className='d-flex align-items-center justify-content-center'>
                    <div className="symbol symbol-70px">
                      <div className="symbol-label fs-2 fw-bold">{stats?.status_statistics?.initiated_count}</div>
                    </div>
                  </div>
                  {/* end::Stat */}
                </div>
                {/* end::Col */}
              </div>
              {/* end::Row */}

              
              
            </div>
            {/* end::Stats */}

          </div>
          {/* end::Body */}
        </>
      )}
    </div>
  )
}


export {JobStatusSummary}
