import clsx from 'clsx';
import * as Yup from 'yup'
import {useEffect} from 'react'
import {FC, useState} from 'react'
import {useQuery} from 'react-query'
import { toast } from 'react-toastify';
import {KTIcon, QUERIES, isNotEmpty} from '../../../../../_metronic/helpers'
import {useListView} from '../../../../common/table/core/ListViewProvider'
import {TableListLoader} from '../../../../common/table/components/TableListLoader'
import {useFormik} from 'formik'
import {getInvoiceById, createInvoice, updateInvoice} from '../core/_requests'
import {Invoice} from '../core/_models'
import {useQueryResponse} from '../core/QueryResponseProvider'
import InputField from '../../../../common/components/InputField'
import {Jobs} from '../../../../common/components/Jobs'
import {Customers} from '../../../../common/components/Customers'
import DatePicker from '../../../../common/components/DatePicker'
import SelectField from '../../../../common/components/SelectField'


type Props = {
  isInvoiceLoading: boolean
  invoice: Invoice
}

const InvoiceSchema = Yup.object().shape({
  job: Yup.string()
    .required('Job is required'),
  customer: Yup.string()
    .required('Customer is required'),
  issue_date: Yup.date()
    .required('Issue date is required'),
  total_amount: Yup.number()
    .required('Total amount is required'),
  invoice_no: Yup.string()
})

const ModalForm: FC<Props> = ({invoice, isInvoiceLoading}) => {

  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  const initialValues = {
    id: invoice.id,
    invoice_no: invoice.invoice_no,
    job: invoice.job_id || '',
    customer: invoice.customer_id || '',
    issue_date: invoice.issueDate || null,
    due_date: invoice.dueDate || null,
    paid_date: invoice.paidDate || null,
    status: invoice.status || 'pending',
    total_amount: invoice.total_amount || '',
  }

  const [invoiceForEdit] = useState(initialValues)

  const formik = useFormik({
    initialValues: invoiceForEdit,
    validationSchema: InvoiceSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      const payload = {
        id: values.id,
        invoice_no: values.invoice_no,
        job_id: values.job,
        customer_id: values.customer,
        issue_date: values.issue_date,
        due_date: values.due_date,
        paid_date: values.paid_date,
        status: values.status,
        total_amount:values.total_amount
      }
      try {
        if (isNotEmpty(values.id)) {
          console.log(payload)
          await updateInvoice(payload)
          toast.success('Record updated successfully');
        } else {
          delete payload['id'];
          await createInvoice(payload)
          toast.success('Record created successfully');
        }
      } catch (ex) {
        console.error(ex)
        toast.warning('An error occured');
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_invoice_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_invoice_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_invoice_header'
          data-kt-scroll-wrappers='#kt_modal_add_invoice_scroll'
          data-kt-scroll-offset='300px'
        >

        <div className="row">
          <div className="col-md-6">
            <div className="mb-10">
              <label className="form-label">Number</label>
              <input
                type='text'
                {...formik.getFieldProps('invoice_no')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.invoice_no && formik.errors.invoice_no},
                  {'is-valid': formik.touched.invoice_no && !formik.errors.invoice_no}
                )}
                placeholder="Number"
                name="invoice_no"
                autoComplete='off'
              />
              {formik.errors.invoice_no && typeof formik.errors.invoice_no === 'string' && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.invoice_no}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="col-md-6">
            <Jobs 
              label="Select job"
              name="job"
              formik={formik}
              isDisabled={false}
              defaultValue={invoice.job
                  ? {
                      label: invoice.job.title,
                      value: initialValues.job
                    }
                  : {}
                }
              />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <Customers 
              label="Select customer"
              name="customer"
              formik={formik}
              defaultValue={invoice.customer
                  ? {
                      label: invoice.customer.firstname + " " + invoice.customer.lastname,
                      value: initialValues.customer
                    }
                  : {}
                }
              />
          </div>
          <DatePicker
            defaultValue={initialValues.issue_date}
            columns={6}
            label="Issued on"
            name="issue_date"
            formik={formik}
            isLoading={isInvoiceLoading}
          />
        </div>

        <div className="row">
          <DatePicker
            defaultValue={initialValues.due_date}
            columns={6}
            label="Due date"
            name="due_date"
            formik={formik} 
            isLoading={isInvoiceLoading}
          />

          <InputField
            isLoading={isInvoiceLoading}
            formik={formik}
            name="total_amount"
            label="Total amount"
            placeholder="Total amount"
            type='number'
            columns={6}
          />
        </div>

         <div className="row">

            <SelectField
              options={[
                  { label: 'Pending', value: 'pending' },
                  ...(invoice?.job?.status !== 'pending' ? [{ label: 'Paid', value: 'paid' }] : [])
              ]}
              defaultValue={initialValues.status}
              name="status"
              columns={6}
              label="Status"
              formik={formik}
            />

            {formik.values.status === 'paid' && (
              <DatePicker
                defaultValue={initialValues.paid_date}
                columns={6}
                label="Paid on"
                name="paid_date"
                formik={formik} 
                isLoading={isInvoiceLoading}
              />
            )}
          </div>
          
          
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-invoices-modal-action='cancel'
            disabled={formik.isSubmitting || isInvoiceLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-invoices-modal-action='submit'
            disabled={isInvoiceLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isInvoiceLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isInvoiceLoading) && <TableListLoader />}
    </>
  )
}

const ModalHeader = ({title}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>{itemIdForUpdate ? `Edit ${title}` : `Add ${title}`}</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-invoices-modal-action='close'
        onClick={() => setItemIdForUpdate(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

const ModalWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: invoice,
    error,
  } = useQuery(
    `${QUERIES.INVOICES_LIST}-invoice-${itemIdForUpdate}`,
    () => {
      return getInvoiceById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <ModalForm isInvoiceLoading={isLoading} invoice={{id: undefined}} />
  }

  if (!isLoading && !error && invoice) {
    return <ModalForm isInvoiceLoading={isLoading} invoice={invoice} />
  }

  return null
}


const InvoiceModal = ({title}) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_invoice'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-900px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <ModalHeader title={title}/>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-10 my-2'>
              <ModalWrapper />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

const AddInvoiceButton = () => {
  const {setItemIdForUpdate} = useListView()

  const openAddInvoiceModal = () => {
    setItemIdForUpdate(null)
  }

  return (
      <button type='button' className='btn btn-primary' onClick={openAddInvoiceModal}>
        <KTIcon iconName='plus' className='fs-2' />
        Add Invoice
      </button>
  )
}

export {AddInvoiceButton, InvoiceModal}