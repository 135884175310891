import { FC, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { MenuTestPage } from "../pages/MenuTestPage";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import BuilderPageWrapper from "../pages/layout-builder/BuilderPageWrapper";
import RolesPage from "../pages/roles/RolesPage";

// users
import InstallersPage from "../pages/users/installers/InstallersPage";
import UsersPage from "../pages/users/admins/UsersPage";
import CustomersPage from "../pages/users/customers/CustomersPage";
import UserProfilePage from '../pages/users/UserProfilePage';
import InstallerViewPage from "../pages/users/installers/InstallerViewPage";
import CustomerViewPage from "../pages/users/customers/CustomerViewPage";
import UserViewPage from "../pages/users/admins/UserViewPage";

// jobs
import JobsPage from "../pages/jobs/active/JobsPage";
import JobsHistoryPage from "../pages/jobs/history/JobsHistoryPage";
import JobHistoryViewPage from "../pages/jobs/history/JobHistoryViewPage";


//finance
import InvoicesPage from "../pages/finance/invoices/InvoicesPage";
import ReportsPage from "../pages/finance/reports/ReportsPage";
import InvoiceViewPage from "../pages/finance/invoices/InvoiceViewPage";
import { FinanceOverviewWrapper } from "../pages/finance/overview/FinanceOverview";
import PaymentRequestsPage from "../pages/finance/requests/PaymentRequestsPage";
import PaymentRequestsHistoryPage from "../pages/finance/payments/PaymentRequestsHistoryPage";

// support
import TicketsPage from "../pages/support/tickets/TicketsPage";
import FeedbacksPage from "../pages/support/feedbacks/FeedbacksPage";



// coordination
import MetricsPage from "../pages/coordination/metrics/MetricsPage";
import CertificatesPage from "../pages/coordination/certificates/CertificatesPage";
import CertificationsPage from "../pages/coordination/certifications/CertificationsPage";
import SchedulerPage from "../pages/coordination/scheduler/SchedulerPage";

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import("../_delete/profile/ProfilePage"));
  const WizardsPage = lazy(() => import("../_delete/wizards/WizardsPage"));
  const AccountPage = lazy(() => import("../_delete/accounts/AccountPage"));
  const WidgetsPage = lazy(() => import("../_delete/widgets/WidgetsPage"));
  const ChatPage = lazy(() => import("../_delete/apps/chat/ChatPage"));
  // const UsersPage = lazy(
  //   () => import("../_delete/apps/user-management/UsersPage")
  // );

  //Actual Routes
  // const JobsWrapperPage = lazy(() => import("../modules/jobs/JobsPageWrapper"));
  // const UsersWrapperPage = lazy(
  //   () => import("../modules/users/UsersPageWrapper")
  // );

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        <Route path="dashboard" element={<DashboardWrapper />} />
        
        {/* Pages */}
        <Route path="roles" element={<RolesPage />} />
        {/*users*/}
        <Route path="users/installers" element={<InstallersPage />} />
        <Route path="users/customers" element={<CustomersPage />} />
        <Route path="users/admins" element={<UsersPage />} />
        <Route path="profile/" element={<UserProfilePage />} />
        <Route path="users/installers/:id" element={<InstallerViewPage />} />
        <Route path="users/customers/:id" element={<CustomerViewPage />} />
        <Route path="users/admins/:id" element={<UserViewPage />} />
        {/*jobs*/}
        <Route path="jobs/active-jobs" element={<JobsPage />} />
        <Route path="jobs/history" element={<JobsHistoryPage />} />
        <Route path="jobs/history/:id" element={<JobHistoryViewPage />} />
        <Route path="builder" element={<BuilderPageWrapper />} />
        <Route path="menu-test" element={<MenuTestPage />} />
        
        {/*finance*/}
        <Route path="finance/invoices" element={<InvoicesPage />} />
        <Route path="finance/reports" element={<ReportsPage />} />
        <Route path="finance/invoices/:id" element={<InvoiceViewPage />} />
        <Route path="finance/my-payment-requests" element={<PaymentRequestsPage />} />
        <Route path="finance/payment-requests" element={<PaymentRequestsHistoryPage />} />
        {/*<Route path="finance/payment-requests" element={<PaymentsHistoryPage />} />*/}

        {/*Support*/}
        <Route path="support/tickets" element={<TicketsPage />} />
        <Route path="support/feedbacks" element={<FeedbacksPage />} />

        {/*Coordination*/}
        <Route path="installers/metrics" element={<MetricsPage />} />
        <Route path="installers/certificates" element={<CertificatesPage />} />
        <Route path="installers/certifications" element={<CertificationsPage />} />
        <Route path="installers/interview-scheduler" element={<SchedulerPage />} />

        {/* Lazy Modules */}
        <Route
          path="crafted/pages/profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route path="finance/overview" element={<FinanceOverviewWrapper />} />
        <Route
          path="crafted/widgets/*"
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/chat/*"
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/user-management/*"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />

        {/*<Route
          path="jobs/*"
          element={
            <SuspensedView>
              <JobsWrapperPage />
            </SuspensedView>
          }
        />*/}

        {/*<Route
          path="users/*"
          element={
            <SuspensedView>
              <UsersWrapperPage />
            </SuspensedView>
          }
        />*/}

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
