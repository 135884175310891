import {useState} from "react";
import {useQueryClient, useMutation} from 'react-query'
import {useListView} from '../../../common/table/core/ListViewProvider'
import ConfirmDeleteDialog from "../../../common/components/ConfirmDeleteDialog"
import { toast } from 'react-toastify';

const TableListGrouping = ({queryList, useQueryResponse, deleteSelectedEntity}) => {
  const {selected, clearSelected} = useListView()
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()

  const [isDialogVisible, setDialogVisible] = useState(false);

  const deleteSelectedItems = useMutation(() => deleteSelectedEntity(selected), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${queryList}-${query}`])
      clearSelected()
      toast.success('Records deleted successfully');
    },
    onError: (error) => {
      // ❌ Handle the
      clearSelected()
      console.error('Error deleting selected items:', error);
      toast.error('Failed to delete records');
    },
  })

  const handleDelete = async () => {
    await deleteSelectedItems.mutateAsync();
    setDialogVisible(false)
  };

  const handleCancelConfirm = () => {
    setDialogVisible(false);
  };

  const handleShowDialog = () => {
    setDialogVisible(true);
  };


  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> Selected
      </div>

      <button
        type='button'
        className='btn btn-danger'
        onClick={handleShowDialog}
      >
        Delete Selected
      </button>
      <ConfirmDeleteDialog
        open={isDialogVisible}
        onCancel={handleCancelConfirm}
        onConfirm={handleDelete}
      />
    </div>
  )
}

export {TableListGrouping}
