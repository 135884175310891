import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const ConfirmDeleteDialog = ({ open, onCancel, onConfirm }) => {
  return (
    <Dialog 
      header="Confirm Delete" 
      visible={open} 
      // style={{ width: '20vw' }} 
      onHide={onCancel}
    >
      <p className="m-0 text-center text-gray-600 fw-bold">
        Are you sure you would like to delete this record?
      </p>
      <div className="text-center pt-3">
        <Button
          className="btn btn-danger me-3 fw-bold"
          icon="pi pi-check"
          label="Delete"
          onClick={onConfirm}      
        />
        <Button
          className="btn btn-primary"
          icon="pi pi-times fw-bold"
          label="Cancel"
          onClick={onCancel}       
        />
      </div>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;
