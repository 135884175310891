import React, { useEffect, useState } from "react";
import { PageTitle } from "../../../../_metronic/layout/core";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Installers } from "../../../common/components/Installers";
import Flatpickr from "react-flatpickr";


import { getInterviewSchedules } from "../core/_requests";
import { addInterviewSchedule } from "../core/_requests";

const usersBreadcrumbs = [
  {
    title: "",
    path: "/installers/interview-scheduler",
    isSeparator: false,
    isActive: false,
  },
];

export default function SchedulerPage() {
  const [modalOpen, setModalOpen] = useState(false);
  const [isCalendarLoading, setIsCalendarLoading] = useState(false);
  const [interviewSchedules, setInterviewSchedules] = useState({})

  const [title, setTitle] = useState('');
  const [installerId, setInstallerId] = useState({});
  const [meetingLink, setMeetingLink] = useState('');

  const [startTime, setStartTime] = useState<any>(new Date());
  const [endTime, setEndTime] = useState<any>(new Date());


  useEffect(() => {
    // We have to show toolbar only for dashboard page

    const fetchInterviewSchedules = async () => {
      setIsCalendarLoading(true);
      try {
        const data = await getInterviewSchedules();
        if (data) {
          setInterviewSchedules(data);
        }
      } catch (error) {
        console.error('Error fetching stats:', error);
      } finally {
        setIsCalendarLoading(false);
      }
    };

    fetchInterviewSchedules();

  }, [])

  /*const handleDateSelect = (dateSelectInfo) =>{

    let title = prompt('Add Interview Slot:')
    let calendarApi = dateSelectInfo.view.calendar

    if(title){
      calendarApi.addEvent({
        title,
        start: dateSelectInfo.startStr,
        end: dateSelectInfo.endStr,
      })
    }

    // The we post to backend

  }*/





  const handleDateSelect = (dateSelectInfo: any) => {

    // TODO - Update code to work directly with BS
    // Get the button element that triggers the modal
    var myButton = document.querySelector<HTMLButtonElement>("[data-bs-target='#kt_modal_1']");

    // Simulate button click
    myButton!.click();


    const submitButton = document.getElementById("save-schedule") as HTMLButtonElement;
    const cancelButton = document.getElementById("cancel-modal") as HTMLButtonElement;

    submitButton.addEventListener("click", () => {

      // Noticed delays in state update and also time comes properly formatted like this

      let titleElement = document.getElementById("schedule-title") as HTMLInputElement;
      let title = titleElement!.value;

      let startTimeElement = document.getElementById("start-time") as HTMLInputElement;
      let startTime = startTimeElement!.value;

      let endTimeElement = document.getElementById("end-time") as HTMLInputElement;
      let endTime = endTimeElement!.value;


      // Lets do a check because I nulled the variable at the end to prevent repeat on previously selected date

      if (dateSelectInfo != null) {
        let calendarApi = dateSelectInfo.view.calendar;
        if (title && calendarApi) {
          calendarApi.addEvent({
            title,
            start: dateSelectInfo.startStr,
            end: dateSelectInfo.endStr,
          });


          let event = {
            title,
            installer_id: Number(installerId),
            start: dateSelectInfo.startStr + 'T' + startTime + ':00',
            end: dateSelectInfo.endStr + 'T' + endTime + ':00',
            all_day: false,
            status: "scheduled",
            background_color: "",
            border_color: "",
            text_color: "",
            class_name: "bartum-interview",
            editable: true,
            extendedProps: {
              link: meetingLink
            }
          };

          console.log(event)

          // Post to backend
          addInterviewSchedule(event);

          // Clear the modal title input and close the modal
          titleElement.value = ''
          cancelButton!.click()

        } else {
          // console.error("Title or activeCalendarApi is null");
          // Handle the case where title or activeCalendarApi is null, e.g., show an error message
        }

      }



      // Lets prevent the dates repeating events
      dateSelectInfo = null;

    });




  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleModalSubmit = () => {
    // Handle submit, e.g., posting to backend
    // Use the title state value here
    //console.log("Title:", title);
    //console.log("Installer:", installer);
    //console.log("Meeting Link:", meetingLink);

    // Send data to backend

    // After handling, close the modal
    setModalOpen(false);
  };



  //console.log(events);
  //alert('sample')

  return (
    <>
      <PageTitle
        breadcrumbs={usersBreadcrumbs}
        description="Schedule Interviews"
      >
        Installers Interview Scheduler
      </PageTitle>

      <div id="kt_app_content" className="app-content flex-column-fluid">
        {/*<!--begin::Content container-->*/}

        <div className="card">

          {isCalendarLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '30vh', // Adjust height as needed
              }}
            >
              <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="5" />
            </div>
          ) : (<>

              <div className="card-body py-4">
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay",
                  }}
                  initialView="dayGridMonth"
                  editable={true}
                  selectable={true}
                  selectMirror={true}
                  dayMaxEvents={true}
                  weekends={true}
                  initialEvents={interviewSchedules} // alternatively, use the `events` setting to fetch from a feed
                  select={handleDateSelect}
              /*eventContent={(eventInfo) => (
                <>
                  <p>{eventInfo.timeText}</p>
                  <i>{eventInfo.event.title}</i>
                  <p>{eventInfo.event.extendedProps.description}</p>
                </>
              )}*/
              //eventContent={renderEventContent} // custom render function
              //eventClick={this.handleEventClick}
              //eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
              /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
                />
              </div>


              <button type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_1"
              >
                Invisible Button
              </button>


              <div className="modal fade" tabIndex={-1} id="kt_modal_1">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Add Interview Schedule</h5>
                      <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">

                      </div>
                    </div>
                    <div className="modal-body">
                      <div className="mt-2">
                        <label className="form-label">Interview title</label>
                        <input
                          type="text"
                          className="form-control"
                          id="schedule-title"
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                      <div className="mt-5">
                        <Installers
                          label="Select installer"
                          name="installer"
                          formik={{ setFieldValue: (name, value) => setInstallerId(value) }}
                          defaultValue={installerId}
                        />
                      </div>
                      <div className="mt-5">
                        <label className="form-label">Meeting Link</label>
                        <input
                          type="text"
                          className="form-control"
                          id="meeting-link"
                          onChange={(e) => setMeetingLink(e.target.value)}
                        />
                      </div>


                      <div className='text-center'>
                        <Flatpickr
                          id="start-time"
                          value={startTime.setMinutes(0, 0, 0)}
                          onChange={([e]) => {
                            setStartTime(e);
                          }}
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "H:i",
                          }}
                          className='mt-5 form-control form-control-solid'
                          placeholder='Start Time'
                          style={{ width: '40%', display: 'inline' }}
                        />

                        <Flatpickr
                          id="end-time"
                          value={endTime.setMinutes(0, 0, 0)}
                          onChange={([e]) => {
                            setEndTime(e);
                          }}
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "H:i",
                          }}
                          className='ms-8 form-control form-control-solid'
                          placeholder='End Time'
                          style={{ width: '40%', display: 'inline' }}
                        />

                      </div>

                    </div>

                    <div className="modal-body">

                    </div>


                    <div className="modal-footer">
                      <button type="button" className="btn btn-light" id="cancel-modal" data-bs-dismiss="modal">Close</button>
                      <button type="button" className="btn btn-primary" id="save-schedule" onClick={handleModalSubmit}>Save changes</button>
                    </div>
                  </div>
                </div>
              </div>



          </>)}





        </div>
        {/*<!--end::Content container-->*/}

        {/* Modal */}
        {modalOpen && (
          <div className="modal">
            <div className="modal-content">
              <h2>Add Interview Slossst:</h2>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <button onClick={handleModalSubmit}>Submit</button>
              <button onClick={handleCloseModal}>Cancel</button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
