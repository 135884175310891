import {PageTitle} from '../../../../_metronic/layout/core'
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getCustomerById } from './core/_requests';
import PleaseWait from '../../../common/components/PleaseWait'
import { Link } from 'react-router-dom';
import {capitalize} from '../../../../_metronic/helpers'
import RatingStars from '../../../common/components/RatingStars'
import {KTIcon} from '../../../../_metronic/helpers'

import React from 'react';

import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';

function JobsTable({jobs}) {

  const [filters, setFilters] = useState({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      title: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      status: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const onGlobalFilterChange = (e) => {
      const value = e.target.value;
      let _filters = { ...filters };

      _filters['global'].value = value;

      setFilters(_filters);
      setGlobalFilterValue(value);
  };

  const renderHeader = () => {
      return (
          <div className="flex justify-content-end">
              <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
              </span>
          </div>
      );
  };

  const ratingBodyTemplate = (rowData) => {
      const rating = rowData.rating;

      return (
          <div className="flex align-items-center gap-2 fw-bold text-gray-800 fs-6">
              <RatingStars numStars={rating} totalStars={5} />
          </div>
      );
  };


  const titleBodyTemplate = (rowData) => {
    const title = rowData.title
    return (
      <span className='fw-bold text-gray-500 fs-6'>
        {capitalize(title)}
      </span>
    )
  }

  const serviceTypeBodyTemplate = (rowData) => {
    const type = rowData.service_type
    return (
      <span className='fw-bold text-gray-500 fs-6'>
        {capitalize(type)}
      </span>
    )
  }

  const  statusBodyTemplate = (rowData) => {
    const status = rowData.status
    return (
      <span className='fw-bold text-gray-500 fs-6'>
        {capitalize(status)}
      </span>
    )
  }

  const  viewBodyTemplate = (rowData) => {
    return (
      <span className='fw-bold text-gray-600 fs-6'>
        <Link to={rowData.status === 'completed' ? `/jobs/history/${rowData.id}` : `/jobs/active-jobs`}>View</Link>
      </span>
    )
  }


  const header = renderHeader();

  return (
      <>
        <div className="card-header">
          {/* Card title */}
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">Jobs</h3>
          </div>
        </div>
          <DataTable value={jobs} paginator rows={10} dataKey="id" filters={filters} filterDisplay="row" loading={false}
            globalFilterFields={['title', 'status']} header={header} emptyMessage="No jobs found." tableStyle={{ 
              backgroundColor: 'red'
             }}>
              <Column field="title" header="Title" style={{ minWidth: '12rem' }} body={titleBodyTemplate}/>
              <Column field="service_type" header="Service type" style={{ minWidth: '12rem' }} body={serviceTypeBodyTemplate}/>
              <Column field="status" header="Status" style={{ minWidth: '12rem' }} body={statusBodyTemplate}/>
              <Column field="rating" header="Rating" style={{ minWidth: '12rem' }} body={ratingBodyTemplate} />
              <Column header="View" style={{ minWidth: '12rem' }} body={viewBodyTemplate} />
          </DataTable>
      </>
  );
}

const ProfileDetailsCard = ({customer}) => {
  
  return (
    <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
      {/* Card header */}
      <div className="card-header">
        {/* Card title */}
        <div className="card-title m-0">
          <h3 className="fw-bold m-0">Profile Details</h3>
        </div>
        {/* Action */}
        <Link to={`/users/customers/`} className="btn btn-sm btn-primary align-self-center">
        <i className="bi bi-arrow-return-left"></i> Go back
        </Link>
      </div>
      {/* Card body */}
      <div className="card-body p-9">
        {/* Rows */}
        <div className="row mb-7">
          <label className="col-lg-4 fw-semibold text-muted">Full Name</label>
          <div className="col-lg-8">
            <span className="fw-bold fs-6 text-gray-800">
              {customer?.user.firstname} {customer?.user.lastname}
            </span>
          </div>
        </div>

        <div className="row mb-7">
          <label className="col-lg-4 fw-semibold text-muted">Username</label>
          <div className="col-lg-8">
            <span className="fw-semibold text-gray-800 fs-6">{capitalize(customer?.user.username)}</span>
          </div>
        </div>

        <div className="row mb-7">
          <label className="col-lg-4 fw-semibold text-muted">Email Address</label>
          <div className="col-lg-8">
            <span className="fw-semibold text-gray-800 fs-6">{customer?.user.email}</span>
          </div>
        </div>
        
        <div className="row mb-7">
          <label className="col-lg-4 fw-semibold text-muted">
            Contact Phone
            <span className="ms-1" data-bs-toggle="tooltip" aria-label="Phone number must be active" data-bs-original-title="Phone number must be active" data-kt-initialized="1">
              <i className="ki-duotone ki-information fs-7"><span className="path1"></span><span className="path2"></span><span className="path3"></span></i>
            </span>
          </label>
          <div className="col-lg-8 d-flex align-items-center">
            <span className="fw-bold fs-6 text-gray-800 me-2">{customer?.user?.phone}</span>
            <span className="badge badge-success">Verified</span>
          </div>
        </div>

         <div className="row mb-7">
          <label className="col-lg-4 fw-semibold text-muted">State</label>
          <div className="col-lg-8">
            <span className="fw-semibold text-gray-800 fs-6">{capitalize(customer?.user.state)}</span>
          </div>
        </div>

        <div className="row mb-7">
          <label className="col-lg-4 fw-semibold text-muted">Rating Average</label>
          <div className="col-lg-8">
            <span className="fw-semibold text-gray-800 fs-6">
              <RatingStars numStars={customer?.details.rating_average} totalStars={5} />
            </span>
          </div>
        </div>

        {/* Additional rows go here */}
      </div>
    </div>
  );
};

const CustomerDetails = ({ customer }) => {
  
  const defaultAvatarSrc = '/media/avatars/avatar.png';

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed'>
              <img src={customer?.user.avatar_url ? customer?.user.avatar_url : defaultAvatarSrc} alt='Metornic' />
              <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <span className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    {`${customer?.user.firstname} ${customer?.user.lastname}`}
                  </span>
                  <span>
                    <KTIcon iconName='verify' className='fs-1 text-primary' />
                  </span>
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <span
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                    Customer
                  </span>
                  <span
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTIcon iconName='geolocation' className='fs-4 me-1' />
                    {customer?.details?.contact_info?.address ?? "-"}
                  </span>
                  <span
                    className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                  >
                    <KTIcon iconName='sms' className='fs-4 me-1' />
                    {customer?.user.email}
                  </span>
                </div>
              </div>
            </div>

            
          </div>
        </div>

        <ProfileDetailsCard customer={customer}/> 
        <JobsTable jobs={customer?.jobs ?? []}/>        
      </div>
    </div>
  );
};

export default function CustomerViewPage() {
    const { id } = useParams();
    const [customer, setCustomer] = useState({});
    const [isLoading, setIsLoading] = useState(true)
    const [title, setTitle] = useState('')

    useEffect(() => {
        const fetchCustomer = async () => {
          try {
            const data = await getCustomerById(id);
            if(data){
              setCustomer(data)
              setTitle(data?.user?.username!)
            }
          } catch (error) {
            console.error('Error fetching customer:', error);
          } finally {
            setIsLoading(false)
          }
        };
        fetchCustomer();
    }, [id])

    return (
      <>
        <PageTitle description={title}>
          <Link to="/users/customers">Customers</Link>
        </PageTitle>
        {isLoading ? <PleaseWait /> : <CustomerDetails customer={customer}/>}
      </>
    );
}