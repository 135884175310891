import axios, {AxiosResponse} from 'axios'
import {ID, Response, processQueryString} from '../../../../../_metronic/helpers'
import {Customer, CustomersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const CUSTOMER_URL = `${API_URL}/customers`
const GET_CUSTOMERS_URL = `${API_URL}/customers/`

const getCustomers = (query: string): Promise<CustomersQueryResponse> => {
  const filters = processQueryString(query);

  return axios
    .get(`${GET_CUSTOMERS_URL}?${filters}`)
    .then((d: AxiosResponse<CustomersQueryResponse>) => {
      return d.data
    })
}

const getAllCustomers = () => {

  return axios
    .get(`${GET_CUSTOMERS_URL}`)
    .then((d) => d.data)
}

const getCustomerById = (id): Promise<Customer | undefined> => {
  return axios
    .get(`${CUSTOMER_URL}/${id}`)
    .then((response: AxiosResponse<Response<Customer>>) => response.data)
    .then((response: Response<Customer>) => response.data)
}

const createCustomer = (customer): Promise<Customer | undefined> => {
  return axios
    .post(CUSTOMER_URL, customer)
    .then((response: AxiosResponse<Response<Customer>>) => response.data)
    .then((response: Response<Customer>) => response.data)
}

const updateCustomer = (customer): Promise<Customer | undefined> => {
  return axios
    .put(`${CUSTOMER_URL}/${customer.id}`, customer)
    .then((response: AxiosResponse<Response<Customer>>) => response.data)
    .then((response: Response<Customer>) => response.data)
}

const deleteCustomer = (customerId: ID): Promise<void> => {
  return axios.delete(`${CUSTOMER_URL}/${customerId}`).then(() => {})
}

// const deleteSelectedCustomers = () => {}
const deleteSelectedCustomers = (customerIds: Array<ID>): Promise<void> => {
  const requests = customerIds.map((id) => axios.delete(`${CUSTOMER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getCustomers, getAllCustomers, getCustomerById, createCustomer, updateCustomer, deleteCustomer, deleteSelectedCustomers}