// @ts-nocheck
import {FC} from 'react'
import {Column} from 'react-table'
import {ActionsCell} from './ActionsCell'
import {TableCustomHeader} from '../../../../common/table/columns/TableCustomHeader'
import {TableSelectionHeader} from '../../../../common/table/columns/TableSelectionHeader'
import {TableSelectionCell} from '../../../../common/table/columns/TableSelectionCell'
import {Invoice} from '../core/_models'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {deleteInvoice} from '../core/_requests'
import {QUERIES, capitalize, formatDate, CURRENCY} from '../../../../../_metronic/helpers'

type Props = {
  invoice: Invoice
}

const ColumnCell: FC<Props> = ({ invoice, fieldName }) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>{capitalize(invoice[fieldName])}</div>
    </div>
  )
}

const DateCell: FC<Props> = ({ invoice, fieldName }) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>{formatDate(invoice[fieldName])}</div>
    </div>
  )
}

const AmountCell: FC<Props> = ({ invoice, fieldName }) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>{CURRENCY.format(invoice[fieldName])}</div>
    </div>
  )
}

const StatusCell: FC<Props> = ({ invoice }) => {

  return (
      <>
        {invoice.status === 'pending' ? (
          <div className='d-flex align-items-center'>
            <span className="badge badge-secondary">Pending</span>
          </div>
        ) : (
          <div className='d-flex align-items-center'>
            <span className="badge badge-primary">Processed</span>
          </div>
        )}
      </>
  );
}

const invoicesColumns: ReadonlyArray<Column<Invoice>> = [
  {
    Header: (props) => <TableSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <TableSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Number' className='min-w-125px' />,
    id: 'invoice_no',
    Cell: ({ ...props }) => <ColumnCell invoice={props.data[props.row.index]} fieldName='invoice_no' />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Issued On' className='min-w-125px' />,
    id: 'issueDate',
    Cell: ({ ...props }) => <DateCell invoice={props.data[props.row.index]} fieldName='issueDate' />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Due Date' className='min-w-125px' />,
    id: 'dueDate',
    Cell: ({ ...props }) => <DateCell invoice={props.data[props.row.index]} fieldName='dueDate' />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Paid On' className='min-w-125px' />,
    id: 'paid_date',
    Cell: ({ ...props }) => <DateCell invoice={props.data[props.row.index]} fieldName='paidDate' />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Amount' className='min-w-125px' />,
    id: 'total_amount',
    Cell: ({ ...props }) => <AmountCell invoice={props.data[props.row.index]} fieldName='total_amount' />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    id: 'status',
    Cell: ({ ...props }) => <StatusCell invoice={props.data[props.row.index]} fieldName='status' />,
  },
  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ActionsCell id={props.data[props.row.index].id} queryList={QUERIES.INVOICES_LIST} useQueryResponse={useQueryResponse} deleteEntity={deleteInvoice} />,
  },
]

export {invoicesColumns}
