import Select, { components, GroupProps } from 'react-select';
import { useState, useEffect } from 'react';
import { getAllPermissions } from '../../pages/roles/core/_requests';

// for now dummy roles

const groupStyles = {
  border: '2px dotted #36B37E',
  borderRadius: '5px',
  background: '#f2fcff',
  padding: '8px',
};

const Group = (props: GroupProps<{ label: string; value: string }>) => (
  <div style={groupStyles}>
    <components.Group {...props} />
  </div>
);

const Permissions = ({ label, name, defaultValue, formik }) => {
  const [options, setOptions] = useState([]);
  
  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const data = await getAllPermissions();
        if(data.data){
            // const options = data.data.map((perm) => ({ label: perm.toUpperCase(), value: perm }));
            // Grouping logic
            const groupedPermissions = data.data.reduce((acc, permission) => {
              const [action, category] = permission.split('_');
              const permissionObject = { label: permission.toUpperCase().replace('_'," "), value: permission };

              // Find or create the group
              const group = acc.find((group) => group.label === category);

              if (group) {
                group.options.push(permissionObject);
              } else {
                acc.push({ label: category, options: [permissionObject] });
              }

              return acc;
            }, []);

            setOptions(groupedPermissions)
        }
      } catch (error) {
        console.error('Error fetching permissions:', error);
      }
    };

    fetchPermissions();
  }, []);

  return (
    <>
      <div className='mb-10'>
        <label className='form-label'>Permissions</label>
        <Select
          className='react-select-styled react-select-solid'
          classNamePrefix='react-select'
          options={options}
          components={{ Group }}
          placeholder='Select an option'
          onChange={(permission) => {
            const permissions = permission.map(per => per['value'])
            formik.setFieldValue('permissions', permissions)
          }}
          defaultValue={defaultValue}
          isMulti
        />
      </div>
    </>
  );
};

export { Permissions };
