import {useListView} from '../../../../common/table/core/ListViewProvider'
import {TableListToolbar} from '../../../../common/table/components/TableListToolbar'
import {TableListSearchComponent} from '../../../../common/table/components/TableListSearchComponent'
import {TableListGrouping} from '../../../../common/table/components/TableListGrouping'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {deleteSelectedCertificates} from '../core/_requests'
import {Export} from './Export'
import {AddCertificateButton} from './CertificateModal'
import {QUERIES} from '../../../../../_metronic/helpers'

const CertificatesListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <TableListSearchComponent placeholder="Search for certificate" />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {
          selected.length > 0 ? <TableListGrouping queryList={QUERIES.CERTIFICATES_LIST} useQueryResponse={useQueryResponse} deleteSelectedEntity={deleteSelectedCertificates} />
          : (
              <TableListToolbar>
                <Export />
                <AddCertificateButton />
              </TableListToolbar>
            )
        }
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {CertificatesListHeader}
