import Select from 'react-select'
import {SKILLS} from '../../../_metronic/helpers';

const Skills = ({label, name, defaultValue, formik}) => {
    const options = SKILLS.map((skill) => ({label: skill.toUpperCase(), value: skill.toLowerCase()}))
    const defaultVal = options.filter((option) => defaultValue.includes(option.value));

    return (
        <>
            <div className='mb-10'>
                <label className='form-label'>Skills</label>
                <Select 
                    className='react-select-styled react-select-solid' 
                    classNamePrefix='react-select' 
                    options={options} 
                    placeholder='Select an option' 
                    onChange={(skill) => {
                        const skills = skill.map(skill => skill.value)
                        formik.setFieldValue('skills', skills)
                    }}
                    defaultValue={defaultVal}
                    isMulti
                />
            </div>
        </>
    )
}

export { Skills };