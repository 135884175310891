import React from "react";
import {UsersListWrapper} from './UsersList'
import {PageTitle} from '../../../../_metronic/layout/core'

const usersBreadcrumbs = [
  {
    title: '',
    path: '/users/admins',
    isSeparator: false,
    isActive: false,
  },
]

export default function UsersPage() {
    return (
        <>
            <PageTitle breadcrumbs={usersBreadcrumbs} description='Manage users'>
                Users Profiles
            </PageTitle>
            <UsersListWrapper />
        </>
    )
}